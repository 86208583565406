<template>
  <div>
    <h1>{{ schema.name }} Wizard</h1>

    <h2>{{ page.title }}</h2>

    <form-generator class="mt-6" :schema="page.fields" v-model="formData"></form-generator>

    <div class="my-6">
      <button type="button" @click="pageNumber--" v-if="pageNumber > 1">Back</button>
      <button type="button" @click="pageNumber++" v-if="pageNumber < schema.pages.length">Continue</button>
    </div>

    <div>
      <span v-if="formData.title">{{formData.title}} </span>
      <span v-if="formData.firstName">{{formData.firstName}} </span>
      <span v-if="formData.lastName">{{formData.lastName}} </span>
      <span v-if="formData.age">, {{formData.age}} years old</span>
      <span v-if="formData.coverBackground">, cover: {{formData.coverBackground}}</span>
      <span v-if="formData.namesList">, names: {{formData.namesList.join(', ')}}</span>
      <span v-if="formData.presentationHymn">, presentation hymn: {{formData.presentationHymn}}</span>
      <span v-if="formData.secondReading">, second reading: {{formData.secondReading}}</span>
    </div>
  </div>
</template>

<script>
  import FormGenerator from '../components/FormGenerator'
  import schema from '../schemas/FuneralBooklet'

  export default {
    name: 'Wizard',
    components: { FormGenerator },
    data() {
      return {
        formData: {
          firstName: 'Test',
          lastName: 'User'
        },
        schema: schema,
        pageNumber: 1
      };
    },
    computed: {
      page() {
        return schema.pages.find(x => x.number === this.pageNumber);
      }
    }
  };
</script>
