<template>
  <div v-if="loaded">
    <h2 class="mb-6">Funeral Booklet Creation Wizard</h2>
    <div class="inline-block mx-auto">
      <div class="view-tabs max-w-xs hidden mb-4">
        <button @click="switchView(true)" :class="[preview ? 'btn-light' : 'btn', 'rounded-none', 'w-1/2']">Design</button>
        <button @click="switchView(false)" :class="[preview ? 'btn' : 'btn-light', 'rounded-none', 'w-1/2']">Preview</button>
      </div>
      <div class="mb-4 text-right mr-4">
        <label class="mr-4">Color Theme</label>
        <select @change="themeChanged" v-model="theme">
          <option v-for="(theme, index) in themes" :key="index" :value="theme" v-bind:selected="(index === 0)" >{{theme}}</option>
        </select>
      </div>
      <div class="flex">
        <div ref="bookletDesign" class="booklet-design max-w-xs text-left mr-4">
          <div class="bg-grey-lightest border border-grey p-6 rounded">
            <h2 class="text-xl">{{ schemaPage.title }}</h2>
            <div class="text-base">{{ schemaPage.caption }} </div>
            <div class="booklet-wizard">
              <form-generator @input="saveBooklet" class="mt-6" :schema="schemaPage.fields" v-model="formData" :cremation="formData.Cremation"></form-generator>
            </div>
          </div>
          <div class="my-6 text-right">
            <button type="button" @click="previousPage" v-if="pageNumber > 1" class="btn mr-4">Back</button>
            <button type="button" @click="nextPage" v-if="pageNumber < schema.pages.length" class="btn">Continue</button>
            <router-link class="btn" v-if="pageNumber === schema.pages.length" :to="{ name: 'booklet-pre-approval', params: { id }}">Continue</router-link>
            <button v-if="approvalPages" type="button" @click="returnToApproval" class="btn mt-4">Return to Approval</button>
          </div>
        </div>
        <div :class="['booklet-preview', { 'opacity-0': !previewLoaded }]" ref="bookletPreview" class="mr-4">
          <booklet-viewer ref="bookletViewer" @load="previewLoaded = true" :pageNumber="bookletPage" :model="model" :template="template" class="border border-grey rounded inline-block" ></booklet-viewer>
        </div>
      </div>
      <div class="text-green-dark text-center my-4">
        {{ $store.getters.messages }}
      </div>
      <div class="error-summary my-4"></div>
    </div>
  </div>
</template>

<script>
  import FormGenerator from '../components/FormGenerator'
  import BookletViewer from '../components/BookletViewer'
  import * as api from '../client/api.js'
  import * as bookletModels from '../tools/bookletModels'
  import * as easterTools from '../tools/easterTools'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Booklet',
    components: { FormGenerator, BookletViewer },
    props: ['page', 'approvalPages'],
    data() {
      return {
        id: null,
        formData: {},
        schema: null,
        pageNumber: 1,
        loaded: false,
        template: null,
        preview: false,
        bookletPage: 1,
        bookletPages: null,
        theme: null,
        themes: null,
        previewLoaded: false
      };
    },
    created() {
      let self = this;

      Promise.all([
        api.ThemesGet(),
        api.BookletsByIdGet({ id: self.$route.params.id })
      ]).then(([themesResponse, bookletResponse]) => {
        if (bookletResponse && bookletResponse.data) {
          let booklet = bookletResponse.data;
          self.id = booklet.id;
          self.formData = JSON.parse(booklet.modelJson);
          self.template = bookletModels.createTemplateModel(booklet.template);

          if (self.signedIn && self.bookletData) {
            self.formData = Object.assign(self.formData, self.bookletData);
            self.theme = self.formData.Theme;
            self.saveBooklet(self.formData, null, () => {
              self.$store.dispatch('deleteBookletData');
              self.pageNumber++;
            });
          }

          self.schema = JSON.parse(booklet.schemaJson);

          let pageNumber = parseInt(this.$route.params.page);

          if (pageNumber && pageNumber > 0 && pageNumber <= self.schema.pages.length) {
            self.pageNumber = pageNumber;
          }
        }

        if (themesResponse && themesResponse.data) {
          self.themes = themesResponse.data;
          self.theme = self.formData.Theme.Id;
        }

        self.bookletPages = self.getBookletPages();
        self.loaded = true;

      }).catch(e => {
        self.processErrorMessages(e);
        self.handleApiError(e);
      });
    },
    watch: {
      preview() {
        if (this.preview) {
          this.$refs.bookletViewer.update();
        }
      },
      pageNumber() {
        this.syncBookletView();
      }
    },
    computed: Object.assign(
      mapGetters(['signedIn', 'bookletData']), {
        schemaPage() {
          return this.schema.pages.find(x => x.number === this.pageNumber);
        },
        model() {
          return bookletModels.createDataModel(this.schema, this.formData);
        }
      }),
    methods: {
      storeBooklet() {
        this.$store.dispatch('storeBookletData', JSON.parse(JSON.stringify(this.formData)));
      },
      saveBooklet(formData, updateInfo, callback) {
        let self = this;

        if (updateInfo && updateInfo.fieldName === 'MassDate') {
          let easterReading = easterTools.getEasterReading(updateInfo.value, this.$config);
          let easterReadingOld = easterTools.getEasterReading(updateInfo.oldValue, this.$config);

          if (easterReading !== easterReadingOld) {
            let easterFields = this.schema.pages
              .map(x => x.fields)
              .reduce((a, b) => a.concat(b))
              .filter(x => x.type === 'scripture' && x.reading === 'EASTER');

            easterFields.forEach(x => self.formData[x.name] = null);
          }
        }
 
        if (!self.signedIn && self.pageNumber === 1) {
          self.storeBooklet();
        }
        else {
          api.BookletsPut({ args: { id: self.id, modelJson: JSON.stringify(self.formData) }})
            .then(() => {
              if (typeof(callback) === 'function') {
                callback();
              }
            })
            .catch(e => {
              self.processErrorMessages(e);
              self.handleApiError(e);
            })
        }
      },
      previousPage() {
        this.pageNumber--;
      },
      nextPage() {
        if (!this.signedIn) {
          this.storeBooklet();
          this.$router.push({name: 'sign-in', params: { redirectUrl: window.location.pathname + window.location.search }});
        }
        else {
          this.pageNumber++;
        }
      },
      getBookletPages() {
        let container = window.document.createElement('div');
        container.innerHTML = this.template;
        let pages = container.getElementsByClassName('page');
        let result = {};

        Array.from(pages).forEach((x, i) => {
          let [, templatePage] = x.outerHTML.match(/class=".*page-(\d+).*"/);
          let fields = Array.from(x.querySelectorAll('[data-field]:not([data-field=""])'));

          if (fields) {
            let pageFields = fields
              .map(x => x.attributes['data-field'].value)
              .filter((el, i, a) => el && a.indexOf(el) === i);

            pageFields.forEach(x => result[x] = templatePage);
          }
        });

        return result;
      },
      syncBookletView() {
        let self = this;
        let fields = this.schema.pages.filter(x => x.number === self.pageNumber)[0].fields;
        if (fields && fields.length > 0) {
          for (let field of fields) {
            let bookletPage = this.bookletPages[field.name];
            if (bookletPage && this.bookletPage != bookletPage) {
              this.bookletPage = bookletPage;
              break;
            }
          }
        }
      },
      switchView(design) {
        this.preview = !design;
        this.$refs.bookletDesign.style.display = design ? 'block' : 'none';
        this.$refs.bookletPreview.style.display = design ? 'none' : 'block';
      },
      themeChanged() {
        let self = this;

        api.ThemesByIdGet({ id: self.theme }).then(response => {
          if (response && response.data) {
            self.formData.Theme.Id = response.data.id;
            self.formData.Theme.StyleCss = response.data.styleCss;

            if (self.signedIn) {
              this.saveBooklet(self.formData);
            }
          }
        });
      },
      returnToApproval() {
        if (this.approvalPages && this.approvalPages.length === 2) {
          this.saveBooklet(this.formData, null, () => this.$router.push({ name: 'booklet-approval'}));
        }
      }
    }
  };
</script>

<style>
  .booklet-wizard label {
    @apply block;
    @apply text-left;
    @apply mt-4;
    @apply mb-1;
    @apply text-base;
  }

  .booklet-wizard input, .booklet-wizard button {
    @apply w-64;
    @apply ml-0;
  }

  .booklet-wizard input {
    @apply text-grey-darker;
  }

  @media only screen
  and (min-device-width : 375px)
  and (max-device-width : 667px) {
    .view-tabs {
      @apply block;
    }

    .booklet-preview {
      @apply hidden;
    }
  }
</style>