<template>
  <div>
    <h1>Not Found</h1>
    <p>
      <router-link to="/">Home</router-link>
    </p>
  </div>
</template>

<script>
  export default {
    name: "NotFound"
  }
</script>
