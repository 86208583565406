<template>
  <div>
    <h1>Welcome</h1>
    
    <div class="mb-4">Don't have an account? <router-link to="/register">Register</router-link></div>
    
    <div class="text-left max-w-sm mx-auto">
      <div class="p-2">
        <div class="inline-block w-32">Email Address:</div>
        <div class="inline-block w-full md:w-80"><input class="w-full" name="EmailAddress" type="email" v-model="emailAddress" /></div>
      </div>
      <div class="p-2">
        <div class="inline-block w-32">Password:</div>
        <div class="inline-block w-full md:w-80"><input class="w-full" name="Password" type="password" v-model="password" /></div>
      </div>
      <div class="mb-8 mt-4 md:pl-32">
        <label class="pl-2">
          <input class="cursor-pointer" v-model="persist" type="checkbox"/>
          <span class="pl-2 cursor-pointer">Remember Me</span>
        </label>
      </div>
    </div>
    <button type="button" @click="signIn" class="btn">Sign In</button>
                  
    <div class="m-8"><router-link to="/reset-password">Forgot password?</router-link></div>

    <p class="error-summary"></p>
  </div>
</template>

<script>
  export default {
    name: 'SignIn',
    props: ['message', 'redirectUrl'],
    data: function () {
      return {
        emailAddress: null,
        password: null,
        persist: false
      }
    },
    methods: {
      signIn: function () {
        const self = this;
        
        self.$store
          .dispatch('signIn', self).then(response => {
            console.log(response)
            if(response){
              let redirectUrl =  self.$route.params.redirectUrl || '/my-booklets';

              self.$router.push(redirectUrl);
            }
          })
          .catch(e => {
            console.log(e)
            self.processErrorMessages(e);
            self.handleApiError(e);
          })
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name === 'register' && this.$route.params.redirectUrl) {
        to.params.redirectUrl = this.$route.params.redirectUrl;
      }

      next();
    }
  }
</script>
