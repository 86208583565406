<template>
    <div>
        <Accordion :title="header" @expand="expandAll" class="text-navy hover:text-melon">
            <p class="py-6">
                Before you begin, please review the following steps and gather the necessary items needed as you create your funeral mass booklet: 
            </p>
            <div v-if="templateName === 'FourPageFuneralBooklet'">
                <div class="-mt-8 mb-8">
                    <ol class="m-0">
                        <li class="mb-3 pl-6">Select Images, Poems, Prayers, Quotes from your personal library or from the provided resource library</li>
                        <li class="mb-3 pl-6">Format all Images before uploading into booklet</li>                        
                        <li class="mb-3 pl-6">Select Church, Celebrant, Liturgy Readers, Pallbearers and Music Ministry</li>
                        <li class="mb-3 pl-6">Carefully check spelling</li>                    
                        <li class="mb-3 pl-6">Be aware of dates of birth, death, and scheduled mass/time</li>                        
                        <li class="mb-12 pl-6">Select Scripture Readings and Hymns</li>                    
                    </ol>
                    <h3><u>4 Page Booklet Outline</u></h3>
                    <ul class="list-reset mt-8">
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Front Cover:</b></div>
                                <div class="flex-1 pl-3">Insert Image 4x4 (max.) high resolution*</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Names of chosen:</b></div>
                                <div class="flex-1 pl-3">Celebrants, Liturgy Readers, Pallbearers and Music Ministry**</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Pre-select:</b></div>
                                <div class="flex-1 pl-3">Scripture Readings, Gospel Hymns***</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Date of Decedent’s Birth:</b></div>
                                <div class="flex-1 pl-3">enter: 00/00/0000</div>
                            </div>
                        </li>

                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Date of Decedent’s Death:</b></div>
                                <div class="flex-1 pl-3">enter: 00/00/0000</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Name &amp; City, State of Church:</b> ex. </div>
                                <div class="flex-1 pl-3">St. Patrick’s Cathedral<br />Philadelphia, PA  19103</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Date &amp; time of Mass:</b></div>
                                <div class="flex-1 pl-3">enter 00/00/0000 &amp; ex. 11:00 a.m. or 11:00 A.M.</div>
                            </div>
                        </li>
                        <li class="mb-8">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Back Cover:</b></div>
                                <div class="flex-1 pl-3">Insert Image 4x6 (max) high resolution*  or leave page blank<br />Pre-select an image, poem, prayer, or a quote from your personal library or one from the resource library</div>
                            </div>
                        </li>
                    </ul>

                    <div class="text-sm italic font-medium text-blue hover:text-melon cursor-pointer" @click="showModal=true">View sample</div>
                </div>
            </div>

            <div v-if="templateName === 'EightPageFuneralBooklet'">
                 <div class="-mt-4 mb-8">
                    <ol class="m-0">
                        <li class="mb-3 pl-6">Select Images, Poems, Prayers, Quotes from your personal library or from the provided resource library</li>
                        <li class="mb-3 pl-6">Format all Images before uploading into booklet</li>                        
                        <li class="mb-3 pl-6">Select Church, Celebrant, Liturgy Readers, Pallbearers and Music Ministry</li>
                        <li class="mb-3 pl-6">Carefully check spelling</li>                    
                        <li class="mb-3 pl-6">Be aware of dates of birth, death, and scheduled mass/time</li>                        
                        <li class="mb-3 pl-6">Select Scripture Readings and Hymns</li>                    
                        <li class="mb-12 pl-6">Prepare Thank You note to family &amp; friends</li>                    
                    </ol>
                    <h3><u>8 Page Booklet Outline - Scripture Titles</u></h3>
                    <ul class="list-reset mt-8">
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Front Cover:</b></div>
                                <div class="flex-1 pl-3">Insert Image 4x4 (max.) high resolution*</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Inside Front Cover:</b></div>
                                <div class="flex-1 pl-3">Insert image 4x6 (max) high resolution* or leave page blank<br />Pre-select an image, poem, prayer, or a quote from your personal library or one from the resource library</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Names of chosen:</b></div>
                                <div class="flex-1 pl-3">Celebrants, Liturgy Readers, Pallbearers and Music Ministry**</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Pre-select:</b></div>
                                <div class="flex-1 pl-3">Scripture Readings, Gospel Hymns***</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Date of Decedent’s Birth:</b></div>
                                <div class="flex-1 pl-3">enter: 00/00/0000</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Date of Decedent’s Death:</b></div>
                                <div class="flex-1 pl-3">enter: 00/00/0000</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Name &amp; City, State of Church:</b> ex. </div>
                                <div class="flex-1 pl-3">St. Patrick’s Cathedral<br />Philadelphia, PA  19103</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Date &amp; time of Mass:</b></div>
                                <div class="flex-1 pl-3">enter 00/00/0000 &amp; ex. 11:00 a.m. or 11:00 A.M.</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Expression of Gratitude:</b></div>
                                <div class="flex-1 pl-3">Insert prepared thank you note to family &amp; friends**** (600 character max.); and, insert image 4x3 high resolution*</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Page 6:</b></div>
                                <div class="flex-1 pl-3">Insert image 4x4 (max) high resolution; and, insert any message (475 character max.)</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Inside Back Cover:</b></div>
                                <div class="flex-1 pl-3">Insert image 4x7 (max) high resolution* or leave page blank</div>
                            </div>
                        </li>
                        <li class="mb-8">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Back Cover:</b></div>
                                <div class="flex-1 pl-3">Insert Image 4x6 (max) high resolution*  or leave page blank<br />Pre-select an image, poem, prayer, or a quote from your personal library or one from the resource library</div>
                            </div>
                        </li>
                    </ul>

                    <div class="text-sm italic font-medium text-blue hover:text-melon cursor-pointer" @click="showModal=true">View sample</div>
                </div>
            </div>

            <div v-if="templateName === 'EightPageFullFuneralBooklet'">
                 <div class="-mt-4 mb-8">
                    <ol class="m-0">
                        <li class="mb-3 pl-6">Select Images, Poems, Prayers, Quotes from your personal library or from the provided resource library</li>
                        <li class="mb-3 pl-6">Format all Images before uploading into booklet</li>                        
                        <li class="mb-3 pl-6">Select Church, Celebrant, Liturgy Readers, Pallbearers and Music Ministry</li>
                        <li class="mb-3 pl-6">Carefully check spelling</li>                    
                        <li class="mb-3 pl-6">Be aware of dates of birth, death, and scheduled mass/time</li>                        
                        <li class="mb-3 pl-6">Select Scripture Readings and Hymns</li>                    
                        <li class="mb-12 pl-6">Prepare Thank You note to family &amp; friends</li>                    
                    </ol>
                    <h3><u>8 Page Booklet Outline - Full Scripture</u></h3>
                    <ul class="list-reset mt-8">
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Front Cover:</b></div>
                                <div class="flex-1 pl-3">Insert Image 4x4 (max.) high resolution*</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Inside Front Cover:</b></div>
                                <div class="flex-1 pl-3">Insert image 4x6 (max) high resolution* or leave page blank<br />Pre-select an image, poem, prayer, or a quote from your personal library or one from the resource library</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Names of chosen:</b></div>
                                <div class="flex-1 pl-3">Celebrants, Liturgy Readers, Pallbearers and Music Ministry**</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Pre-select:</b></div>
                                <div class="flex-1 pl-3">Scripture Readings, Gospel Hymns***</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Date of Decedent’s Birth:</b></div>
                                <div class="flex-1 pl-3">enter: 00/00/0000</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Date of Decedent’s Death:</b></div>
                                <div class="flex-1 pl-3">enter: 00/00/0000</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Name &amp; City, State of Church:</b> ex. </div>
                                <div class="flex-1 pl-3">St. Patrick’s Cathedral<br />Philadelphia, PA  19103</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Date &amp; time of Mass:</b></div>
                                <div class="flex-1 pl-3">enter 00/00/0000 &amp; ex. 11:00 a.m. or 11:00 A.M.</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Expression of Gratitude:</b></div>
                                <div class="flex-1 pl-3">Insert prepared thank you note to family &amp; friends**** (600 character max.); and, insert image 4x3 high resolution*</div>
                            </div>
                        </li>
                        <li class="mb-8">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Back Cover:</b></div>
                                <div class="flex-1 pl-3">Insert Image 4x6 (max) high resolution*  or leave page blank<br />Pre-select an image, poem, prayer, or a quote from your personal library or one from the resource library</div>
                            </div>
                        </li>
                    </ul>

                    <div class="text-sm italic font-medium text-blue hover:text-melon cursor-pointer" @click="showModal=true">View sample</div>
                </div>
            </div> 
            
            <div v-if="templateName === 'TwelvePageFuneralBooklet'">
                 <div class="-mt-4 mb-8">
                    <ol class="m-0">
                        <li class="mb-3 pl-6">Select Images, Poems, Prayers, Quotes from your personal library or from the provided resource library</li>
                        <li class="mb-3 pl-6">Format all Images before uploading into booklet</li>                        
                        <li class="mb-3 pl-6">Select Church, Celebrant, Liturgy Readers, Pallbearers and Music Ministry</li>
                        <li class="mb-3 pl-6">Carefully check spelling</li>                    
                        <li class="mb-3 pl-6">Be aware of dates of birth, death, and scheduled mass/time</li>                        
                        <li class="mb-3 pl-6">Select Scripture Readings and Hymns</li>                    
                        <li class="mb-12 pl-6">Prepare Thank You note to family &amp; friends</li>                    
                    </ol>
                    <h3><u>12 Page Booklet Outline - Full Scripture</u></h3>
                    <ul class="list-reset mt-8">
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Front Cover:</b></div>
                                <div class="flex-1 pl-3">Insert Image 4x4 (max.) high resolution*</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Inside Front Cover:</b></div>
                                <div class="flex-1 pl-3">Insert image 4x6 (max) high resolution* or leave page blank<br />Pre-select an image, poem, prayer, or a quote from your personal library or one from the resource library</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Names of chosen:</b></div>
                                <div class="flex-1 pl-3">Celebrants, Liturgy Readers, Pallbearers and Music Ministry**</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Pre-select:</b></div>
                                <div class="flex-1 pl-3">Scripture Readings, Gospel Hymns***</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Date of Decedent’s Birth:</b></div>
                                <div class="flex-1 pl-3">enter: 00/00/0000</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Date of Decedent’s Death:</b></div>
                                <div class="flex-1 pl-3">enter: 00/00/0000</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Name &amp; City, State of Church:</b> ex. </div>
                                <div class="flex-1 pl-3">St. Patrick’s Cathedral<br />Philadelphia, PA  19103</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Date &amp; time of Mass:</b></div>
                                <div class="flex-1 pl-3">enter 00/00/0000 &amp; ex. 11:00 a.m. or 11:00 A.M.</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Expression of Gratitude:</b></div>
                                <div class="flex-1 pl-3">Insert prepared thank you note to family &amp; friends**** (600 character max.); and, insert image 4x3 high resolution*</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Pages 8, 9, 10:</b></div>
                                <div class="flex-1 pl-3">Insert images up to 4x7 (max) high resolution*;<br /> and, insert a message (475 character max.)<br />Pre-select an image, poem, prayer, or a quote from your personal library or one from the resource library</div>
                            </div>
                        </li>
                        <li class="mb-3">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Inside Back Cover:</b></div>
                                <div class="flex-1 pl-3">Insert image 4x7 (max) high resolution* or leave page blank</div>
                            </div>
                        </li>
                        <li class="mb-8">
                            <div class="sm:flex">
                                <div class="flex-init"><b>Back Cover:</b></div>
                                <div class="flex-1 pl-3">Insert Image 4x6 (max) high resolution*  or leave page blank<br />Pre-select an image, poem, prayer, or a quote from your personal library or one from the resource library</div>
                            </div>
                        </li>
                    </ul>

                    <div class="text-sm italic font-medium text-blue hover:text-melon cursor-pointer" @click="showModal=true">View sample</div>
                </div>
            </div>

            <ul class="list-reset text-sm text-grey-darkest">
                <li class="m1-3 mb-1"><span class="mr-2">*</span> Format &amp; Save all images <b>prior</b> to uploading into booklet (i.e. visual effects: borders, frames, shadow, etc)</li>
                <li class="m1-3 mb-1"><span class="mr-2">**</span> Check spelling accuracy</li>
                <li class="m1-3 mb-1"><span class="mr-2">***</span> The furnished liturgical prayers, scripture readings, and blessings are in accordance with the Order of Christian Funerals for use when planning the Funeral Mass and are found in the booklet "Through Death to Life." Your Parish Pastor or his delegate may provide a copy of this booklet; however, <i>if your Pastor provides additional scripture readings, not included in our selection, the full scripture reading cannot be displayed, only the Scripture Reading Title and No. can be inserted, not the full reading.</i></li>
                <li v-if="showGratitudeFootnote" class="m1-3 mb-1"><span class="mr-2">****</span> Prepare Thank You note <b>prior</b> to completion of booklet</li>

            </ul>   
        </Accordion>
        <show-modal-popup :showModal="showModal" modal-class="max-w-lg p-12">
            <div slot="modal-content">
               <sample-generator :templateName="templateName"></sample-generator>
            </div>
    </show-modal-popup>
    </div>    
</template>

<script>
  import Accordion from '../components/Accordion'
  import ShowModalPopup from "../components/ShowModalPopup"
  import SampleGenerator from "../components/SampleGenerator"

  export default {
    name: 'Prerequisite',
    props: [ 'templateName' ],
    data() {
        return {
            "expanded": true,
            showModal: false
        }
    },
    components: { Accordion, ShowModalPopup, SampleGenerator },
    methods: {
        expandAll() {
            self.$emit("toggleCardState");
        }
    },
    computed: {
    header: function () {
        let header = "Helpful hints to design "
        
        if (this.templateName && this.templateName.toLowerCase().startsWith("four")) return header + "a 4 page - scripture titles booklet";
        if (this.templateName && this.templateName.toLowerCase().startsWith("eightpagefull")) return header + "an 8 page - full scripture booklet";
        if (this.templateName && this.templateName.toLowerCase().startsWith("eight")) return header + "an 8 page - scripture titles booklet";        
        if (this.templateName && this.templateName.toLowerCase().startsWith("twelve")) return header + "a 12 page - full scripture booklet";
        return ""
      },
      showGratitudeFootnote: function () {
          return this.templateName !== 'FourPageFuneralBooklet' 
      }
    }
  }
</script>

<style scoped>
    a {
        @apply font-normal;
        @apply italic;
        @apply text-sm;
        @apply text-blue;
        @apply mt-2;
        margin-left: 4rem;

    }
    .last {
        @apply pb-8;
        @apply border-b;
        @apply border-grey-light;
    }
    .checkmark {
        @apply mx-6;
        @apply h-4
    }
</style>
