import LogglyTracker from '../scripts/loggly.tracker'

export default class LoggingService {
  constructor(logglyToken, applicationName, environment, releaseNumber) {
    this.applicationName = applicationName;
    this.environment = environment;
    this.releaseNumber = releaseNumber;

    let tracker = new LogglyTracker();

    tracker.push({
      logglyKey: logglyToken,
      sendConsoleErrors: true
    });

    this.tracker = tracker;
  }

  logRoute(route) {
    let routeObject = {
      name: route.name,
      fullPath: route.fullPath,
      title: route.meta ? route.meta.title : null,
      params: route.params
    };

    this.log(routeObject, "route");
  }

  log(object, type) {
    let logObject = {
      Application: this.applicationName,
      Environment: this.environment,
      ReleaseNumber: this.releaseNumber
    };

    if (type) {
      logObject[type] = object;
    }

    this.tracker.push(logObject);
  }
}