<template>
  <div>
    <div id="burger" 
      :class="{ 'active' : isOpen }" 
      @click.prevent="toggleSlider"
      v-on-clickaway="hideSlider"
      v-on:click.native="toggleSlider">
        <button type="button" class="burger-button cursor-pointer outline-none" title="Menu">
          <span class="burger-bar burger-bar--1"></span>
          <span class="burger-bar burger-bar--2"></span>
          <span class="burger-bar burger-bar--3"></span>
        </button>
    </div>
    <transition name="slide">
      <div v-if="isOpen" class="sidebar-panel">
        <div class="sidebar-menu pt-12" style="height: 80%">
          <div v-if="!signedIn" class="buttons">
            <router-link to="/sign-in" class="outline hover:bg-melon mr-4">
              Sign In
              <Forward class="h-4" />
            </router-link>
            <router-link to="/register" class="outline selected">Register</router-link>
          </div>
          <router-link to="/how-it-works">How It Works</router-link>
          <router-link to="/pricing">What's The Cost</router-link>
          <router-link to="/pre-planning">Preplan a Funeral Mass</router-link>

          <router-link to="/About-Us">What makes us different</router-link>
          <router-link to="/fallen-heros">Our Fallen Heros</router-link>
          <router-link to="/pre-planning/pre-planning">Resources &amp; References</router-link>

          <router-link to="/affiliate-providers">Affiliate Program</router-link>
          <router-link to="/contact-us">Customer Service &amp; Support</router-link>
        </div>
      </div>
    </transition>
  </div>

</template>
<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "Burger",
  mixins: [clickaway],

  data: function() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleSlider() {
      this.isOpen = !this.isOpen;
    },
     hideSlider() {
      this.isOpen = false;
    }
  }
};
</script>

<style scoped>
@import "../assets/css/Burger.css";
@import "../assets/css/SliderMenu.css";

.buttons {
  @apply flex;
  @apply items-center;
  @apply justify-between;
}

.outline {
  @apply flex;
  @apply items-center;
  @apply text-base;
  @apply justify-center;
  @apply py-1;
  @apply border;
  @apply border-cream;
  @apply rounded-sm;
  @apply w-24;
}

.outline:hover {
  @apply text-white;
  @apply border-white;
}

.selected {
  @apply bg-melon;
  @apply text-white;
}

</style>
