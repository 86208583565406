<template>
  <div>
    <h1>Checkout</h1>
    <div class="mx-auto max-w-2xl">
        <div :class='{"md:flex": options.isPrintOrder}'>
          <div class="flex-1 mb-6" :class='{"md:mr-3": options.isPrintOrder}'>
            <div class="mx-auto max-w-md rounded-lg text-left border border-grey-light bg-grey-lightest">
              <h5 class="text-lg md:text-xl p-2 bg-grey-lighter border-b border-grey-light">Order Summary<span class="font-medium float-right pr-3">
                <router-link :to="{ name: 'print-options', params: { id: bookletId, printOptions: options, shippingAddress: shippingAddress }}" class="text-sm italic">Edit</router-link></span></h5> 
              <div class="flex m-4 px-4" :class='{"md:px-12": !options.isPrintOrder}'>
                <div class="flex-auto text-sm">
                  <div>
                    <div class="inline-block w-32">Decendent's Name :  </div>
                    <div class="inline-block">{{ booklet.deceasedName }}</div>          
                  </div>
                  <div>
                    <div class="inline-block w-32">Template : </div>
                    <div class="inline-block">{{ checkoutOptions.bookletName }}</div>          
                  </div>                  
                  <div class="pb-3">
                    <div class="inline-block w-32">PDF File : </div>
                    <div class="inline-block">Delivered via Email</div>          
                  </div>
                  <div v-if="options.isPrintOrder" class="pb-3">50% Discount w/ Print Order</div> 
                  <div>Purchase Price</div>  
                </div>
                <div class="flex-auto text-right self-end">{{ options.bookletAmount | toCurrency }}</div>
              </div>
              <div v-if="options.isPrintOrder">
                <div class="flex m-4 px-4 pt-4 border-t border-grey-light">
                  <div class="flex-auto text-sm">
                    <div class="pb-1">{{ options.printOptions.quantity }} - Copies</div>
                    <div v-if="isMultiPage" class="pb-1">{{ checkoutOptions.paperName }} - {{ checkoutOptions.coverGrade }} / {{ checkoutOptions.paperName }} - {{ checkoutOptions.interiorGrade }}</div>
                    <div v-if="!isMultiPage" class="pb-1">{{ checkoutOptions.paperName }} - {{ checkoutOptions.coverGrade }}</div>
                    <div class="pb-1">{{ options.printOptions.paperColor }}</div>
                  </div>
                  <div class="flex-auto text-right self-end">{{ options.printAmount | toCurrency }}</div>
                </div>
              </div>
            </div>
            <div v-show="options.isPrintOrder" class="mt-6 mx-auto max-w-md rounded-lg text-left border border-grey-light bg-grey-lightest">
              <h5 class="text-lg md:text-xl p-2 bg-grey-lighter border-b border-grey-light">Shipping Address</h5> 
              <div class="m-4 px-4">
                <div class="md:flex">
                  <div class="flex-1 md:pr-1">
                    <div class="text-sm pb-1">First Name<span class="required">*</span></div>
                    <div><input name="ShippingFirstName" type="text" v-model="shippingAddress.firstName" class="w-full"/></div>
                  </div>
                  <div class="flex-1 md:pl-1">
                    <div class="text-sm pb-1">Last Name<span class="required">*</span></div>
                    <div><input name="ShippingLastName" type="text" v-model="shippingAddress.lastName" class="w-full"/></div>
                  </div>      
                </div>
                <div class="pt-1">
                  <div class="text-sm pb-1">Street Address<span class="required">*</span></div>
                  <div><input name="ShippingAddress1" type="text" v-model="shippingAddress.address1" class="w-full"/></div>
                </div>
                <div class="pt-2">
                  <div><input name="ShippingAddress2" type="text" v-model="shippingAddress.address2" class="w-full"/></div>
                </div> 
                <div class="md:flex pt-1">
                  <div class="flex-1 md:pr-2">
                    <div class="text-sm pb-1">City<span class="required">*</span></div>
                    <div><input name="ShippingCity" type="text" v-model="shippingAddress.city" class="w-full"/></div>
                  </div>
                  <div class="flex-1 md:pl-2 md:pr-2">
                    <div class="text-sm pb-1">State<span class="required">*</span></div>
                    <div><input name="ShippingState" type="text" v-model="shippingAddress.state" class="w-full"/></div>
                  </div>      
                  <div class="flex-1 md:pl-2">
                    <div class="text-sm pb-1">Zip Code<span class="required">*</span></div>
                    <div><input name="ShippingZipCode" type="text" v-model="shippingAddress.zipCode" class="w-full"/></div>
                  </div>        
                </div>    
              </div>
            </div>            
          </div>
          <div class="flex-1" :class='{"md:ml-3": options.isPrintOrder}'>
            <div class="mx-auto max-w-md rounded-lg text-left border border-grey-light bg-grey-lightest">
              <h5 class="text-lg md:text-xl p-2 bg-grey-lighter border-b border-grey-light">Payment Information</h5> 
              <div class="m-4 px-4 md:px-12">
                <div v-show="options.isPrintOrder">
                  <div class="flex mt-2">
                    <div class="flex-auto">SUB-TOTAL</div>
                    <div class="flex-auto text-right">{{ options.subTotal | toCurrency }}</div>
                  </div>
                  <div class="flex mt-4 pt-4 border-t border-grey-light">
                    <div class="flex-auto">UPS Overnight Shipping &amp; Handling</div>
                    <div class="flex-auto text-right">- Free -</div>
                  </div>
                  <div class="flex mt-4 pt-4 border-t border-grey-light">
                    <div class="flex-auto self-end">ORDER TOTAL</div>
                    <div class="flex-auto text-right text-xl md:text-2xl">{{ options.subTotal | toCurrency }}</div>
                  </div> 
                </div>
                <div v-if="!options.isPrintOrder" class="flex mt-4">
                  <div class="flex-auto self-end">ORDER TOTAL</div>
                  <div class="flex-auto text-right text-xl md:text-2xl">{{ options.subTotal | toCurrency }}</div>
                </div> 
                <div class="text-center mt-4 pt-4 border-t border-grey-light">
                  <img src="@/assets/images/powered-by-stripe-cc.jpg"/>
                </div> 
                
                <form method="post" id="payment-form">
                  <div class="mt-4 mb-1 text-sm">Credit Card<span class="required">*</span></div>     
                  <div class="text-center">
                    <div class="border p-2 rounded-sm w-full mx-auto">
                      <div id="card-element"></div>
                    </div>
                    <div id="card-errors" role="alert">{{ error }}</div>
                  </div>
                  <div class="my-10 text-center">
                    <process-button :enabled="completeEnabled" class="btn" @click="purchase">
                      <span slot="content">Complete Purchase</span>
                      <span slot="processingContent">Processing...</span>
                    </process-button>
                  </div>
                </form>      
              </div>
            </div>  
            <div v-show="options.isPrintOrder" class="text-left mt-6 max-w-md rounded-lg text-left border border-grey-light bg-white">
              <div class="m-4 md:px-4">
                    <div class="text-sm pb-1">Funeral Home/Church Parish Identifier<span @click="showModal=true" class="pl-4 text-xs text-blue cursor-pointer">What is this?</span></div>
                    <div><input name="Identifier" type="text" @keyup="changed($event)" @paste="changed($event)" v-model="identifier" class="w-full sm:w-1/2"/></div>
                    <div class="text-sm pl-2 pt-2">{{ affiliateName }}</div>
              </div>
            </div>          
          </div>
        </div>      
    </div>
    <p class="error-summary"></p>
    <show-modal-popup :showModal="showModal" modal-class="max-w-lg p-12" @close="closeModal">
      <div slot="modal-content">
            <h1 class="text-xl md:text-2xl text-navy mb-3">What is a Funeral Home/Church Parish Identifier?</h1>
            <p class="text-sm">
                This identifying number, identifies the funeral home or church parish who is planning the funeral service. Contact your funeral planner to obtain an identifting number.  If one is unavailable, please leave this entry blank.
            </p>
            <div class="mt-6 pt-6 text-right border-t border-grey-light">
                <button type="button" class="btn" data-modal-action="cancel" >
                    Close
                </button>                
            </div>
      </div>
    </show-modal-popup>
  </div>
</template>

<script>
  import * as api from '../client/api.js'
  import {mapGetters} from 'vuex'
  import ProcessButton from "../components/ProcessButton"
  import ShowModalPopup from "../components/ShowModalPopup"
  import retry from '../tools/retry'

  let stripe = null;
  let elements = null;
  let card = null;

  export default {
    name: "Checkout",
    components: { ProcessButton, ShowModalPopup },
    props: ['bookletId', 'checkoutOptions'],
    data() {
      return {
        id: null,
        shippingAddress: { 
          "firstName": null, 
          "lastName": null, 
          "address1": null, 
          "address2": null,
          "city": null,
          "state": null,
          "zipCode": null,
          },
        cardComplete: false,
        booklet: {},
        callback: null,
        error: null,
        options: {},
        identifier: null,
        affiliateName: null,
        showModal: false,
      }
    },
    computed: {
      ...mapGetters(['signedIn', 'currentUser']),
      isMultiPage: function() {
        return this.booklet.bookletTypeName && !this.booklet.bookletTypeName.toLowerCase().startsWith("four");
      },
      completeEnabled: function () {
        const self = this

        const enabled = self.cardComplete && (
          !self.options.isPrintOrder ||
          (self.shippingAddress.firstName && self.shippingAddress.lastName &&
            self.shippingAddress.address1 && self.shippingAddress.city &&
            self.shippingAddress.state && self.shippingAddress.zipCode)
        )

        return enabled
      },
      postOrderArgs: function () {
        const self = this
        const shippingAddress = self.options.isPrintOrder ? self.shippingAddress : null
        const printOptions = self.options.isPrintOrder ? {
            pricingId: self.options.pricing.id,
            paperColor: self.options.printOptions.paperColor
          } : null

        return {
          bookletId: self.bookletId,
          identifier: self.identifier,
          shippingAddress: shippingAddress,
          printOptions: printOptions
        }
      }
    },
    methods: {
      processPayment() {
        const self = this

        stripe.createToken(card, { name:  self.currentUser.emailAddress }).then(result => {
          if (result.error) {
            this.doCallback();
            self.hasCardErrors = true
            self.$forceUpdate()
          }
          else {
            const data = self.postOrderArgs

            data.paymentToken = result.token.id
            
            api.OrdersPost({ args: data }).then(response => {
              self.waitForDownloadable(result => {
                this.doCallback();
                if (self.$config.googleAdsConversionId) {
                  self.$gtag.event('conversion', { send_to: self.$config.googleAdsConversionId + '/2shWCOTW_tgBELjksqIC', transaction_id: orderId })
                }

                self.$router.push({ name: 'confirmation', params: { data: { bookletId: self.bookletId, orderId: response.data, downloadable: result, options: self.options, shippingAddress: self.shippingAddress, identifier: self.identifier } } })
              });

            }).catch(error => {
              this.doCallback();
              self.processErrorMessages(error)
              self.handleApiError(error)
            })
          }
        })
      },
      purchase(callback) {
        this.callback = callback
        const self = this

        self.processPayment()
      },
      waitForDownloadable(callback) {
        let self = this;

        let operation = () => {
          return new Promise((resolve) => {
            api.BookletsByIdGet({ id: self.bookletId }).then(response => {
              if (response && response.status === 200 && response.data.downloadable === true) {
                resolve(true);
              }
            }).catch();
          });
        };

        retry(2000, 5, operation)
          .then(x => callback(true))
          .catch(e => callback(false));
      },
      doCallback() {
        if (this.callback) {
          this.callback();
        }
      },
      changed(event) {
        this.$nextTick(() => {
          let self = this;
          self.affiliateName = null; 

          let value = event.target.value;

          if(value){
            api.AffiliatesByIdentifierGet({ identifier: value })
              .then(response => {
                if (response && response.data) {
                  self.affiliateName = response.data;          
                }
              }).catch(e => {
                self.processErrorMessages(e);
                self.handleApiError(e);
            });          
          }
        });
      }
    },
    mounted() {
      let self = this
      
      if(!self.bookletId){
        self.$router.replace({ name: 'my-booklets'})
      } else if(self.checkoutOptions === undefined) {
        self.$router.replace({ name: 'print-options', params: { id: self.bookletId }})
      }
    },
    created() {
      let self = this

      if(self.bookletId && self.checkoutOptions) {
        self.options = self.checkoutOptions

        if (self.options.shippingAddress) {
          self.shippingAddress = self.options.shippingAddress
        } else {
          self.shippingAddress.firstName = self.currentUser.firstName
          self.shippingAddress.lastName = self.currentUser.lastName
        }

        api.BookletsByIdGet({ id: self.bookletId }).then(response => {
          if (response && response.data) {
            self.booklet = response.data;
          }
        }).catch(e => {
          self.processErrorMessages(e);
          self.handleApiError(e);
        });

        self.$nextTick(() => {
          if (!stripe) {
            stripe = Stripe(self.$config.stripe.publishableKey)
            elements = stripe.elements()
            card = elements.create('card', { hidePostalCode: true })
          }

          card.mount('#card-element')

          card.on('change', function (event) {
            self.cardComplete = event.complete
            self.error = event.error ? event.error.message : ''
          })
        })
      }
    },
    filters: {
      toCurrency: function (value) {
        if (typeof value !== "number") {
            return value;
        }
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        });
        
        return formatter.format(value);
      }}
  }
</script>

<style scoped>
.required {
  @apply text-red;
  @apply font-semibold;
  @apply pl-1;
}
.icon {
  @apply h-4;
  @apply w-4;
}
</style>
