<template>
  <transition name="fade" appear>
    <div v-show="open" class="fixed pin p-8 z-50" style="background-color: hsla(0, 0%, 0%, .5)">
      <on-click-outside :do="close">
        <div ref="modal" class="w-full mx-auto bg-white rounded p-6 mt-4 sm:mt-8 z-10 shadow-lg">
          <slot name="modal-content"></slot>
        </div>
      </on-click-outside>
    </div>
  </transition>
</template>

<script>
  import OnClickOutside from "./OnClickOutside";

  export default {
    name: 'Modal',
    components: { OnClickOutside },
    props: {
      open: { required: true },
      preventBackgroundScrolling: { default: true },
      modalClass: { default: 'max-w-sm' }
    },
    watch: {
      open: {
        immediate: true,
        handler: function(show) {
          if (show) {
            this.preventBackgroundScrolling && document.body.style.setProperty('overflow', 'hidden');
            this.$nextTick(() => {
              this.$el.querySelectorAll('.scrollable-container').forEach(x => x.scrollTop = 0);
            })
          } else {
            this.preventBackgroundScrolling && document.body.style.removeProperty('overflow');
          }
        }
      }
    },
    methods: {
      close(result) {
        result = result || 'cancel';

        if (this.open) {
          this.$emit('close', result);
        }
      }
    },
    created() {
      const escapeHandler = e => {
        if (e.key === 'Escape' && this.open) {
          this.close('cancel');
        }
      };

      document.addEventListener('keydown', escapeHandler);

      this.$once('hook:destroyed', () => {
        document.removeEventListener('keydown', escapeHandler);
      });
    },
    mounted() {
      let classes = this.modalClass.match(/\S+/g) || [];

      classes.forEach(x => {
        this.$refs.modal.classList.add(x);
      });

      let self = this;
      let buttons = this.$el.querySelectorAll('button[data-modal-action]');

      buttons.forEach(button => {
        let action = button.attributes['data-modal-action'].value;
        button.addEventListener('click', () => self.close(action));
      });
    }
  }
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>