<template>
  <div class="md:flex mx-auto">
    <div class="md:flex-1 md:border-r md:border-grey-light md:py-12 md:pr-12">
      <h5 v-if="!isForPricing" class="text-xl md:text-2xl pb-6">Print Options</h5>
      <h5 v-if="isForPricing" class="text-xl md:text-2xl pb-6">Pricing Calculator</h5>
      <div class="pb-8 text-blue italic">
        <b>Step 1 -</b>
        <span class="pl-2">Select 'Print With Us' or 'Print Yourself'</span>
      </div>
      <div @click='isPrintOrder = true' class="selector" :class='{"selected": isPrintOrder}'>
        
      <div class="md:flex mx-auto items-center justify-center">
          <CircleCheckMark v-if="isPrintOrder" class="checkmark"></CircleCheckMark>
          <div>
            <h5 class="text-xl md:text-lg whitespace-no-wrap text-center ">Print With Us</h5>
            <div class="text-4xl text-center "><span class="text-grey line-through pr-3">$99</span><span class="font-medium">$49</span></div>
          </div>
          <ul>
            <li>Includes:</li>
            <li>Template Creation</li>
            <li>Premium Paper Selection</li>
            <li>FREE Overnight Shipping</li>
          </ul>            
        </div>

      </div>
      <div v-if="isPrintOrder">
        <div class="my-6 text-blue italic">
          <b>Step 2 -</b>
          <span class="pl-2">Select Premium Paper Stock, Color &amp; Print Quantity</span>
        </div>
        <div class="md:mb-6">
          <div class="pb-2">
            <span class="font-medium">Premium Paper</span> : Cover Weight &amp; Stock
          </div>
          <select v-model="options.coverPaperId" class="w-full">
            <option value="0">-- Select Cover Weight &amp; Stock with Matching Text --</option>
            <option
              v-for="paperWeight in paperWeights"
              v-bind:value="paperWeight.value"
              v-bind:key="paperWeight.value"
            >{{ paperWeight.text }}</option>
          </select>
          <div v-if="isMultiPage">
            <div class="pt-6 text-blue italic">
              <b>Step 3 -</b>
              <span
                class="pl-2"
              >Check the box below to use selected Cover Stock for both Cover &amp; Text pages</span>
            </div>
            <div class="pt-4">
              <label for="useCoverForInterior">
                <input
                  id="useCoverForInterior"
                  type="checkbox"
                  v-model="options.useCoverPaperForInterior"
                />
                <span class="pl-4">Use Selected Cover Stock as Cover &amp; Text</span>
              </label>
            </div>
          </div>
          <div
            v-if="isOutOfStock"
            class="pt-6 text-sm md:text-md text-red-dark italic"
          >* This preminum paper and color combination is currently out-of-stock, please make a new selection</div>
          <div class="font-medium pt-6 pb-2">Premium Paper : Color</div>
          <select v-model="options.paperColor" class="w-full">
            <option value="0">-- Select Premium Paper Color --</option>
            <option
              v-for="paperColor in paperColors"
              v-bind:key="paperColor.text"
            >{{ paperColor.text }}</option>
          </select>
          <div class="font-medium pt-6 pb-2">Print Quantity</div>
          <select v-model="options.quantity" class="w-full">
            <option value="0">-- Select Print Quantity --</option>
            <option
              v-for="printQuantity in printQuanties"
              v-bind:value="printQuantity.value"
              v-bind:key="printQuantity.value"
            >{{ printQuantity.text }}</option>
          </select>
          <div
            class="pt-6 text-sm italic text-blue"
          >Contact us directly for all Orders over 500.</div>
        </div>
      </div>
      <div @click='isPrintOrder = false' class="selector mt-16" :class='{"selected": !isPrintOrder}'>

      <div class="md:flex mx-auto items-center justify-center">
          <CircleCheckMark v-if="!isPrintOrder" class="checkmark"></CircleCheckMark>
          <div>
            <h5 class="text-xl md:text-lg whitespace-no-wrap text-center">Print Yourself</h5>
            <div class="text-4xl text-center font-medium">$99</div>
          </div>
          <ul>
            <li>Includes:</li>
            <li>Template Creation</li>
            <li>Print-ready PDF File</li>
            <li>Utilize Preferred Printing</li>
          </ul> 
        </div>
      </div>
    </div>
    <div class="md:flex-1 md:py-12 md:pl-12">
      <h5 class="text-xl md:text-2xl pb-6">Order Summary</h5>
      <div class="md:mx-6">
        <div v-if="booklet.deceasedName" class="flex">
          <div class="flex-auto">Decendent's Name :</div>
          <div class="flex-auto whitespace-no-wrap text-right">{{ booklet.deceasedName }}</div>
        </div>
        <div class="flex">
          <div class="flex-auto">Template :</div>
          <div class="flex-auto text-right">{{ bookletName }}</div>
        </div>
        <div class="flex">
          <div class="flex-auto">PDF File :</div>
          <div class="flex-auto text-right">Delivered via Email</div>
        </div>
        <div class="flex mt-4">
          <div v-if="isPrintOrder" class="flex-auto self-end">Booklet Creation</div>
          <div v-if="!isPrintOrder" class="flex-auto self-end">Purchase Price</div>
          <div class="flex-auto text-right text-xl md:text-2xl">$99.00</div>
        </div>
        <div v-if="isPrintOrder">
          <div class="flex mt-2">
            <div class="flex-auto">50% Discount w/ Print Order</div>
            <div class="flex-auto text-right">
              <span class="text-red">-$50.00</span>
            </div>
          </div>
          <div class="flex mt-2">
            <div class="flex-auto">Discount Price</div>
            <div class="flex-auto text-right">$49.00</div>
          </div>
          <div class="flex mt-4 pt-4 border-t border-grey-light">
            <div class="flex-auto">
              <div v-if="paperSelected">
                <div class="pb-1">{{ options.quantity }} Copies</div>
                <div
                  class="pb-1"
                  v-if="isMultiPage"
                >{{ paperName }} - {{ coverGrade }} / {{ paperName }} - {{ interiorGrade }}</div>
                <div class="pb-1" v-if="!isMultiPage">{{ paperName }} - {{ coverGrade }}</div>
                <div class="pb-1">{{ options.paperColor }}</div>
              </div>
              <div v-if="!paperSelected">Booklet Printing</div>
            </div>
            <div class="flex-auto self-end text-right">
              <div v-if="paperSelected">{{ printAmount | toCurrency }}</div>
              <div v-if="!paperSelected" class="italic">- Pending Selection -</div>
            </div>
          </div>
          <div class="flex mt-4 pt-4 border-t border-grey-light">
            <div class="flex-auto self-end">SUB-TOTAL</div>
            <div class="flex-auto text-right text-xl md:text-2xl">{{ subTotal | toCurrency }}<span v-if="isForPricing">*</span></div>
          </div>
          <div class="flex mt-4 pt-4 border-t border-grey-light">
            <div class="flex-auto self-end">
              Overnight Shipping &amp; Handling
              <br />
              <span class="text-sm">Free For Limited Time</span>
            </div>
            <div class="flex-auto text-right self-end">- FREE -</div>
          </div>
          <div v-if="isForPricing" class="mt-4 pt-4 border-t border-grey-light text-sm italic">
            * Additional discounts may apply
          </div>

        </div>
          <div v-if="!isForPricing" class="mt-6 pt-6 text-right border-t border-grey-light">
            <button :disabled="!checkoutEnabled" @click="checkout" class="btn mx-auto">Checkout</button>
          </div>      
        </div>
    </div>
  </div>
</template>

<script>
import * as api from '../client/api.js'
import PaperWeights from "../assets/json/PaperWeights.json";
import PaperColors from "../assets/json/PaperColors.json";
import PrintQuanties from "../assets/json/PrintQuanties.json";
import CircleCheckMark from '../components/svg/CircleCheckMark.svg'

export default {
  name: 'PriceModel',
  props: [ 'printOptions', 'shippingAddress' ],

  data() {
    return {
      booklet: {},
      paperWeights: PaperWeights,
      paperColors: PaperColors,
      printQuanties: PrintQuanties,
      pricing: {},
      isPrintOrder: true,
      options: {
        bookletTypeName: null,
        coverPaperId: 0,
        quantity: 0,
        paperColor: 0,
        useCoverPaperForInterior: false
      },
      bookletName: null, 
    };
  },
  components: {
    PaperWeights,
    PaperColors,
    PrintQuanties,
    CircleCheckMark
  },
  methods: {
    getBookletTitle: function(val) {
      if (val && val.toLowerCase().startsWith("four")) return "4 Page Funeral Booklet";
      if (val && val.toLowerCase().startsWith("eightpagefull")) return "8 Page Full Funeral Booklet";
      if (val && val.toLowerCase().startsWith("eight")) return "8 Page Funeral Booklet";
      if (val && val.toLowerCase().startsWith("twelve")) return "12 Page Funeral Booklet";
      return ""
    },
    checkout() {
      let self = this;
      self.$router.push({ 
        name: 'checkout', 
        params: { 
          bookletId: self.booklet.id,
          checkoutOptions: self.checkoutOptions }})
    },
    expandAll() {
      self.$emit("closeAll");
    }
  },
  watch: {
    paperSelectionHash: function(val){
      let self = this;
      
      if (self.paperSelected) {
        api.PricingGet(self.options).then(response => {
            self.pricing = response.data
        }).catch(e => {
            self.processErrorMessages(e)
            self.handleApiError(e)
        });
      }
    },
    bookletTypeOption: {
      handler: function (bookletTypeOption) {
        let self = this;

        self.options.bookletTypeName = bookletTypeOption
        self.booklet.bookletTypeName = bookletTypeOption
        self.bookletName = self.getBookletTitle(bookletTypeOption)
      },
      immediate: true
    } 
  },
  computed: {
    bookletTypeOption: function () {
      return this.printOptions.printOptions.bookletTypeName
    },
    paperSelectionHash: function () {
      const self = this

      const hash = self.options ? `${self.options.coverPaperId}:${self.options.quantity}:${self.options.paperColor}:${self.options.useCoverPaperForInterior}:${self.bookletName}` : ''
      return hash
    },
    paperSelected: function () {
      const self = this

      return (self.options.coverPaperId && self.options.coverPaperId !== "0") &&
          (self.options.quantity && self.options.quantity !== "0") &&
          (self.options.paperColor && self.options.paperColor !== "0") &&  self.bookletName
    },
    isOutOfStock: function() {
      const self = this

      if (self.options.coverPaperId == 1 && self.options.paperColor == "Natural") return true //80# Gloss - Natural
      if (self.options.coverPaperId == 4 && self.options.paperColor == "Natural") return true //100# Gloss - Natural
      return false
    },
    isMultiPage: function() {
      return this.booklet.bookletTypeName && !this.booklet.bookletTypeName.toLowerCase().startsWith("four");
    },
    bookletAmount: function () {
      return this.isPrintOrder ? 49 : 99
    },
    printAmount: function () {
      return this.paperSelected ? this.pricing.amount : 0
    },
    subTotal: function() {
      return this.isPrintOrder ? this.bookletAmount + this.printAmount : this.bookletAmount
    },
    paperName: function() {
      const paperName = this.pricing.coverPaper ? `${this.pricing.coverPaper.name}` : ''
      return paperName
    },
    coverGrade: function() {
      const coverGrade = this.pricing.coverPaper ? `${this.pricing.coverPaper.grade.name}` : ''
      return coverGrade
    },
    interiorGrade: function() {
      const coverGrade = this.pricing.interiorPaper ? `${this.pricing.interiorPaper.grade.name}` : ''
      return coverGrade
    },
    isForPricing: function() {
      return (typeof this.$route.params.id === 'undefined');
    },      
    checkoutEnabled: function () {
      return (this.isPrintOrder && this.paperSelected && !this.isOutOfStock) || !this.isPrintOrder
    },
    checkoutOptions: function () {
      const self = this;

      return {
        bookletName: self.bookletName,
        shippingAddress: self.shippingAddress,
        printOptions: self.options,
        isPrintOrder: self.isPrintOrder,
        paperName: self.paperName,
        coverGrade: self.coverGrade,
        interiorGrade: self.interiorGrade,
        bookletAmount: self.bookletAmount,
        printAmount: self.printAmount,
        subTotal: self.subTotal,
        pricing: self.pricing,
        isMultiPage: self.isMultiPage
      }
    }
  },
  created() {
    const self = this
    const printOptions = self.printOptions

    if(printOptions){
      self.isPrintOrder = printOptions.isPrintOrder
      self.options = printOptions.printOptions,
      self.pricing = printOptions.pricing
    }

    api.BookletsByIdGet({ id: self.$route.params.id })
      .then(response => {
        if (response && response.data) {
          self.booklet = response.data
          self.options.bookletTypeName = self.booklet.bookletTypeName
          self.bookletName = self.getBookletTitle(self.options.bookletTypeName)
      }
      }).catch(e => {
        self.processErrorMessages(e);
        self.handleApiError(e);
      });
  
    if(!self.booklet.bookletTypeName) {
        self.booklet.bookletTypeName = self.options.bookletTypeName
        self.bookletName = self.getBookletTitle(self.booklet.bookletTypeName)
    }
  },
  filters: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
          return value;
      }
      var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
      });
      
      return formatter.format(value);
    }
  }
};
</script>

<style scoped>
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  ul > li {
    @apply italic;
    @apply text-sm;
    @apply pb-1; ;
    @apply whitespace-no-wrap;
  }
  ul > li:first-child {
    @apply -ml-4;
    list-style: none;
  }

  .selector {
    @apply pr-4;
    @apply py-4;
    @apply rounded-lg; 
    @apply cursor-pointer;
    @apply border-grey;
    @apply border-2;
    @apply bg-blue-lighter;
    @apply text-grey-darker;
    }
  
  .selector:hover {
    @apply bg-navy;
    @apply text-grey-lighter;
  }
  
  .selected {
    @apply bg-navy;
    @apply text-grey-lighter;
  }
  
  .checkmark {
    @apply relative;
    @apply h-10;
    @apply w-10; 
    left:-20px;
  }

  
  @media (max-width: 768px) {
    .checkmark {
      @apply -mt-12;
      top: 103px;
    }
    ul {
      @apply pl-24;
    }
  }
</style>