<template>
  <div>
    <label v-html="label"></label>
        <input
           type="date"
           :name="name"
           :value="value | dateOnly"
           @input="isDate($event.target.value)"
           :placeholder="placeHolder" />
  </div>
</template>
<script>
  import '../../filters/DateOnly'
  import moment from 'moment'

  export default {
    name: 'DateInput',
    props: ['placeholder', 'label', 'name', 'value'],
    methods: {
      isDate(value) {
        if(!value || !isNaN(Date.parse(value)) && moment(value, 'YYYY-MM-DD',true).isValid()) {
          this.$emit('input', value);
        }
      }
    },
    computed: {
      placeHolder() {
        var safari = navigator.userAgent.toLowerCase().indexOf('safari') > -1
        var ie = navigator.userAgent.toLowerCase().indexOf('msie') > -1 || navigator.userAgent.toLowerCase().indexOf('trident') > -1 
        return safari || ie ? "ex. 2019-01-31" : this.placeholder; 
      }
    }
  }
</script>