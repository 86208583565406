<template>
  <div>
   <h1>Booklet Creation</h1>
    <h2 class="text-xl md:text-2xl text-black max-w-xl mx-auto pb-8">select… create &amp; design.. review &amp; approve... print &amp; deliver</h2>
    
    <div class="text-md md:text-lg mx-auto max-w-xl text-left">
      <p>
        MY WAY BOOKLETS' simplistic templates are straight forward and user friendly. They are designed to assist you with the creation of a custom funeral Mass booklet that will honor and celebrate the life of your loved one, while calling to mind the hearts and souls of all in attendance with a treasured memorable keepsake.
      </p>
      <p>
        MY WAY BOOKLETS' integrates all points covered in accordance with the Order of Christian Funerals, including the Cremation Rite and <i>provides the complete selection of requested scripture readings for the First Reading, the Second Reading, the Gospel Reading, the Responsorial Psalms and the Hymns</i> recommended for use when planning a traditional Catholic Funeral Mass in the presence of the body or cremated remains.
      </p>
      <p>
        In addition to integrating all points covered in the Order of Christian Funerals with Cremation, MY WAY BOOKLETS' also gives you the <i>ability to add personal elements such as favorite photos, poems, prayers, quotes and expressions of gratitude</i>.
      </p>
      <p>
        We are honored to assist with your creation of a uniquely designed Mass booklet. And we confidently guarantee that the matchless features of the templates will impress your family and friends with a lasting impression of love and devotion.
      </p>
    </div>
    <h2 class="text-xl md:text-2xl text-center my:6 md:my-12">Select a template to begin</h2>
    <div class="md:flex max-w-xl mx-auto mb-24">
      <div class="md:flex-1 md:border-r md:border-grey-light">
        <circle-one class="h-24 md:h-32 my-4"/>
        <h3 class="text-md md:text-lg pb-6">4 page -<br>scripture titles</h3>
        <div class="text-sm italic font-medium text-blue hover:text-melon mb-8 cursor-pointer" @click="toggleModal('FourPageFuneralBooklet')">View sample</div>
        <router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'FourPageFuneralBooklet' }}" class="btn mx-auto mb-8">Select</router-link>
      </div>
      <div class="md:flex-1 md:border-r md:border-grey-light">
        <circle-two class="h-24 md:h-32 my-4"/>
        <h3 class="text-md md:text-lg pb-6">8 page -<br>scripture titles</h3>
        <div class="text-sm italic font-medium text-blue hover:text-melon mb-8 cursor-pointer" @click="toggleModal('EightPageFuneralBooklet')">View sample</div>
        <router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'EightPageFuneralBooklet' }}" class="btn mx-auto mb-8">Select</router-link>
      </div>      
      <div class="md:flex-1 md:border-r md:border-grey-light">
        <circle-two class="h-24 md:h-32 my-4"/>
        <h3 class="text-md md:text-lg pb-6">8 page -<br>full scripture</h3>
        <div class="text-sm italic font-medium text-blue hover:text-melon mb-8 cursor-pointer" @click="toggleModal('EightPageFullFuneralBooklet')">View sample</div>
        <router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'EightPageFullFuneralBooklet' }}" class="btn mx-auto mb-8">Select</router-link>
      </div>       
      <div class="md:flex-1">
        <circle-three class="h-24 md:h-32 my-4"/>
        <h3 class="text-md md:text-lg pb-6">12 page -<br>full scripture</h3>
        <div class="text-sm italic font-medium text-blue hover:text-melon mb-8 cursor-pointer" @click="toggleModal('TwelvePageFuneralBooklet')">View sample</div>
        <router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'TwelvePageFuneralBooklet' }}" class="btn mx-auto">Select</router-link>
      </div>  
    </div>
    <hr>
    <router-link to="/funeral-booklet-options">
      <h2 class="text-2xl md:text-3xl text-center hover:text-melon">Can't decide? Compare For Yourself</h2>
    </router-link>
    <show-modal-popup :showModal="showModal" modal-class="max-w-lg p-12">
      <div slot="modal-content">
          <sample-generator :templateName="templateName"></sample-generator>
      </div>
    </show-modal-popup>
  </div>
</template>

<script>
  import CircleOne from '../components/svg/CircleOne.svg'
  import CircleTwo from '../components/svg/CircleTwo.svg'
  import CircleThree from '../components/svg/CircleThree.svg'
  import ShowModalPopup from "../components/ShowModalPopup"
  import SampleGenerator from "../components/SampleGenerator"
  
  export default {
    name: "FuneralBooklets",
    data() {
        return {
            showModal: false,
            templateName: null
        }
    }, 
    components: { CircleOne, CircleTwo, CircleThree, ShowModalPopup, SampleGenerator },
    methods: {
      toggleModal(bookletName) {
          let self = this;

          self.templateName = bookletName;
          self.showModal = true;
      }
    }
  }

</script>

<style scoped>
hr {
  @apply my-16;
  @apply max-w-md;
  @apply border-0;
  @apply h-px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));  
}

h2:hover {
  cursor: pointer;
}
</style>
