<template>
  <div class="relative">
    <router-link
      to="#"
      class="flex items-center"
      v-on-clickaway="hideMegaMenu"
      v-on:click.native="toggleMegaMenu"
      v-bind:class="{ activated: isMegaMenuActive }"
      @keydown.space.exact.prevent="toggleMegaMenu"
      @keydown.escape.exact="hideMegaMenu"
    >
      Features
      <Dropdown :class="{rotate:isMegaMenuActive}" class="downArrow h-5 ml-1" />
    </router-link>
    <transition name="megamenu-fade">
      <div v-if="isMegaMenuActive" class="megamenu-panel">
        <div class="flex px-8 pt-6 border-b border-grey-light">
          <ul class="w-1/2 list-reset mr-4">
            <li>
              <router-link to="/how-it-works" class="flex">
                <BookletOpen class="h-5" />
                <span class="ml-2">
                  <span class="block">On-line Booklet Creation</span>
                  <span class="text-xs normal-case text-gray">
                    <span class="block">On-line Creation In 4 Easy Steps</span>
                    <span class="block">Time Is Of The Eessence</span>
                    <span class="block">Create, Approve, Print, Deliver</span>
                  </span>
                </span>             
              </router-link>
            </li>
            
            <li>
              <router-link to="/pre-planning" class="flex">
                <Heart class="h-5" />
                <span class="ml-2">
                  <span class="block">Preplan a Funeral Mass</span>
                  <span class="text-xs normal-case text-gray">
                    <span class="block">Prepare For The Future</span>                     
                    <span class="block">Why Pre-plan</span>                     
                    <span class="block">Save &amp; Retrieve Your Booklet</span>                   
                  </span>
                </span>             
              </router-link>
            </li>

            <li>
              <router-link to="/fallen-heros" class="flex">
                <Praying class="h-5" />
                <span class="ml-2">
                  <span class="block">Our Fallen Heros</span>
                  <span class="text-xs normal-case text-gray">
                    <span class="block">Children</span>
                    <span class="block">COVID-19 Families</span>
                    <span class="block">Veterans</span>
                  </span>
                </span>             
              </router-link>
            </li>
          </ul>
          
          <ul class="w-1/2 list-reset">
            <li>
              <router-link to="/pricing" class="flex">
                <DollarSign class="h-5" />
                <span class="ml-2">
                  <span class="block">What's The Cost</span>
                  <span class="text-xs normal-case text-gray">
                    <span class="block">Selection Based Pricing</span>
                    <span class="block">Free Next Day Shipping</span>
                    <span class="block">Our Guarantee</span>
                  </span>
                </span>             
              </router-link>
            </li>            
            <li>
              <router-link to="/about-us" class="flex">
                <Star class="h-5" />
                <span class="ml-2">
                  <span class="block">About Us</span>
                  <span class="text-xs normal-case text-gray">
                    <span class="block">What makes us different</span>
                    <span class="block">Our Mission</span>
                    <span class="block">Dedication</span>
                  </span>
                </span>             
              </router-link>
            </li>
            
            <li>
              <router-link to="/resources" class="flex">
                <Processing class="h-5" />
                <span class="ml-2">
                  <span class="block">Resources &amp; References</span>
                  <span class="text-xs normal-case text-gray">
                    <span class="block">Order Of A Christian Funeral</span>
                    <span class="block">Purpose Of A Funeral</span>
                  </span>
                </span>             
              </router-link>
            </li>
          </ul>
        </div>
        
        <div class="flex bg-grey-lighter px-8 py-6 -mb-6">
          <ul class="w-1/2 list-reset mr-4">
              <router-link to="/affiliate-providers" class="flex">
                <Affiliate class="h-5" />
                <span class="ml-2">
                  <span class="block">Affiliate Program</span>
                  <span class="text-xs normal-case text-gray">
                    <span class="block">FOR FUNERAL PROFESSIONALS ONLY</span>
                    <span class="block">Funeral Homes, Funeral Directors,  </span>
                    <span class="block">Crematories &amp; Liturgical Planners</span>                  
                  </span>
                </span>             
              </router-link>
          </ul>
          <ul class="w-1/2 list-reset">
            <li>
              <router-link to="/contact-us" class="flex">
                <ContactUs class="h-5" />
                <span class="ml-2">
                  <span class="block">Customer Service &amp; Support</span>
                  <span class="text-xs normal-case text-gray">
                    <span class="block">How Can We Help?</span>
                    <span class="block">Contact Us</span>
                  </span>
                </span>             
              </router-link>            
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Analytics from "../components/svg/icon/Analytics.svg";
import User from "../components/svg/icon/User.svg";
import Profile from "../components/svg/icon/Profile.svg";
import BookletOpen from "../components/svg/icon/BookletOpen.svg";
import Praying from "../components/svg/icon/Praying.svg";
import Heart from "../components/svg/icon/Heart.svg";
import Processing from "../components/svg/icon/Process.svg";
import DollarSign from "../components/svg/icon/DollarSign.svg";
import Star from "../components/svg/icon/Star.svg";
import ContactUs from "../components/svg/icon/ContactUs.svg";
import Affiliate from "../components/svg/icon/Affiliate.svg";
import Dropdown from "../components/svg/icon/Dropdown.svg";

import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "MegaMenu",
  mixins: [clickaway],

  data: function() {
    return {
      isMegaMenuActive: false
    };
  },

  methods: {
    toggleMegaMenu() {
      this.isMegaMenuActive = !this.isMegaMenuActive;
    },
    hideMegaMenu() {
      this.isMegaMenuActive = false;
    }
  },
  components: {
    Analytics,
    User,
    Dropdown,
    Profile,
    BookletOpen,
    Praying,
    Heart,
    Processing,
    DollarSign,
    Star,
    ContactUs,
    Affiliate
  }
};
</script>

<style scoped>
a {
  @apply text-base;
  @apply capitalize;
  @apply text-cream;
  letter-spacing: 3px;
}

a:hover {
  @apply text-white;
  transition: all 0.3s ease;
}

.activated {
  @apply text-white;
}

.downArrow {
  transition: all 0.6s ease;
}
.downArrow.rotate {
  transform: rotate(-180deg);
}

.megamenu-fade-enter-active,
.megamenu-fade-leave-inactive {
  transition: all 0.3s ease-in-out;
}

.megamenu-fade-enter,
.megamenu-fade-leave-to {
  opacity: 0;
  transform: translateY(-12px);
}

.megamenu-panel {
  @apply absolute;
  @apply normal-case;
  @apply font-normal;
  @apply bg-white;
  @apply overflow-hidden;
  @apply z-20;
  @apply mt-4;
  width: 730px;
  left: -585px;
  @apply rounded-lg;
  @apply shadow-lg;
}
/* box-shadow: 0 35px 55px 0 rgba(0,0,0,0.21),
              0 25px 35px 0 rgba(0,0,0,0.18); 
*/

.megamenu-panel a {
  @apply mb-6;
  @apply text-blue;
}

.megamenu-panel a:hover {
  @apply text-melon;
} 
</style>
