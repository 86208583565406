<template>
<div>
  <div class="my-16 hidden md:block">
    <div class="flex justify-between text-center text-sm border-b border-grey-lighter">
      <div class="flex-1 m-2 text-left">BOOKLET COMPONENTS</div>
      <div class="flex-none w-32 pb-2">4 page<br />scripture titles</div>
      <div class="flex-none w-32 pb-2">8 page<br />scripture titles</div>
      <div class="flex-none w-32 pb-2">8 page<br />full scripture</div>
      <div class="flex-none w-32 pb-2">12 page<br />full scripture</div>
    </div> 
    <div class="flex justify-between text-center text-sm border-b border-grey-lighter hover:bg-grey">
      <div class="flex-1 m-2 text-left"># Pages/ # Sheets</div>
      <div class="flex-none w-32 py-2">4/1</div>
      <div class="flex-none w-32 py-2">8/2</div>
      <div class="flex-none w-32 py-2">8/2</div>
      <div class="flex-none w-32 py-2">12/3</div>
    </div> 
    <div v-for="item in items" :key="item.label + '-1'">
      <div class="flex justify-between text-center text-sm border-b border-grey-lighter hover:bg-grey">
        <div class="flex-1 m-2 text-left">{{ item.label }}</div>
        <div class="flex-none w-32 pt-1"><checkMark v-if="item.opt1" class="h-5"></checkMark></div>
        <div class="flex-none w-32 pt-1"><checkMark v-if="item.opt2" class="h-5"></checkMark></div>
        <div class="flex-none w-32 pt-1"><checkMark v-if="item.opt3" class="h-5"></checkMark></div>
        <div class="flex-none w-32 pt-1"><checkMark v-if="item.opt4" class="h-5"></checkMark></div>
      </div>   
    </div>
    <div class="flex justify-between text-center">
      <div class="flex-1"></div>
      <div class="flex-none w-32 pt-6"><router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'FourPageFuneralBooklet' }}" class="btn mx-auto">Select</router-link></div>
      <div class="flex-none w-32 pt-6"><router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'EightPageFuneralBooklet' }}" class="btn mx-auto">Select</router-link></div>
      <div class="flex-none w-32 pt-6"><router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'EightPageFullFuneralBooklet' }}" class="btn mx-auto">Select</router-link></div>
      <div class="flex-none w-32 pt-6"><router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'TwelvePageFuneralBooklet' }}" class="btn mx-auto">Select</router-link></div>
    </div>
  </div>
  
  <div class="my-6 block md:hidden">
    <div class="flex justify-between text-center text-sm border-b border-grey-lighter">
      <div class="flex-1 m-2 text-left">BOOKLET COMPONENTS</div>
      <div class="flex-none w-32 pb-2">4 page<br />scripture titles</div>
    </div> 
    <div class="flex justify-between text-center text-sm border-b border-grey-lighter hover:bg-grey">
      <div class="flex-1 m-2 text-left"># Pages/ # Sheets</div>
      <div class="flex-none w-32 py-2">4/1</div>
    </div> 
    <div v-for="item in items" :key="item.label + '-2'">
      <div class="flex justify-between text-center text-sm border-b border-grey-lighter hover:bg-grey">
        <div class="flex-1 m-2 text-left">{{ item.label }}</div>
        <div class="flex-none w-32 pt-1"><checkMark v-if="item.opt1" class="h-5"></checkMark></div>
      </div>   
    </div>
    <router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'FourPageFuneralBooklet' }}" class="btn w-full mt-6">Select : 4 page with scripture titles</router-link>

    <div class="flex justify-between text-center text-sm border-b border-grey-lighter mt-12">
      <div class="flex-1 m-2 text-left">BOOKLET COMPONENTS</div>
      <div class="flex-none w-32 pb-2">8 page<br />scripture titles</div>
    </div> 
    <div class="flex justify-between text-center text-sm border-b border-grey-lighter hover:bg-grey">
      <div class="flex-1 m-2 text-left"># Pages/ # Sheets</div>
      <div class="flex-none w-32 py-2">8/2</div>
    </div> 
    <div v-for="item in items" :key="item.label + '-3'">
      <div class="flex justify-between text-center text-sm border-b border-grey-lighter hover:bg-grey">
        <div class="flex-1 m-2 text-left">{{ item.label }}</div>
        <div class="flex-none w-32 pt-1"><checkMark v-if="item.opt2" class="h-5"></checkMark></div>
      </div>   
    </div>
    <router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'EightPageFuneralBooklet' }}" class="btn w-full mt-6">Select : 8 page with scripture titles</router-link>

    <div class="flex justify-between text-center text-sm border-b border-grey-lighter mt-12">
      <div class="flex-1 m-2 text-left">BOOKLET COMPONENTS</div>
      <div class="flex-none w-32 pb-2">8 page<br />full scripture</div>
    </div> 
    <div class="flex justify-between text-center text-sm border-b border-grey-lighter hover:bg-grey">
      <div class="flex-1 m-2 text-left"># Pages/ # Sheets</div>
      <div class="flex-none w-32 py-2">8/2</div>
    </div> 
    <div v-for="item in items" :key="item.label + '-4'">
      <div class="flex justify-between text-center text-sm border-b border-grey-lighter hover:bg-grey">
        <div class="flex-1 m-2 text-left">{{ item.label }}</div>
        <div class="flex-none w-32 pt-1"><checkMark v-if="item.opt3" class="h-5"></checkMark></div>
      </div>   
    </div>
    <router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'EightPageFullFuneralBooklet' }}" class="btn w-full mt-6">Select : 8 page with full scripture</router-link>
  
    <div class="flex justify-between text-center text-sm border-b border-grey-lighter mt-12">
      <div class="flex-1 m-2 text-left">BOOKLET COMPONENTS</div>
      <div class="flex-none w-32 pb-2">12 page<br />full scripture</div>
    </div> 
    <div class="flex justify-between text-center text-sm border-b border-grey-lighter hover:bg-grey">
      <div class="flex-1 m-2 text-left"># Pages/ # Sheets</div>
      <div class="flex-none w-32 py-2">12/3</div>
    </div> 
    <div v-for="item in items" :key="item.label + '-5'">
      <div class="flex justify-between text-center text-sm border-b border-grey-lighter hover:bg-grey">
        <div class="flex-1 m-2 text-left">{{ item.label }}</div>
        <div class="flex-none w-32 pt-1"><checkMark v-if="item.opt4" class="h-5"></checkMark></div>
      </div>   
    </div>
    <router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'TwelvePageFuneralBooklet' }}" class="btn w-full mt-6">Select : 12 page with full scripture</router-link>

  </div>
</div>
</template>

<script>
  import CheckMark from '../components/svg/CheckMark.svg'
  import Comparisons from '../assets/json/Comparisons.json'

  export default {
    name: 'ComparisonChart',
    data() {
      return {
        items: Comparisons
      }
    },
    components: {
      CheckMark,
      Comparisons
    }
  }
</script>
