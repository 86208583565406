<template>
  <button type="button" :disabled="disabled" @click="started" ref="button">
    <slot v-if="!processing" name="content">Process</slot>
    <slot v-if="processing" name="processingContent">Please wait...</slot>
  </button>
</template>

<script>
  export default {
    name: 'ProcessButton',
    props: ['enabled'],
    data() {
      return {
        processing: false
      }
    },
    computed: {
      disabled: function() {
        const self = this
        const enabled = self.enabled === undefined ? true : self.enabled


        return !(enabled && !self.processing)
      }
    },
    methods: {
      started() {
        let self = this;
        self.processing = true;

        let timer = new Promise(function (resolve) {
          setTimeout(function () {
            resolve();
          }, self.$config.minimumProcessDurationInMilliseconds)
        });

        self.$emit('click', (fn) => {
          timer.then(() => {
            self.processing = false;
            if (typeof(fn) === 'function') {
              fn();
            }
          })
        });
      }
    }
  }
</script>
