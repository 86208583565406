<template>
  <div v-if="loaded">
    <h1>My Profile</h1>
    
    <div class="p-2">
      <div class="inline-block w-32 text-left">Email Address:</div>
      <div class="inline-block w-48 text-left"><input name="EmailAddress" type="email" v-model="emailAddress" /></div>
    </div>
    <div class="p-2">
      <div class="inline-block w-32 text-left">First Name:</div>
      <div class="inline-block w-48 text-left"><input name="FirstName" v-model="firstName" /></div>
    </div>
    <div class="p-2">
      <div class="inline-block w-32 text-left">Last Name:</div>
      <div class="inline-block w-48 text-left"><input name="LastName" v-model="lastName" /></div>
    </div>
    <div class="mt-8">
      <process-button class="btn" @click="update">
        <span slot="content">Update</span>
        <span slot="processingContent">Updating...</span>
      </process-button>
    </div>
    <p class="error-summary"></p>
  </div>
</template>

<script>
  import * as api from '../client/api.js'
  import ProcessButton from '../components/ProcessButton'

  export default {
    name: "Profile",
    components: { ProcessButton },
    data() {
      return {
        id: null,
        emailAddress: null,
        firstName: null,
        lastName: null,
        loaded: false,
      }
    },
    methods: {
      update(callback) {
        let self = this

        let user = {
          id: self.id,
          emailAddress: self.emailAddress,
          firstName: self.firstName,
          lastName: self.lastName
        }

        api.UsersPut({ args: user })
          .then(response => {
            callback(() => {
              self.$store.commit('SET_CURRENT_USER', user)
            });
          })
          .catch(e => {
            callback(() => {
              self.processErrorMessages(e)
              self.handleApiError(e)
            });
          })
      }
    },
    created() {
      let self = this;

      api.UsersMeGet().then(response => {
        if (response && response.data) {
          let user = response.data;

          self.$store.commit('SET_CURRENT_USER', user);

          self.id = user.id;
          self.emailAddress = user.emailAddress;
          self.firstName = user.firstName;
          self.lastName = user.lastName;

          self.loaded = true;
        }
      }).catch(e => this.handleApiError(e));
    }
  }
</script>
