<template>
    <div>
        <span class="p-5" title="facebook" @click="open('https://www.facebook.com/MyWayBookllets/')"><Facebook class="social"></Facebook></span>
        <span class="p-5" title="twitter" @click="open('https://twitter.com/')"><Twitter class="social"></Twitter></span>
        <span class="p-5" title="instagram" @click="open('https://www.instagram.com/mywaybooklets/?hl=en')"><Instagram class="social"></Instagram></span>
        <span class="p-5" title="linkedin" @click="open('https://www.linkedin.com/company/my-way-booklets/')"><LinkedIn class="social"></LinkedIn></span>
    </div>
</template>

<script>
  import Facebook from '../components/svg/Facebook.svg'
  import Twitter from '../components/svg/Twitter.svg'
  import Instagram from '../components/svg/Instagram.svg'
  import LinkedIn from '../components/svg/LinkedIn.svg'
  
  export default {
    name: 'SocialMedia',
    components: { Facebook, Twitter, Instagram, LinkedIn },
    methods:{
        open: function (url) { window.open(url, "_blank"); }
    }
  };
</script>

<style scoped>
path {
    fill: #a0aab3;
}
.social {
    @apply h-6;
    @apply w-6;
}
.social :hover{
    fill: #ff9651;
}
</style>

