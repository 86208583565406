<template>
    <div class="relative text-white hover:text-gold">
        <router-link
            to="#"
            class="flex items-center"
            v-on-clickaway="hideDropdown"
            v-on:click.native="toggleDropdown"
            v-bind:class="{ activated: isMenuActive }"
            @keydown.space.exact.prevent="toggleDropdown"
            @keydown.escape.exact="hideDropdown"
        >
            <User class="h-6 mr-2" />
            {{ userDisplayName }}
            <Dropdown :class="{rotate:isMenuActive}" class="downArrow h-5 ml-1 mr-4"/>
        </router-link>
        <transition name="dropdown-fade">
            <ul v-if="isMenuActive" class="dropdown-list">
              <li>
                <router-link to="" class="flex items-center px-6 py-4 border-b border-grey-light bg-grey-lighter">
                  <User class="h-5 mr-2 -mt-4" />
                  <span>
                      <span class="block">{{ userDisplayName }}</span>
                      <span class="block text-xs italic lowercase font-thin mt-1">{{ currentUser.emailAddress }}</span>
                  </span>
                </router-link>
              </li>
              <li>
                <router-link to="/profile" class="flex px-6 py-4">
                  <Profile class="h-5" />
                  <span class="block ml-2">My Account</span>
                </router-link>
              </li>
              <li>
                <router-link to="/my-booklets" class="flex px-6 py-4">
                  <BookletOpen class="h-5" />
                  <span class="block ml-2">My Booklets</span>
                </router-link>
              </li>
              <li>
                <router-link to="/contact-us" class="flex px-6 py-4">
                  <Question class="h-5" />
                  <span class="block ml-2">Support</span>
                </router-link>
              </li>
              <li>
                <router-link to="/contact-us" class="flex px-6 py-4">
                  <Rating class="h-5" />
                  <span class="block ml-2">Share a Review</span>
                </router-link>
              </li>              
              <li>
                <router-link @click.native="signOut" to="/" class="flex px-6 py-4 border-t border-grey-light bg-grey-lighter">
                  <SignOut class="h-5" />
                  <span class="block ml-2">Sign Out</span>
                </router-link>
              </li>
            </ul>
        </transition>
    </div>
</template>

<script>
import Forward from "../components/svg/icon/Forward.svg";
import User from "../components/svg/icon/User.svg";
import SignOut from "../components/svg/icon/SignOut.svg";
import Profile from "../components/svg/icon/Profile.svg";
import Rating from "../components/svg/icon/Rating.svg";
import BookletOpen from "../components/svg/icon/BookletOpen.svg";
import Question from "../components/svg/icon/Question.svg";
import Dropdown from "../components/svg/icon/Dropdown.svg";

import { mapGetters } from "vuex";
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "UserMenu",
  mixins: [clickaway],

  computed: mapGetters(['signedIn', 'userDisplayName', 'messages', 'currentUser']),
  
  data: function() {
    return {
      isMenuActive: false,
    };
  },

  methods: {
    toggleDropdown() {
      this.isMenuActive = !this.isMenuActive;
    },
    hideDropdown() {
      this.isMenuActive = false;
    },
    signOut() {
      this.isMenuActive = false
      this.$store.dispatch("signOut").then(response => {
        this.$router.push("/");
      });
    }
  },
  components: { Forward, User, Dropdown, SignOut, Profile, Rating, BookletOpen, Question }
};
</script>

<style scoped>
a {
  @apply text-base;
  @apply uppercase;
  @apply text-cream;
  letter-spacing: 3px;
}

a:hover {
  @apply text-white;
  transition: all 0.3s ease;
}

.activated {
  @apply text-white;
}

.downArrow {
  transition: all 0.6s ease;
}
.downArrow.rotate {
  transform: rotate(-180deg);
}

.dropdown-fade-enter-active,
.dropdown-fade-leave-inactive {
  transition: all 0.6s ease-in-out;
}

.dropdown-fade-enter,
.dropdown-fade-leave-to {
  opacity: 0;
  transform: translateY(-12px);
}

.dropdown-list {
  @apply absolute;
  @apply normal-case;
  @apply font-normal;
  @apply bg-white;
  @apply overflow-hidden;
  @apply z-20;
  @apply mt-4;
  @apply w-64;
  left: -68px;
  @apply rounded-lg;
  @apply shadow-lg;
  @apply list-reset;
}

.dropdown-list a {
  @apply normal-case;
  @apply text-blue;
}

.dropdown-list a:hover {
  @apply text-melon;
}
</style>
