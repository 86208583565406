<template>
  <div>
    <!-- <h2 class="mb-12">Congratulations, You Did It…<br /><span class="ml-16">Ready to review and approve?</span></h2>    -->
    <h1 class="mb-12">Congratulations, You Did It…<br />Ready to review and approve?</h1>   
    <div class="text-md md:text-lg mx-auto max-w-xl text-left">
      <p>
        Before you give your final stamp of Approval, we request that you thoroughly review your booklet creation in its entirety.
      </p>
      <p>
        The Funeral Booklet Creation Wizard will take you through page by page, take this opportunity to check spelling accuracy, date accuracy, 
        and the chance to add or eliminate photos, poems, prayers, quotes, etc. Feel free to make as many changes as necessary, simply click on 
        highlighted text or image to be edited and proceed to the end.
      </p>
      <p>
        Upon completion of your final review and edits, the booklet is ready for APPROVAL.
      </p>
      <p>
        Once you APPROVE your booklet, it is eligible for conversion to a print-ready PDF file and your masterpiece is qualified to print 
        with MY WAY BOOKLETS', print at home or any commercial printer of your preference. 
      </p>
      <div class="text-center">
        <hr>
        <router-link :to="{ name: 'booklet-approval', params: { id: $route.params.id }}" class="btn mx-auto my-12">Continue</router-link>
      </div>
    </div>
   </div>
</template>

<script>
  import * as api from '../client/api.js'
  import { BookletStatus } from '../models/BookletStatus'

  export default {
    name: 'BookletPreApproval',
    beforeRouteEnter (to, from, next) {
      let bookletId = to.params.id;

      api.BookletsByIdGet({ id: bookletId }).then(response => {
        if (response && response.data) {
          let status = response.data.status;

          if (status >= BookletStatus.PreApproved) {
            next({ name: 'booklet-approval', params: { id: bookletId }});
          }
          else {
            api.BookletsByIdStatusPut({ id: bookletId, args: { status: BookletStatus.PreApproved }});
            next();
          }
        }
      });
    }
  }
</script>

<style scoped>
hr {
  @apply mt-12;
  @apply mb-12;
  @apply max-w-2xl;
  @apply border-0;
  @apply h-px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));  
}
</style>
