<template>
  <div class="mx-auto max-w-full">
    <h1>Business Affiliate Registration</h1>
    <div class="text-md md:text-lg mx-auto max-w-xl text-left">
      <h3 class="pb-3 font-semibold">We're excited to share the details!</h3>
      <p>
          <i>My Way Booklets'</i> is honored to partner with funeral homes, directors, crematories, parish pastors, and liturgical planners 
         across the United States to professionally represent their business with an exclusive service setting them apart from their 
         competition while increasing their revenue with no additional cost to their bottom line!
      </p>
      <div>
       Tell <i>My Way Booklets'</i> more about who you are and who you represent: 
      </div>
      <div class="py-6 md:pl-6">
        <input type="radio" id="funeralhome" value="1" v-model="typeID"><label for="funeralhome"><span class="pl-4">a Funeral Home</span></label>    
        <span class="pl-16"><input type="radio" id="churchparish" value="2" v-model="typeID"><label for="churchparish"><span class="pl-4">a Church Parish</span></label></span>
      </div>         
      <div class="mt-6 mx-auto rounded-lg text-left border border-grey-light shadow-lg">
        <h5 class="text-md md:text-lg p-2 bg-grey-lighter border-b border-grey-light">Business Account Information</h5> 
        <div class="md:flex p-5 bg-grey-lightest">

          <div class="flex-1">
            <div class="text-sm pb-1">Funeral Home / Church<span class="required">*</span></div>
            <div><input name="Name" type="text" v-model="name" class="w-full"/></div>
            <div class="md:flex">
              <div class="flex-1 md:pr-1">
                <div class="text-sm pt-2 pb-1">First Name<span class="required">*</span></div>
                <div><input name="FirstName" type="text" v-model="firstName" class="w-full"/></div>
              </div>
              <div class="flex-1 md:pl-1">
                <div class="text-sm pt-2 pb-1">Last Name<span class="required">*</span></div>
                <div><input name="LastName" type="text" v-model="lastName" class="w-full"/></div>
              </div>      
            </div>     
           </div>
           <div class="flex-1 md:ml-5">
            <div class="text-sm pt-2 pb-1 md:pt-0">Email Address<span class="required">*</span></div>
            <div><input name="EmailAddress" type="email" v-model="emailAddress" class="w-full"/></div>                     
            <div class="text-sm pt-2 pb-1">Telephone<span class="required">*</span></div>
            <div><input name="Telephone" type="text" v-model="telephone" class="w-full"/></div>             
           </div>
           <div class="flex-1 md:ml-5">
            <div class="text-sm pt-2 pb-1 md:pt-0">Street Address</div>
            <div><input name="Address1" type="text" v-model="address1" class="w-full"/></div>              
            <div class="md:flex">
              <div class="flex-1 md:pr-1">
                <div class="text-sm pt-2 pb-1">City</div>
                <div><input name="City" type="text" v-model="city" class="w-full"/></div>
              </div>
              <div class="flex-1 md:pl-1 md:pr-1">
                <div class="text-sm pt-2 pb-1">State</div>
                <div><input name="State" type="text" v-model="state" class="w-full"/></div>
              </div>      
              <div class="flex-1 md:pl-1">
                <div class="text-sm pt-2 pb-1">Zip Code</div>
                <div><input name="ZipCode" type="text" v-model="zipCode" class="w-full"/></div>
              </div>  
            </div>                         
           </div>
         </div>
      </div>
      <div class="pt-12 text-right">
        <button :disabled="isDisabled" class="btn mx-auto" @click="submit">Submit</button>
      </div> 
      <div class="pt-4 md:pl-4 ">
        <label for="sendSixPanel"><input id="sendSixPanel" type="checkbox" v-model="sendSixPanel"/><span class="pl-4">Please send me a <i>Free</i> six-panel acrylic Booklet Option table display.</span></label>
      </div>
        </div>  
    <p class="error-summary"></p>     
  </div>
</template>

<script>
  import * as api from '../client/api.js'
  import { mapGetters } from 'vuex'

  export default {
    name: 'AffiliateRegister',
    data: function () {
      return {
        name: null,
        typeID: 1,
        firstName: null,
        lastName: null,
        emailAddress: null,
        telephone: null,            
        address1: null, 
        address2: null, 
        city: null, 
        state: null, 
        zipCode: null,
        sendSixPanel: true
      }
    },
    methods: {
     submit() {
        const self = this

        let contact = { firstName: self.firstName, lastName: self.lastName, emailAddress: self.emailAddress, telephone: self.telephone }
        let location = { address1: self.address1, address2: self.address2, city: self.city, state: self.state, zipCode: self.zipCode }

        api.AffiliatesPost({ args: { name: self.name, contact: JSON.stringify(contact), location: JSON.stringify(location), typeid: self.typeID } }).then(response => {
            self.$router.push({ name: 'affiliateconfirmation', params: { data: { name: self.name, typeid: self.typeID, contact: contact, location: location, identifier: response.data  } } })
         })
         .catch(e => {
            self.processErrorMessages(e);
            self.handleApiError(e);
        })
      }
    },
    computed: {
      ...mapGetters(['signedIn', 'currentUser']),
  
      isDisabled: function () {
        const self = this
        
        const disabled = (!self.name || !self.firstName || !self.lastName || !self.emailAddress|| !self.telephone)

        return disabled
      }
    },
    watch: {
      signedIn: {
        immediate: true,
        handler: function(synch) {
          if (synch) {
            const self = this

            self.firstName = self.currentUser.firstName
            self.lastName = self.currentUser.lastName
            self.emailAddress = self.currentUser.emailAddress
          }
        }
      }
    },
  }
</script>

<style scoped>
.required {
  @apply text-red;
  @apply font-semibold;
  @apply pl-1;
}
</style>