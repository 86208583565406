<template>
  <div>
    <label v-html="label"></label>
    <span>
      <show-modal-button ref="imageModalButton" modal-class="max-w-md" @close="closeModal" @showModal="setShowModal" 
                         :class="[imageLoading ? 'disabled' : (image ? 'text-melon text-left' : 'btn text-center'), 'ml-2']"
      >
        <div slot="button-content" class="image-button truncate">
          {{ image ? (image.name || image.Name) : (imageLoading ? (loaded ? value : 'Loading...') : 'Select Image') }}
        </div>
        <div slot="modal-content">
          <h1 v-html="label" class="font-normal text-xl text-left mb-2 sm:mt-0 mt-2"></h1>
          <div v-if="instruction" v-html="instruction" class="text-sm text-left px-2 mb-6"></div>

          <div v-if="allowUpload" class="border-b pb-4 mb-4" style="white-space: nowrap">
            <div class="md:flex">
                <div class="flex-initial">
                  <file-upload-button class="btn mr-4" url="/images" :cloudinary=cloudinary @completed="imageUploaded">
                    <span slot="content">Upload Image</span>
                  </file-upload-button>                 
                </div>
                <div v-if="selectedImage" class="flex-initial">
                  <label class="selected-image" >{{ selectedImage.name || selectedImage.Name }}</label>                  
                </div>
            </div>


          </div>

          <div v-if="cloudinary && uploaded" class="scrolling">
            <div class="md:flex justify-between">
              <div class="md:w-2/5">
                <cloudinary-option :options="imageOptions" :showModal="showModal" @change="onOptionsChange($event)" class="h-full"></cloudinary-option>
              </div>
              <div class="text-center md:w-3/5">
                <div v-show="!displayPreview" class="imgFrame md:pl-6">
                  <img class="m-auto" src="../../assets/images/AjaxLoader.gif" alt="" />
                </div>
                <div v-show="displayPreview" class="imgFrame md:pl-6">
                   <img @load="onImageLoad" :src="displayImage" alt="" class="m-auto originalImg">
                </div>
              </div>
            </div>
          </div> 

          <div v-if="images" class="image-container scrollable-container overflow-y-auto p-2 clearfix">
            <span v-for="image in images" v-bind:key="image.id">
              <span @click="selectImage(image)"
                    :class="['float-left', 'h-32', 'mb-4', 'mr-4', 'cursor-pointer', 'image', { selected: selectedImage && selectedImage.id === image.id }]"
              >
                <div class="h-24 relative">
                  <img :src="`/files/${image.id}`" class="absolute pin max-w-full max-h-full w-auto h-auto m-auto">
                </div>
                <div class="p-1 text-sm text-center truncate" :title="image.name || image.Name">
                  {{ image.name || image.Name }}
                </div>
              </span>
            </span>
          </div>
          <div v-if="allowSelect && images.length === 0">
            {{ loaded ? 'No images to select' : 'Loading...' }}
          </div>

          <div class="text-right mt-2">
            <button type="button" class="btn-light ml-4" data-modal-action="cancel">Cancel</button>
            <button type="button" class="btn ml-4" data-modal-action="ok">Save</button>
          </div>
        </div>
      </show-modal-button>
    </span>
  </div>
</template>

<script>
  import Vue from 'vue'
  import * as api from '../../client/api.js'
  import ShowModalButton from '../ShowModalButton'
  import FileUploadButton from '../FileUploadButton'
  import ToggleSwitch from '../ToggleSwitch.vue'
  import CloudinaryOption from '../CloudinaryOption.vue'

  export default {
    name: 'ImageInput',
    props: ['label', 'name', 'value', 'allowUpload', 'categories', 'instruction', 'cloudinary'],
    components: { ShowModalButton, FileUploadButton, ToggleSwitch, CloudinaryOption },
    data() {
      return {
        image: null,
        selectedImage: null,
        images: [],
        loaded: false,
        uploaded: false,
        displayPreview: false,
        imageOptions: '',
        showModal: false
      }
    },
    created() {
      let self = this;

      if (self.allowSelect) {
        self.getImages(() => self.init());
      }
      else {
        this.loaded = true;
        self.init();
      }
      
      if (self.cloudinary) self.initCloudinary()
    },
    computed: {
      allowSelect() {
        return this.categories && this.categories.length > 0;
      },
      imageLoading() {
        return this.value && !this.image;
      },
      displayImage() {
        let fileName = this.selectedImage.Id ? this.selectedImage.Id  : this.selectedImage.id ? this.selectedImage.id : null
        return this.$config.cloudinary.url + this.imageOptions + this.$config.cloudinary.path + fileName
      }
    },
    methods: {
      init() {
        if (this.value) {
          let image = this.images.find(x => x.id === this.value.id || x.id === this.value.Id);

          if (!image) {
            this.uploaded = true;
            image = this.value;
          }
          this.image = this.selectedImage = image;
        }
      },
      initCloudinary() {
        if (this.value) {
          this.selectedImage = this.value
          this.imageOptions = this.value.Options
          this.displayPreview = true
        }
      },
      imageUploaded(image) {
        this.uploaded = true;
        this.selectedImage = image;
        this.setImageOptions
      },
      selectImage(image) {
        this.selectedImage = this.selectedImage && this.selectedImage.id === image.id ? null : image;
      },
      getImages(callback) {
        let self = this;

        api.ImagesGet({ categoryIds: this.categories }).then(response => {
          if (response && response.data) {
            self.images = response.data;
            self.loaded = true;

            if (callback) {
              callback();
            }
          }
        }).catch(e => this.handleApiError(e));
      },
      setImageOptions() {
        Vue.set(this.selectedImage, 'cloudinary', this.cloudinary) 
        Vue.set(this.selectedImage, 'options', this.imageOptions)
      },
      onOptionsChange(opt) {
        this.imageOptions = opt
        this.displayPreview = false
      },
      onImageLoad() {
        this.displayPreview = true
      },
      closeModal(result) {
        if (result === 'ok') {
          this.setImageOptions()
          this.image = this.selectedImage;
          this.$emit('input', this.selectedImage);
        }
        else if (result === 'cancel') {
          this.init();
        }
      },
      setShowModal(result) {
        this.showModal = result
      }
    }
  }
</script>

<style scoped>
  .imgFrame {
    @apply flex;
    @apply justify-center;
    @apply items-center;
    width: 360px;
    height: 360px;
  }
  .originalImg {
    width: 350px;
    height: 350px;
    max-height: 350px;
    object-fit: contain;
  }
  @media (max-width: 576px) {
    .imgFrame {
    width: 300px;
    height: 300px;
    }
    .originalImg {
        @apply mt-3;
        width: 290px;
        height: 290px;
        max-height: 290px;
    }
    .scrolling {
      overflow: auto; 
      max-height: 260px;
    }
  }
  .disabled {
    color: #22292f;
    font-weight: 300;
    pointer-events: none;
  }

  .image-container {
    max-height: 50vh;
  }

  .image {
    outline: 1px solid #dae1e7;
    width: 22%;
  }

  .selected {
    outline: 2px solid #de751f;
  }

  .image-button {
    max-width: 15rem;
  }
</style>