<template>
  <div>
    <component v-for="(field, index) in schema"
               :key="getInputKey(field, index)"
               :is="getInputName(field)"
               :value="formData[field.name]"
               @input="updateForm(field.name, $event)"
               v-bind="field"
               :cremation="cremation">
    </component>
  </div>
</template>

<script>
  import NumberInput from './inputs/NumberInput'
  import SelectInput from './inputs/SelectInput'
  import TextInput from './inputs/TextInput'
  import ImageInput from './inputs/ImageInput'
  import DateInput from './inputs/DateInput'
  import ListInput from './inputs/ListInput'
  import HymnInput from './inputs/HymnInput'
  import ScriptureInput from './inputs/ScriptureInput'

  export default {
    name: 'FormGenerator',
    components: { NumberInput, SelectInput, TextInput, ImageInput, DateInput, ListInput, HymnInput, ScriptureInput },
    props: ['schema', 'value', 'cremation'],
    data() {
      return {
        formData: this.value || {}
      };
    },
    methods: {
      getInputName(field) {
        let type = field.type || field.fieldType;
        let inputName = type[0].toUpperCase() + type.slice(1) + 'Input';

        return inputName;
      },
      getInputKey(field, index) {
        return `${this.getInputName(field)}-${field.name}-${index}`;
      },
      updateForm(fieldName, value) {
        let updateInfo = { fieldName, value, oldValue: this.formData[fieldName] };
        this.$set(this.formData, fieldName, value);
        this.$emit('input', this.formData, updateInfo);
      }
    }
  };
</script>
