<template>
  <div>
    <div class="mx-auto max-w-xl">
      <div class="md:px-8 text-left">
        <h1>Terms of Service</h1>
        <p>My Way Booklets, LLC ("My Way") makes this Web site (the "Site"), including all information, documents, communications, files, text, graphics, software, and products available through the Site (collectively, the "Materials") and all services operated by My Way and third parties through the Site (collectively, the "Services"), available for your use subject to the terms and conditions set forth in this document and any changes to this document that My Way may publish from time to time (collectively, the "Terms of Use").</p>
        <h2>In General</h2>
        <p>These terms and conditions govern your use of this website. We regard you accept these terms and conditions in full when you are using this website. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.</p>
        <h2>Terms of Usage</h2>
        <p>Unless otherwise stated, My Way and/or its licensors own the intellectual property rights in the website and material on the website. You may view, download for caching purposes only, and print pages or other content from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.</p>
        <p>You must not misuse this Website. Without limiting the breadth of the foregoing, you will not commit, facilitate or encourage a criminal offence; transmit or distribute a virus, Trojan horse, worm, logic bomb, or post any other material which is malicious, technologically harmful, in breach of confidence or in any way offensive or obscene; hack into any aspect of the Service; corrupt data; cause annoyance to other users; infringe upon the rights of any other person's proprietary rights; send any unsolicited advertising or promotional material, commonly referred to as "spam"; or attempt to affect the performance or functionality of any computer facilities of My Way or its partners involved in the operation and maintenance of this website. You shall not directly or indirectly take any action to unduly stress the website, including by way of example and not limitation, incurring session lengths or conducting search queries that we reasonably determine to be abusive, launching or facilitating any denial of service attack on the website. We reserve the right to monitor your use of the website to provide guidance and assistance in the use of the website, and to monitor your conformance with these Terms of Service. You acknowledge and hereby consent to such monitoring.</p>
        <p>You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity. You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this website without My Way’s express written consent.</p>
        <p>You must not use this website to transmit or send unsolicited commercial communications.</p>
        <p>You must not use this website for any purposes related to marketing without My Way’s express written consent.</p>
        <h2>User Content</h2>
        <p>In these terms and conditions, “your user content” means material (including without limitation text, images, audio material, video material and audio-visual material) that you submit to this website, for whatever purpose.</p>
        <p>You grant to My Way a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate and distribute your user content in any existing or future media. You also grant to My Way the right to sub-license these rights, and the right to bring an action for infringement of these rights.</p>
        <p>Your user content must not be illegal or unlawful, must not infringe any third party's legal rights, and must not be capable of giving rise to legal action whether against you or My Way or a third party (in each case under any applicable law).</p>
        <p>You must not submit any user content to the website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.</p>
        <h2>Limitation of Liability and Warranty</h2>
        <p>Except as otherwise provided in the standard terms of sale that govern the same of each product on this site. The products offered for sale on it and the transactions conducted through it are provided by My Way. My Way makes no presentations or warranties of any kind, express or implied, as to the operation of the site or the information, content, materials, or products included on this site except as provided here to the full extent permissible by applicable law, My Way disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose, non-infringement, title, quiet enjoyment, data accuracy, and system integration. This site may include inaccuracies, mistakes or typographical errors. My Way does not warrant that the content will be uninterrupted or error free. To the maximum extent permitted by law, My Way will not be liable for any damages of any kind arising from the use of this site, including, but not limited to, indirect incidental, punitive, exemplary, special, or consequential damages. To the maximum extent permitted by applicable law, My Way’s total liability to you for any damages (regardless of the foundation for the action) shall not exceed in the aggregate the amount of fees actually paid by you to My Way during the month immediately preceding the act allegedly giving rise to My Way’s liability.</p>
        <h2>Order Acceptance</h2>
        <p>Please note that there may be certain orders that we are unable to accept and must cancel. My Way reserves the right, at sole discretion, to refuse or cancel any order for any reason. Some situations that may result in your order being canceled include limitations on quantities available for purchase, inaccuracies or errors in product or pricing information, or problems identified by our credit and fraud avoidance department. We may also require additional verifications or information before accepting any order. We will contact you if all or any portion of your order is canceled or if additional information is required to accept your order.</p>
        <p>Both parties agree that, following order dispatch, transportation is the sole responsibility of the third-party logistics company. During this stage, full ownership of the product(s) belongs to the buyer; all associated liability and risks during transportation shall be borne by the buyer.</p>
        <h2>Payment Terms</h2>
        <p>We may provide you with access to third party payment gateways such as PayPal. These services are not under our control. You agree to read the terms and conditions governing your selected payment gateway prior to facilitating any transactions through them.</p>
        <h2>Privacy</h2>
        <p>The terms on which we collect, use and disclose your personal information are set out in our Privacy Policy.</p>
        <h2>Sale Terms</h2>
        <p>At the time of purchase, you acknowledge and agree that any purchases of products made through the Site are covered by our Delivery and Refunds terms and conditions.</p>
        <h2>Termination</h2>
        <p>You agree that we may, at any time and at our sole discretion, with or without cause or any notice to you, terminate your access to the Site.</p>
        <p>We shall not be liable to you or any third party for any claims or damages arising out of any termination or suspension or any other actions taken by us in connection therewith.</p>
        <p>If applicable law requires us to provide notice of termination or cancellation, we may give prior or subsequent notice by posting it on the Site or by sending a communication to any address (email or otherwise) that we have for you in our records.</p>
        <h2>Force Majeure</h2>
        <p>We shall be under no liability to you in respect of anything that, if not for this provision, would or might constitute a breach of these Terms, where this arises out of circumstances beyond our control, including but not limited to:</p>
        <p>
          Acts of god;
          <br />Natural disasters;
          <br />Sabotage;
          <br />Accident;
          <br />Riot;
          <br />Shortage of supplies, equipment, and materials;
          <br />Strikes and lockouts;
          <br />Civil unrest;
          <br />Computer hacking; or
          <br />Malicious damage.
        </p>
        <h2>Trademark Notice</h2>
        <p>All trademarks, logos, and service marks appearing in this website are the property of the company or a related company registered trademarks or trademarks protected by law. Violators will be prosecuted to the full extent of the law. Without the prior written approval of My Way and related owner of the trademark, any companies and individuals shall not use, copy, permanent download, modify, disseminate, copying or bundled sales with other products, or registered its domain name or wireless website name, or domain name, or wireless website name the main part in any way or reason to any part of the trademark.</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TermsOfService",
};
</script>
