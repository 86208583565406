<template>
  <div class="hero mx-auto max-w-full" :style="{ backgroundImage: 'url(' + require('@/assets/images/AffiliateProviders.jpg') + ')' }">

      <h1>Funeral Professionals</h1>
      <div class="text-md md:text-lg mx-auto max-w-xl text-left">
        <h3>Set yourself apart from your competition!</h3>
        <h3>Offer a one of a kind website - Increase Revenue!</h3>
        <h3>With No Bottom-line Cost!</h3>
        <p class="mt-12">
          For decades, families of the dearly departed firmly depend on the proficiency of their funeral professionals for:
        </p>
        <ul>
          <li><span><CheckMark class="h-4 mr-6 "></CheckMark>their empathic nurturing nature;</span></li>
          <li><span><CheckMark class="h-4 mr-6" ></CheckMark>their expertise to assist with the disposition of their loved one;</span></li>
          <li><span><CheckMark class="h-4 mr-6"></CheckMark>their knowledge of the affordable service choices; and,</span></li>
          <li><span><CheckMark class="h-4 mr-6"></CheckMark>their knowledge of the current trends and latest market technology.</span></li>
        </ul>
        <p>
          Do you have the essential innovative tools: to serve your families; to sustain your business; to maintain your status in the community; and finally, to support the latest technology trends necessitated to be successful in today’s service profession?
        </p>
        <h3 class="pb-3 font-semibold">Partner with Us!</h3>      
        <p>
          My Way Booklets’ is honored to partner with funeral professional planners across the United States to competently represent their businesses with an innovative one-of-a-kind service separating them from their competition!
        </p>
        <p>Affirmatively, every life has a story… My Way Booklets confidently guarantees this acclamation. This story is expressed with loving admiration for a final time and shared on the pages of a Catholic Funeral Mass Booklet uniquely created in celebration and honor of this life.
           Your families are afforded the ability to simplistically create a Catholic Funeral Mass Booklet, articulating the life story of their loved one expressed through selected scripture readings, hymns, and psalms according to the Order of Christian Funerals together with the capability to
           upload personally formatted photos, poems, prayers and quotes that profoundly pays tribute to the life of their loved one in union with all family and friends.
        </p>
        <h3 class="pb-3 font-semibold">Affiliate Program</h3>      
        <p>
          Our <i>Affiliate Program</i>, especially created and only extended to <i>funeral professionals</i>, offers valued incentives <i>unlike any other currently available on the market!</i>
        </p> 
        <p>
          Take advantage of our program and service, an alternative technique to create Catholic Funeral Mass Booklets with matchless features rapidly becoming a leader in the service profession industry!
          Be the forerunner… Lead the way… Succeed with confidence…
        </p>
        <div class="pt-3 pb-8 text-center">
          <hr>
          <router-link to="/affiliate-register" class="btn mx-auto">Register here!</router-link>
        </div> 
    </div>
    
     
  </div>
</template>

<script>
import CheckMark from '../components/svg/CheckMark.svg'

export default {
 name: 'AffiliateProviders',
 components: { CheckMark }
}
</script>

<style scoped>
hr {
  @apply mb-12;
  @apply max-w-2xl;
  @apply border-0;
  @apply h-px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));  
}
ul {
  @apply list-reset;
  @apply mb-12;
}

li {
  @apply ml-4;  
  @apply mb-3;
}

li span {
  @apply flex;  
  @apply items-center;
}

.hero {
    @apply bg-cover;
    @apply bg-center;
    @apply bg-no-repeat;
    @apply relative;
}

</style>
