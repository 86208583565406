<template>
  <div>
    <div class="mx-auto max-w-xl">
      <div class="md:px-8 text-left">
        <h1>Fallen Heros - Children</h1>
        <p>MY WAY BOOKLETS’ deeply empathizes with the emotional impact of planning a loving meaningful funeral in honor of your beloved child. We extend our sincere condolences for the loss of your precious child and recognize this extremely difficult undertaking.</p>
        <p>Our mission is to assist with the creation of a loving meaningful funeral mass booklet that respectfully remembers, honors, and, spiritually keeps the life of your precious child in your heart forever.</p>
        <p>In recognition of all children under the age of five (5), My Way Booklets’ offers a discount of 25% from the retail price.</p>
        <p>Please <router-link to="/contact-us">contact us</router-link> directly to arrange this service </p>
        <div class="mx-auto text-center my-12 image-children"></div>

        <h1 class="pt-12">COVID19 - LOSS OF A LOVED ONE</h1>
        <p>Losing a loved one is challenging enough; but, losing a loved one during this extraordinary and unprecedented time with funeral restrictions preventing families from planning an immediate meaningful funeral mass have added overwhelming emotions to an already difficult circumstance.</p>
        <p>If pandemic restrictions have caused a graveside committal service or a brief service prior to cremation and you are planning a Catholic Memorial Mass in Celebration of the Life of Your Loved One, MY WAY BOOKLETS' would be honored to assist with the creation of a mass booklet capturing the spirit of your loved one’s life as it physically bids farewell and supports family &amp; friends as they begin their healing journey.</p>
        <p>Please accept our sincere condolences and recognition of your loss with our offer of 25% off the retail price.</p>
        <p>Please <router-link to="/contact-us">contact us</router-link> directly to arrange this discount.</p>

        <h1 class="pt-12">FALLEN HEROES - VETERANS</h1>
        <p>MY WAY BOOKLETS respectfully appreciate and honors ALL members of the Military, First Responders, and, Healthcare Providers, who have unselfishly sacrificed their lives serving their country and fellow citizens maintain freedom and protection from unprecedented health issues.</p>
        <p>Our mission is to assist with the creation of a loving meaningful funeral mass booklet that respectfully remembers, honors, and, spiritually keeps the life of your loved one in the hearts of all family and friends forever.</p>
        <p>As acknowledgement of your loss, we offer a discount of would like to express our 25% less the retail price.</p>
        <p>Please <router-link to="/contact-us">contact us</router-link> directly to arrange this service.</p>
        <div class="mx-auto text-center mt-12 image-veteran"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FallenHeros"
};
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .image-children {
    background-image: url("../assets/images/Children.jpg");
    width: 637px;
    height: 484px;
    box-shadow: 0 0 25px 25px white inset;
    opacity: 0.6;
  }
  .image-veteran {
    background-image: url("../assets/images/Veterans.jpg");
    width: 636px;
    height: 425px;
    box-shadow: 0 0 20px 20px white inset;
    opacity: 0.8;
  }
}
</style>

