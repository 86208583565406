<template>
  <div>
    <h1>Booklet Options</h1>
    <div class="text-md md:text-lg mx-auto max-w-xl text-left"> 
      <p class="text-md md:text-lg mx-auto max-w-xl mb-8">
        MY WAY BOOKLETS' offers four (4) booklet style options: a 4 Page, an 8 Page, an 8 Page Full and a 12 Page. In addition to the template choices, 
        a variety of color themes and various paper combinations are also available for your creative design pleasure. We are confident that you will 
        appreciate the available options to create a unique keepsake reflective of "the dash" of your loved one. 
      </p>
      <hr>
      <h2 class="text-2xl md:text-3xl text-center">Booklet Samples</h2>      
      <div class="md:flex mx-auto mt-6 md:mt-12">
        <div class="md:flex-1 md:mr-6">
          <div v-bind:class="toggleview" class="rounded-lg shadow-lg">
            <img src="@/assets/images/FourPage.jpg"/>
            <div class="mask text-center p-8 md:p-16">
              <h2 class="text-lg md:text-xl">4 page - scripture titles</h2>
              <p class="text-sm md:text-md">This booklet is designed with only the scripture titles listed. <i>The front cover is available for photos. The back cover is available for photos, poems, prayers or quotes. We recommend using a premium paper stock for a superior finish.</i><br /><br /><i>Pricing starts at $99</i><br /><br /><span class="text-sm italic font-medium text-blue hover:text-melon cursor-pointer" @click="toggleModal('FourPageFuneralBooklet')">View sample</span></p>
              <router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'FourPageFuneralBooklet' }}" class="btn mx-auto sm:w-auto">Select</router-link>
            </div>
          </div>
        </div>
        <div class="md:flex-1 md:ml-6 mt-12 md:mt-0">
         <div v-bind:class="toggleview" class="rounded-lg shadow-lg">
            <img src="@/assets/images/EightPage.jpg"/>
            <div class="mask text-center p-8 md:p-16">
              <h2 class="text-lg md:text-xl">8 page - scripture titles</h2>
              <p class="text-sm md:text-md">This booklet is one of our best sellers. <i>Listing scripture titles only which provides extra space for photos, poems, prayers, quotes and/or an expression of gratitude. It is saddle stitched (stapled through the fold line).</i><br /><br /><i>Pricing starts at $99</i><br /><br /><span class="text-sm italic font-medium text-blue hover:text-melon cursor-pointer" @click="toggleModal('EightPageFuneralBooklet')">View sample</span></p>
              <router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'EightPageFuneralBooklet' }}" class="btn mx-auto sm:w-auto">Select</router-link>
            </div>
          </div>
        </div>
      </div>
    
      <div class="md:flex mx-auto mt-12">
        <div class="md:flex-1 md:mr-6">
          <div v-bind:class="toggleview" class="rounded-lg shadow-lg">
            <img src="@/assets/images/EightPageFull.jpg"/>
            <div class="mask text-center p-8 md:p-16">
              <h2 class="text-lg md:text-xl">8 page - full scripture</h2>
              <p class="text-sm md:text-md">This booklet is another one of our best sellers. <i>The full scripture readings are included with additional pages for photos, poems, prayers, quotes, and/or an expression of gratitude. It is saddle stitched (stapled through the fold line).</i><br /><br /><i>Pricing starts at $99</i><br /><br /><span class="text-sm italic font-medium text-blue hover:text-melon cursor-pointer" @click="toggleModal('EightPageFullFuneralBooklet')">View sample</span></p>        
              <router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'EightPageFullFuneralBooklet' }}" class="btn mx-auto sm:w-auto">Select</router-link>
            </div>
          </div>
        </div>
        <div class="md:flex-1 md:ml-6 mt-12 md:mt-0 pb-3">
          <div v-bind:class="toggleview" class="rounded-lg shadow-lg">
            <img src="@/assets/images/TwelvePage.jpg"/>
            <div class="mask text-center p-8 md:p-16">
              <h2 class="text-lg md:text-xl">12 page - full scripture</h2>
              <p class="text-sm md:text-md">This booklet is an extended version of the 8 Page Full Scripture. <i>The full scripture readings are included with plenty of additional pages available for photos, poems, prayers, quotes, and/or an expression of gratitude. It is saddle stitched (stapled through the fold line).</i><br /><br /><i>Pricing starts at $99</i><br /><br /><span class="text-sm italic font-medium text-blue hover:text-melon cursor-pointer" @click="toggleModal('TwelvePageFuneralBooklet')">View sample</span></p>
              <router-link :to="{ name: 'funeral-booklet', params: { id: 'create', templateName: 'TwelvePageFuneralBooklet' }}" class="btn mx-auto sm:w-auto">Select</router-link>
            </div>
          </div>
        </div>
      </div>    
      <hr>
      <h2 class="text-2xl md:text-3xl text-center">Comparison Chart</h2>
      <Comparison-Chart></Comparison-Chart>
    </div>  
    <show-modal-popup :showModal="showModal" modal-class="max-w-lg p-12">
      <div slot="modal-content">
          <sample-generator :templateName="templateName"></sample-generator>
      </div>
    </show-modal-popup>
  </div>

</template>

<script>
  import ComparisonChart from '../components/ComparisonChart'
  import ShowModalPopup from "../components/ShowModalPopup"
  import SampleGenerator from "../components/SampleGenerator"

  export default {
    name: "FuneralBooklets",
    data() {
        return {
            showModal: false,
            templateName: null
        }
    },    
    components: { ComparisonChart, ShowModalPopup, SampleGenerator },
    methods: {
      toggleModal(bookletName) {
          let self = this;

          self.templateName = bookletName;
          self.showModal = true;
      }
    },
    computed: {
      toggleview: function() {
        return screen.width > 992 ? 'view' : '';
      }
    }
  };
</script>

<style scoped>
hr {
  @apply mt-12;
  @apply mb-12;
  @apply max-w-2xl;
  @apply border-0;
  @apply h-px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));  
}

.view {
  min-height: 20rem;
  @apply overflow-hidden;
  @apply relative;
  @apply cursor-default;
}

.view .mask,
.view .content {
  min-height: 32rem;
  @apply bg-white;
  @apply absolute;
  @apply overflow-hidden;
  top: 0;
  left: 0;
  transition: all 0.5s linear;
  opacity: 0;
}

.view img {
  min-height: 20rem;
  @apply block;
  @apply relative;
  @apply w-full;
  transform: scaleY(1);
  transition: all .7s ease-in-out;
}

.view h2 {
  @apply pb-3;
  @apply text-center;
  @apply relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3); 
  transform: scale(0);
  transition: all 0.5s linear;
  opacity: 0;
}

.view p {
  @apply pt-3;
  @apply relative;
  transform: scale(0);
  transition: all 0.5s linear;
  opacity: 0;
}

.view btn {
  @apply relative;
  @apply text-center;
  transform: scale(0);
  transition: all 0.5s linear;
  opacity: 0;
}

.view:hover img {
  -webkit-transform: scale(10);
  transform: scale(10);
  opacity: 0;
}

.view:hover .mask {
  opacity: 1;
}

.view:hover h2,
.view:hover p,
.view:hover btn {
  transform: scale(1);
  opacity: 1;
}

</style>
