import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home'
import Checkout from './views/Checkout'
import Confirmation from './views/Confirmation'
import Register from './views/Register'
import SignIn from './views/SignIn'
import Profile from './views/Profile'
import NotFound from './views/NotFound'
import ResetPasswordRequest from './views/ResetPasswordRequest'
import ResetPassword from './views/ResetPassword'
import MyBooklets from './views/MyBooklets'
import FuneralBooklets from './views/FuneralBooklets'
import FuneralBookletOptions from './views/FuneralBookletOptions'
import FuneralBooklet from './views/FuneralBooklet'
//import Help from './views/Help'
import AboutUs from './views/AboutUs'
// import OurStory from './views/OurStory'
//import FAQ from './views/FAQ'
import ContactUs from './views/ContactUs'
import PrivacyPolicy from './views/PrivacyPolicy'
import TermsOfService from './views/TermsOfService'
import storageService from './services/StorageService'
import HowItWorks from './views/HowItWorks'
import FallenHeros from './views/FallenHeros'
// import Children from './views/Children'
// import Veterans from './views/Veterans'
//import OrderOfChristianFunerals from './views/OrderOfChristianFunerals'
//import PurposeOfAFuneral from './views/PurposeOfAFuneral'
import OurGuarantee from './views/OurGuarantee'
//import Mission from './views/Mission'
//import WhatMakesUsDifferent from './views/WhatMakesUsDifferent'
//import Dedication from './views/Dedication'
import Resources from './views/Resources'
import Pricing from './views/Pricing'
import PrePlanning from './views/PrePlanning'
// import WhyPrePlan from './views/WhyPrePlan'
// import Booklets from './views/Booklets'
// import SaveAndRetrieveYourBooklet from './views/SaveAndRetrieveYourBooklet'
import Wizard from './views/Wizard'
import Booklet from './views/Booklet'
import BookletPreApproval from './views/BookletPreApproval'
import BookletApproval from './views/BookletApproval'
import PrintOptions from './views/PrintOptions'
import AffiliateRegister from './views/AffiliateRegister'
import AffiliateProviders from './views/AffiliateProviders'
import AffiliateConfirmation from './views/AffiliateConfirmation'


Vue.use(Router);

let router = new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { title: 'Home' }
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: Checkout,
      props: true,
      meta: { title: 'Checkout' }
    },
    {
      path: '/booklets/:id/print-options',
      name: 'print-options',
      component: PrintOptions,
      props: true,
      meta: { title: 'Print Options' }
    },
    {
      path: '/confirmation',
      name: 'confirmation',
      component: Confirmation,
      props: true,
      meta: { title: 'Confirmation' }
    },
    {
      path: '/affiliate-providers',
      name: 'affiliateproviders',
      component: AffiliateProviders,
      meta: { title: 'Affiliates Providers' }
    },
    {
      path: '/affiliate-register',
      name: 'affiliateregister',
      component: AffiliateRegister,
      meta: { title: 'Affiliates Register' }
    },
    {
      path: '/affiliate-confirmation',
      name: 'affiliateconfirmation',
      component: AffiliateConfirmation,
      props: true,
      meta: { title: 'Affiliate Confirmation' }
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      props: true,
      meta: { title: 'Register' }
    },
    {
      path: '/sign-in',
      name: 'sign-in',
      component: SignIn,
      props: true,
      meta: { title: 'Sign In' }
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: { title: 'Profile' }
    },
    {
      path: '/reset-password',
      name: 'reset-password-request',
      component: ResetPasswordRequest,
      meta: { title: 'Reset Password' }
    },
    {
      path: '/reset-password/:id',
      name: 'reset-password',
      component: ResetPassword,
      meta: { title: 'Reset Password' }
    },
    {
      path: '/my-booklets',
      name: 'my-booklets',
      component: MyBooklets,
      meta: { title: 'My Booklets' }
    },
    {
      path: '/funeral-booklets',
      name: 'funeral-booklets',
      component: FuneralBooklets,
      meta: { title: 'Funeral Booklets' }
    },
    {
      path: '/funeral-booklet-options',
      name: 'funeral-booklet-options',
      component: FuneralBookletOptions,
      meta: { title: 'Funeral Booklet Options' }
    },
    {
      path: '/funeral-booklets/create',
      name: 'funeral-booklet',
      props: true,
      component: FuneralBooklet,
      meta: { title: 'Funeral Booklet' }
    },
    {
      path: '/booklets/:id/edit',
      name: 'booklet',
      component: Booklet,
      props: true,
      meta: { title: 'Booklet' }
    },
    {
      path: '/booklets/:id/pre-approval',
      name: 'booklet-pre-approval',
      component: BookletPreApproval,
      meta: { title: 'Booklet Pre-Approval' }
    },
    {
      path: '/booklets/:id/approval',
      name: 'booklet-approval',
      component: BookletApproval,
      props: true,
      meta: { title: 'Booklet Approval' }
    },
    // {
    //   path: '/help',
    //   name: 'help',
    //   component: Help,
    //   meta: { title: 'Help' }
    // },
    {
      path: '/about-us',
      name: 'about-us',
      component: AboutUs,
      meta: { title: 'About Us' }
    },
    // {
    //   path: '/our-story',
    //   name: 'our-story',
    //   component: OurStory,
    //   meta: { title: 'Our Story' }
    // },
    // {
    //   path: '/faq',
    //   name: 'faq',
    //   component: FAQ,
    //   meta: { title: 'FAQ' }
    // },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: ContactUs,
      meta: { title: 'Contact Us' }
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicy,
      meta: { title: 'Privacy Policy' }
    },
    {
      path: '/terms-of-service',
      name: 'terms-of-service',
      component: TermsOfService,
      meta: { title: 'Terms of Service' }
    },
    {
      path: '/how-it-works',
      name: 'how-it-works',
      component: HowItWorks,
      meta: { title: 'How It Works' }
    },
    {
      path: '/fallen-heros',
      name: 'fallen-heros',
      component: FallenHeros,
      meta: { title: 'Fallen Heros' }
    },
    // {
    //   path: '/fallen-heros/children',
    //   name: 'children',
    //   component: Children,
    //   meta: { title: 'Children' }
    // },
    // {
    //   path: '/fallen-heros/children',
    //   name: 'children',
    //   component: Children,
    //   meta: { title: 'Children' }
    // },
    // {
    //   path: '/fallen-heros/veterans',
    //   name: 'veterans',
    //   component: Veterans,
    //   meta: { title: 'Veterans' }
    // },
    // {
    //   path: '/resources/order-of-christian-funerals',
    //   name: 'order-of-christian-funerals',
    //   component: OrderOfChristianFunerals,
    //   meta: { title: 'Order of Christian Funerals' }
    // },
    // {
    //   path: '/resources/purpose-of-a-funeral',
    //   name: 'resources/purpose-of-a-funeral',
    //   component: PurposeOfAFuneral,
    //   meta: { title: 'Purpose of a Funeral' }
    // },
    {
      path: '/our-guarantee',
      name: 'our-guarantee',
      component: OurGuarantee,
      meta: { title: 'Our Guarantee' }
    },
    // {
    //   path: '/about/mission',
    //   name: 'mission',
    //   component: Mission,
    //   meta: { title: 'Mission' }
    // },
    // {
    //   path: '/about/what-makes-us-different',
    //   name: 'what-makes-us-different',
    //   component: WhatMakesUsDifferent,
    //   meta: { title: 'What Makes Us Different' }
    // },
    // {
    //   path: '/about/dedication',
    //   name: 'dedication',
    //   component: Dedication,
    //   meta: { title: 'Dedication' }
    // },
    {
      path: '/resources',
      name: 'resources',
      component: Resources,
      meta: { title: 'Resources' }
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: Pricing,
      meta: { title: 'Pricing' }
    },
    {
      path: '/pre-planning',
      name: 'pre-planning',
      component: PrePlanning,
      meta: { title: 'Pre-planning' }
    },
    // {
    //   path: '/pre-planning/why-pre-plan',
    //   name: 'why-pre-plan',
    //   component: WhyPrePlan,
    //   meta: { title: 'Why Pre-Plan' }
    // },
    // {
    //   path: '/pre-planning/booklets',
    //   name: 'booklets',
    //   component: Booklets,
    //   meta: { title: 'Booklets' }
    // },
    // {
    //   path: '/pre-planning/save-and-retrieve-your-booklet',
    //   name: 'save-and-retrieve-your-booklet',
    //   component: SaveAndRetrieveYourBooklet,
    //   meta: { title: 'Save and Retrieve Your Booklet' }
    // },
    {
      path: '/wizard',
      name: 'wizard',
      component: Wizard,
      meta: { title: 'Booklet Wizard' }
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFound,
      meta: { title: 'Not Found' }
    }
  ]
});

router.beforeEach((to, from, next) => {
  let cookieDurationInMinutes = Vue.$config.versionCookieDurationInMinutes;
  let cookieVersion = storageService.getCookieVersion();

  if (cookieDurationInMinutes == 0) {
  }
  else if (!cookieVersion) {
    document.location.href = to.fullPath;

    return;
  } else {
    storageService.setCookieVersion(Vue.$config.version, cookieDurationInMinutes);
  }

  document.title = to.meta.title;

  next();
});

export default router;