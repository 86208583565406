<template>
  <div>
    <h1>Thank you for your order!</h1>
    <div v-if="!data.error" class="text-md md:text-lg mx-auto max-w-lg text-left">
      <p>
         MY WAY BOOKLETS', extends our sincere condolences for the loss of your loved one, 
        {{ booklet.deceasedName }}. We thank you for your order, and, we are confident that you will
        capture the hearts of all in attendance with the beautiful keepsake reflective of treasured memories.
      </p>
      <p>
        May our compassionate Lord be with you as you face the days ahead. And, may your faith remind you that 
        God comforts us in our afflictions and enables us to comfort those who grieve with the same consolation.
      </p>
      <div class="mx-auto max-w-md text-center">
        <div class="mx-auto md:text-lg text-left">
          <div class="pt-6">
            <div class="inline-block md:w-48 text-grey-darker">Order # : </div>
            <div class="inline-block font-medium">{{ data.orderId }} </div>
          </div>
          <div class="pt-6 font-medium">Order Summary : </div>
          <div class="pt-1">
            <div class="inline-block md:w-48 text-grey-darker">Decedent's Name : </div>
            <div class="inline-block font-medium whitespace-no-wrap">{{ booklet.deceasedName }}</div>
          </div> 
          <div>
            <div class="inline-block md:w-48 text-grey-darker">Mass Date : </div>
            <div class="inline-block font-medium"></div>
          </div> 
          <div>
            <div class="inline-block md:w-48 text-grey-darker">Booklet Template : </div>
            <div class="inline-block font-medium">{{ options.bookletName }}</div>
          </div>                                                                       
          <div>
            <div class="pt-6 inline-block md:w-48 text-grey-darker">No of <b>Copies</b> : </div>
            <div class="inline-block font-medium">{{ options.printOptions.quantity }}</div>
          </div>
          <div v-if="options.isPrintOrder">
            <div v-if="options.isMultiPage">
              <div>
                <div class="inline-block md:w-48 text-grey-darker">Paper <b>Cover / Text</b> : </div>
                <div class="inline-block font-medium">{{ options.paperName }} - {{ options.coverGrade }} / <span class="whitespace-no-wrap">{{ options.paperName }} - {{ options.interiorGrade }}</span></div>
              </div>
            </div>  
            <div v-if="!options.isMultiPage">
              <div>
                <div class="inline-block md:w-48 text-grey-darker">Paper <b>Cover</b> : </div>
                <div class="inline-block font-medium">{{ options.paperName }} - {{ options.coverGrade }}</div>
              </div>
            </div>
            <div>
              <div class="inline-block md:w-48 text-grey-darker">Paper <b>Color</b> : </div>
              <div class="inline-block font-medium">{{ options.printOptions.paperColor }}</div>          
            </div>   
            <div>
              <div class="inline-block md:w-48 text-grey-darker">Shipping &amp; Handling : </div>
              <div class="inline-block">UPS Next Day Air by 10:30 a.m.</div>          
            </div>
            <div class="pt-6 italic">Your order is being processed.</div> 
            <div class="italic">Please look for your Shipping Email with the UPS Ttracking number.</div>
            <div class="pt-6">
              <div class="inline-block md:w-64 text-grey-darker">Shipping Information : </div>
              <div class="inline-block">{{ shippingAddress.firstName }} {{ shippingAddress.lastName }}</div> 
            </div>
            <div>
              <div class="inline-block md:w-64 text-grey-darker">&nbsp;</div>
              <div class="inline-block">{{ shippingAddress.address1 }}</div> 
            </div>
            <div v-if="shippingAddress.address2">>
              <div class="inline-block md:w-64 text-grey-darker">&nbsp;</div>
              <div class="inline-block">{{ shippingAddress.address2 }}</div> 
            </div>
            <div>
              <div class="inline-block md:w-64 text-grey-darker">&nbsp;</div>
              <div class="inline-block">{{ shippingAddress.city }}, {{ shippingAddress.state }} {{ shippingAddress.zipCode }}</div> 
            </div>
          </div>

          <div class="pt-6">
            <div v-if="options.isPrintOrder" class="inline-block md:w-64 text-grey-darker">Confirmation Sent To : </div>
            <div v-if="!options.isPrintOrder" class="inline-block md:w-48 text-grey-darker">Confirmation Sent To : </div>
            <div class="inline-block">{{ currentUser.firstName }} {{ currentUser.lastName }}</div> 
          </div>
          <div>
            <div v-if="options.isPrintOrder" class="inline-block md:w-64 text-grey-darker">&nbsp;</div>
            <div v-if="!options.isPrintOrder" class="inline-block md:w-48 text-grey-darker">&nbsp;</div>
            <div class="inline-block">{{ currentUser.emailAddress }}</div> 
          </div>

          <div class="py-12">
            <div v-if="options.isPrintOrder" class="inline-block md:w-64 text-grey-darker">&nbsp;</div>
            <div v-if="!options.isPrintOrder" class="inline-block md:w-48 text-grey-darker">&nbsp;</div>
            <div class="inline-block"><a :href="`/files/${booklet.id}.pdf`" target="_blank">Download PDF file</a></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="data.error">
      An error occurred
    </div>
  </div>
</template>

<script>
  import * as api from '../client/api.js'
  import {mapGetters} from 'vuex'

  export default {
    name: 'Confirmation',
    props: ['data'],
    data() {
      return {
        booklet: {}
      }
    },
    computed: {
      ...mapGetters(['signedIn', 'currentUser']),
      shippingAddress: function() {
        return this.data.shippingAddress
      },
      options: function() {
        return this.data.options
      }
    },
    created() {
      let self = this

      api.BookletsByIdGet({ id: self.data.bookletId }).then(response => {
        if (response && response.data) {
          self.booklet = response.data;
        }
      }).catch(e => {
        self.processErrorMessages(e);
        self.handleApiError(e);
      });
    }
  }
</script>

<style scoped>
hr {
  @apply mt-12;
  @apply mb-12;
  @apply max-w-2xl;
  @apply border-0;
  @apply h-px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));  
}
</style>
