<template>
  <div>
    <div class="mx-auto max-w-xl">
      <div class="md:px-8 text-left">
        <h1>Order of Christian Funerals</h1>
        <p>
          The Catholic Church celebrates a funeral Mass with a particular set of liturgical rites; a/k/a the Order of Christian Funerals with Cremation Rites, the 1997 Appendix for Cremation. These Rites contain the requested prayers, blessings and scripture readings to plan the Catholic funeral  Mass.
        </p>
        <p>  
          The three principal parts of the Catholic funeral liturgy are: the vigil or the “wake”; the funeral mass; and, the committal or final resting place. In celebrating these moments of prayer, we celebrate our faith in Christ; express our love for the person who has died; remain faithful to a human relationship shared through life; and, walk with him or her on their final journey to their next life with God. Baptism welcomes us as a family member of the Church, a Funeral demonstrates that relationship is not broken even by death.
        </p>
        <p>
          MY WAY BOOKLETS' promotes this celebration of life in accordance with said Order of Christian Funerals (OCF) with Cremation Rites, the 1997 Appendix for Cremation, as it simplistically assists with the creation of a custom funeral Mass booklet. The complete selection of scripture readings for the First Reading, the Second Reading, the Gospel Reading, the Responsorial Psalms and the Hymns recommended by the (OCF) for use when planning a Catholic Funeral Mass are provided with every booklet option.
        </p>
        <p>  
          Additionally, it permits you to upload photographs, poems, prayers and quotes from your own personal library or from our resource images library, creating a profound tribute to the life of your loved one. These special features were specifically designed to add caring elements and deliver a memorable treasured keepsake to all in attendance.
        </p> 

        <h1>Purpose of a Funeral</h1>
        <h2></h2> 
        <p>
          For thousands of years, funerals have been a means of expressing our beliefs, thoughts and feelings. It helps us acknowledge the fact that someone we love has passed, allowing us to physically say goodbye before the they are taken to their final resting place.
        </p>
        <p>
          Every LIFE has a story, no two are the same.  It is full of opportunities to express love for another, a Funeral is one of those opportunities. The purpose of a funeral is to help: acknowledge the death; remember a life; acknowledge their unique personality, their life's path, and, most importantly, activate support from family and friends during this naturally difficult time.  This encouragement stimulates feelings for all parties as it affirms the relationship and leads to healing after the loss.
        </p>
        <p>
          Planning a loving tribute, captures the spirit of the loved one,  celebrates their life choices, affirms shared relationships, honors memories, and pays special tribute to the celebrated life of your dearly beloved.  Honoring another person’s life is truly an act of love - for everyone!
        </p>
        <p>
          Conclusively, a funeral provides an opportunity for family and friends to gather, share stories, express their love, respectfully honor someone, who was important to them, and console the survivors. Often, these validated stories and emotions offer consolation to surviving family members which helps them adjust to their loss and face the reality of their loved one's Eternal Life. An extremely difficult first step toward taking grief from the inside and allowing them to express it on the outside through mourning.
        </p>
        <p>
          Meaningful loving funerals are doorways of healing for the bereaved.
        </p>               
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>