import * as Handlebars from 'handlebars'
import moment from "moment";

Handlebars.registerHelper("date", function (context, options) {
  if (context) {
    let momentDate = moment(context);

    if (momentDate.isValid()) {
      return momentDate.format('MMMM D, YYYY');
    }
  }
});

Handlebars.registerHelper("massDate", function (context, options) {
  if (context) {
    let momentDate = moment(context);

    if (momentDate.isValid()) {
      return momentDate.format('dddd, MMMM D, YYYY');
    }
  }
});