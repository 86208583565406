<template>
    <div class="flex m-0">
        <span v-if="checked" class="text-sm pr-2">Yes</span>
        <span v-if="!checked" class="text-sm pr-2">No</span>
        <span>
            <label class="toggle-control">
                <input type="checkbox" checked="checked"  @change="triggerToggle($event)" ref="checkbox"/>
                <span class="control"></span>
            </label>
        </span>

    </div>
</template>

<script>
export default {
    name: 'ToggleSwitch',
    props: ['isActive'],
    data() {
        return {
            checked: this.isActive
        }
    },
    methods: {
        triggerToggle(e) { 
			this.checked = !this.checked
            this.$emit('change', this.checked)
        }
    },
    mounted() {
        if (!this.isActive) {
		   this.checked = !this.isActive
           this.$refs.checkbox.click()
		} 
	},
	watch: {
		isActive() {
			if (this.checked != this.isActive) this.$refs.checkbox.click()
		}	
	}
}
</script>

<style scoped>
@import "../assets/css/ToggleSwitch.css";

</style>