<template>
  <div v-if="!cremation">
    <label v-html="label" class="inline-block align-top mt-2"></label>
    <div v-if="instruction" v-html="instruction" class="text-sm text-grey-darker pb-1 px-1"></div>
    <div class="inline-block max-w-xs">
      <div v-for="(value, index) in values" v-bind:key="index" class="my-1">
        <input ref="listInputs" @input="changeNotify" @keyup="changed(index, $event)" @paste="changed(index, $event)" type="text" v-model="values[index]" />
      </div>
      <input ref="listInput"
             type="text"
             :name="name"
             @keyup="changed(null, $event)"
             @paste="changed(null, $event)"
             :placeholder="placeholder" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ListInput',
    props: ['placeholder', 'label', 'name', 'value', 'instruction', 'cremation'],
    data() {
      return {
        values: this.value || []
      }
    },
    methods: {
      changed(index, event) {
        this.$nextTick(() => {
          let value = event.target.value;

          if (index != null) {
            if (value && event.key !== 'Escape') {
              this.values[index] = value;

              if (event.key === 'Enter') {
                this.$refs.listInput.focus();
              }
            }
            else {
              this.values.splice(index, 1);
              this.$refs.listInput.focus();
            }

            this.changeNotify();
          }
          else {
            if (value) {
              let i = this.values.length;

              this.values.push(value);
              event.target.value = null;
              this.changeNotify();

              this.$nextTick(() => {
                let input = this.$refs.listInputs[i];

                input.setSelectionRange(input.value.length, input.value.length);
                input.focus();
              });
            }
          }
        });
      },
      changeNotify() {
        this.$emit('input', this.values);
      }
    }
  }
</script>
