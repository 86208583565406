<template>
  <div class="mx-auto max-w-full">
    <div
      class="hero px-8 py-12 md:py-24 -mt-8 -ml-8 -mr-8"
        :style="{ backgroundImage: 'url(' + require('@/assets/images/Hero-smm.jpg') + ')' }" >
      <h1 class="text-4xl md:text-5xl text-white">Catholic Funeral Mass Booklets</h1>
      <h2 class="text-2xl md:text-3xl text-white max-w-xl mx-auto pb-8">
        Remember Yesterday, Design Today,
        <br />Treasure Forever
      </h2>
      <router-link
        to="/funeral-booklets"
        class="btn mx-auto sm:w-auto bg-melon lg:bg-transparent border-white text-white"
      >Get Started</router-link>
      <div class="text-xl md:text-3xl max-w-lg text-white mx-auto mt-12">Selective Booklets Designed for Simplistic <br class="hidden lg:inline-block">Creation, Print, and Next Day Delivery</div>
    </div>

    <div class="py-2 sm:py-0 sm:h-16 bg-red-light -ml-8 -mr-8">
      <div class="flex items-center mx-auto max-w-md cursor-pointer" @click="showModal=true">
        <div class="flex-auto hidden sm:block">
          <img class="opacity-75" src="../assets/images/Covid-19.png" />
        </div>
        <div class="flex-auto">
          <div class="pl-3 text-left text-white hover:text-melon sm:border-t border-cream border-dotted">
            <div class="text-md sm:text-xl">COVID-19: Coronavirus</div>
            <div class="text-xs sm:text-md text-grey-light -mt-1">Information regarding funeral mass services </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="testimonial h-96 pt-20 -ml-8 -mr-8"
      :style="{ backgroundImage: 'url(' + require('@/assets/images/Testimonial-bg.jpg') + ')' }"
    >
      <!-- <div class="flex justify-center" >
        <vue-trustpilot identifier="starter" />
      </div> -->
      <rate5 v-if="testimonial.rating === 50" class="mb-6 h-12"></rate5>
      <rate45 v-if="testimonial.rating === 45" class="mb-6 h-12"></rate45>
      <div
        class="text-sm md:text-lg max-w-xs md:max-w-md mx-auto text-grey-darkest relative font-light"
      >"{{testimonial.text}}"</div>
      <div class="text-sm md:text-md text-grey-darker mt-3">
        &mdash;
        <strong>{{testimonial.name}}</strong>
        , {{testimonial.location}}
      </div>
    </div>

    <div class="p-6 -ml-8 -mr-8 py-12 md:py-24">
      <div class="mx-auto md:max-w-xl md:flex">
        <div class="mb-12 md:mb-0 md:flex-1 md:mr-6 lg:mr-8">
          <div class="text-2xl md:text-3xl mx-auto max-w-xs text-grey-darkest pb-6">Booklet Options</div>
          <div class="optionsImg imageEffect" @click="redirectfuneralbookletoptions">&nbsp;</div>
          <div class="text-md md:text-lg mx-auto max-w-sm text-grey-darkest my-8">
            Create a one of a kind Life Celebration tribute, a custom funeral mass booklet, in accordance with the
            <router-link to="/resources/order-of-christian-funerals">Order of Christian Funerals</router-link>with Cremation Rites.
          </div>
          <router-link
            to="/funeral-booklet-options"
            class="btn"
          >Learn more</router-link>
        </div>
        <div class="md:flex-1 md:ml-6 lg:ml-8">
          <div class="text-2xl md:text-3xl mx-auto max-w-xs text-grey-darkest pb-6">Pre-planning</div>
          <div class="preplanImg imageEffect" @click="redirectPrePlanning">&nbsp;</div>
          <div
            class="text-md md:text-lg mx-auto max-w-sm text-grey-darkest my-8"
          >Advanced decisions alleviate future anxiety of your spouse, family, or friends; allowing your booklet to be designed with your personal touch.</div>
          <router-link
            to="/pre-planning"
            class="btn"
          >Learn more</router-link>
        </div>
      </div>
    </div>

    <div class="bg-white -ml-8 -mr-8 -mb-16 py-12 sm:py-16">
      <h2
        class="text-xl md:text-2xl md:max-w-xl mx-auto text-grey-darkest font-light"
      >Follow Us or Join Our List for Updates, and Insperational Quotes</h2>

      <div class="w-full max-w-sm mx-auto mt-12 mb-8 hidden sm:block">
        <div class="flex items-center border-b border-b-2 border-teal py-2">
          <input
            name="EmailAddress"
            type="email"
            v-model="emailAddress"
            class="appearance-none bg-transparent border-none w-full text-grey-darker py-3 px-2 leading-tight focus:outline-none"
            placeholder="Enter an email"
            aria-label="Email Address"
          />
          <button
            @click="redirectRegister"
            class="flex-no-shrink bg-teal hover:bg-teal-dark border-teal hover:border-teal-dark text-sm border-4 text-white py-1 px-2 rounded"
            type="button"
          >Sign Up</button>
          <button
            class="flex-no-shrink border-transparent border-4 text-teal hover:text-teal-darker text-sm py-1 px-2 rounded"
            type="button"
          >Cancel</button>
        </div>
      </div>
      <div class="w-80 max-w-sm mx-auto mb-16 sm:hidden">
        <div class="flex items-center border-b border-b-2 border-teal py-2">
          <input
            class="appearance-none bg-transparent border-none w-full text-grey-darker mr-3 py-3 px-2 leading-tight focus:outline-none"
            type="text"
            placeholder="Enter an email"
            aria-label="Email Address"
          />
        </div>
        <div class="float-right pt-3">
          <button
            class="flex-no-shrink bg-teal hover:bg-teal-dark border-teal hover:border-teal-dark text-sm border-4 text-white py-1 px-2 rounded"
            type="button"
          >Sign Up</button>
          <button
            class="flex-no-shrink border-transparent border-4 text-teal hover:text-teal-darker text-sm py-1 px-2 rounded"
            type="button"
          >Cancel</button>
        </div>
      </div>
    </div>
   
    <show-modal-popup :showModal="showModal" modal-class="max-w-lg p-6 sm:p-12 ">
      <div slot="modal-content">
        <div class="text-sm sm:text-lg covid19">
          <p class="mb-3 text-center"><i>“Come to me, all you who are weary and burdened, and I will give you rest.”<br>Matthew 11:28</i></p>
          <p class="my-3">As Catholics we know the burial of and praying for the dead are corporal and spiritual works of mercy, and we know the importance to our lives.</p>
          <p class="my-3">During this <b>COVID19 pandemic</b>, our Catholic beliefs, rituals, and traditions have been replaced with restrictions, mandated guidelines and recommendations of the Federal, State and local health officials to keep everyone healthy and safe. Sadly, this major disruption suspended public Funeral Masses and Committals, causing great pain and anguish to the surviving families. The Church preferred families to wait for these restrictions to be lifted, and appreciated if an immediate graveside burial was chosen, but, they strongly encouraged a Catholic Memorial Mass be scheduled when Public Masses resume.</p>
          <p class="my-3">Recently announced guidelines will allow In-Person Catholic Masses to resume at the discretion of the Parish Pastor, including Funeral Masses. These directives will permit each Diocese to resume Public Mass by mid-May under their own accord and they will be amended to correspond with the current pandemic developments.</p>
          <p class="mt-3">In harmony with this consensus, <i>My Way Booklets’</i>  is honored to reinstate the beliefs, rituals, traditions, and love for another by assisting with the creation of a funeral mass booklet that will capture the spirit of your loved one’s life. We sincerely invite you to assess our booklet options to creatively design a loving tribute that: celebrates the meaning of their life; expresses their personality; stimulates memories to be treasured forever; and, physically bids farewell as it bestows a healing threshold for the bereaved.</p>
        </div>
      </div>
    </show-modal-popup>
  </div>
</template>

<script>
import ShowModalPopup from "../components/ShowModalPopup"
import Rate45 from "../components/svg/Rate45.svg";
import Rate5 from "../components/svg/Rate5.svg";
import Testimonials from "../assets/json/Testimonials.json";

export default {
  name: "Home",
  data() {
    return {
      testimonial: Testimonials[0],
      timer: null,
      emailAddress: null,
      showModal: false
    };
  },
  components: {
    Rate45,
    Rate5,
    Testimonials,
    ShowModalPopup,
  },
  methods: {
    redirectfuneralbookletoptions: function() {
      this.$router.push({ name: "funeral-booklet-options" });
    },
    redirectPrePlanning: function() {
      this.$router.push({ name: "pre-planning" });
    },
    redirectRegister: function() {
      this.$router.push({
        name: "register",
        params: { emailAddress: this.emailAddress }
      });
    },
    startRotation: function() {
      this.timer = setInterval(this.rotateTestimonials, 10000);
    },
    rotateTestimonials() {
      this.testimonial =
        Testimonials[Math.floor(Math.random() * Testimonials.length)];
    }
  },
  mounted: function() {
    this.startRotation();
  }
};
</script>

<style scoped>
.hero,
.testimonial {
  @apply bg-cover;
  @apply bg-center;
  @apply bg-no-repeat;
  @apply relative;
}
.optionsImg {
  background-image: url("../assets/images/main-booklet.jpg");
}
.preplanImg {
  background-image: url("../assets/images/PrePlanning.jpg");
}
.imageEffect {
  @apply shadow-lg;
  @apply rounded-lg;
  height: 20rem;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.7s;
}
.imageEffect:hover {
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  transition: all 0.7s;
  cursor: pointer;
}


@media (max-width: 576px) {
  .covid19 {
    @apply pr-4;
    @apply overflow-auto;
    height: 475px;
   }
}
</style>
