<template>
  <div v-if="loaded">
    <h1>My Booklets</h1>
    <div class="text-md md:text-lg mx-auto max-w-xl text-left">
      <p>
        MY WAY BOOKLETS' extends our deepest condolences to you and your family on the loss of your dearly beloved.  May our compassionate Lord be your guide during this 
        difficult time to see beyond the sorrow, to reflect on your tranquil memories, and to help comfort your tomorrows.
      </p>
      <p>
        “He heals the brokenhearted and binds up their wounds.”  Psalm 147:3
      </p>
    </div>
    <hr>
    <div v-if="booklets && booklets.length > 0">
      <table class="mx-auto">
        <tbody>
          <tr v-for="booklet in booklets" v-bind:key="booklet.id">
            <td class="py-2 pr-4 text-left">{{ booklet.deceasedName }}</td>
            <td class="py-2 pl-4 text-left">
              <span v-if="editable(booklet)"><router-link :to="`/booklets/${booklet.id}/edit`">Edit</router-link> | </span>
              <span v-if="reviewable(booklet)"><router-link :to="`/booklets/${booklet.id}/approval`">Review</router-link> | </span>
              <span v-if="purchaseable(booklet)"><router-link :to="{ name: 'checkout', params: { bookletId: booklet.id }}">Purchase</router-link> | </span>
              <span v-if="booklet.downloadable"><a :href="`/files/${booklet.id}.pdf`" target="_blank">Download</a> | </span>
              <span><a @click="clone(booklet.id)">Duplicate</a></span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="my-12">
        <router-link class="btn" to="/funeral-booklets/create">Create a new booklet</router-link>
      </div>
    </div>
    <div v-if="!booklets || booklets.length === 0" class="my-12">
      Looks like you don't have any booklets yet. <router-link to="/funeral-booklets/create">Create one now!</router-link>
    </div>
    <div class="my-4 error-summary"></div>
  </div>
</template>

<script>
  import * as api from '../client/api.js'
  import { BookletStatus } from '../models/BookletStatus'

  export default {
    name: 'MyBooklets',
    data() {
      return {
        booklets: null,
        loaded: false
      }
    },
    created() {
      let self = this;

      api.BookletsGet().then(response => {
        if (response && response.data) {
          self.booklets = response.data;
          self.loaded = true;
        }
      }).catch(e => this.handleApiError(e));
    },
    methods: {
      clone(id) {
        let self = this;

        api.BookletsByIdClonePost({ id: id }).then(response => {
          if (response && response.data) {
            self.$router.push({ name: 'booklet', params: { id: response.data }});
          }
        }).catch(e => {
          self.processErrorMessages(e);
          self.handleApiError(e);
        })
      },
      editable(booklet) {
        return booklet.status < BookletStatus.PreApproved;
      },
      reviewable(booklet) {
        return booklet.status >= BookletStatus.PreApproved && booklet.status < BookletStatus.Ordered;
      },
      purchaseable(booklet) {
        return booklet.status === BookletStatus.Approved;
      }
    }
  }
</script>

<style scoped>
hr {
  @apply mt-12;
  @apply mb-12;
  @apply max-w-2xl;
  @apply border-0;
  @apply h-px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));  
}
</style>
