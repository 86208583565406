import ApiError from "../models/ApiError"

export default class ValidationService {
  static processErrorMessages(component, error) {
    if (!(error instanceof ApiError)) {
      return;
    }

    let inputs = component.$el.querySelectorAll('.error');

    for (let i = 0; i < inputs.length; ++i) {
      let input = inputs[i];

      input.classList.remove('error');
      input.style = null;

      if (input.nextSibling && input.nextSibling.classList.contains('error-message')) {
        input.parentNode.removeChild(input.nextSibling);
      }
    }

    for (let name in error.messages) {
      let input = component.$el.querySelector(`[name="${name}"]`);

      if (name) {
        if (input) {
          input.setAttribute('style', 'border: 1px solid red;');
          input.setAttribute('class', 'error');

          let span = document.createElement('span');
          span.innerText = error.messages[name];
          span.setAttribute('style', 'display: inline-block; margin-left: 5px; color: red; font-size: smaller;');
          span.setAttribute('class', 'error-message');

          input.parentNode.insertBefore(span, input.nextSibling);
        }
        else {
          error.messages[''] += ` ${error.messages[name]}`;
        }
      }
    }

    let summary = component.$el.querySelector('.error-summary');

    if (summary) {
      let summaryText = error.statusCode === 401 ? 'Login failed' : error.messages[''];
      summary.innerHTML = `<span style="color: red">${summaryText}</span>`;
    }
  }
}
