<template>
  <div :class="['mx-auto', {'bg-grey-light': this.page === 0}]">
    <iframe frameborder="0" allowfullscreen src="javascript:void(0)" ref="bookletViewer"></iframe>
  </div>
</template>

<script>
  import * as Handlebars from 'handlebars'
  import '../helpers/handlebars'

  export default {
    name: 'BookletViewer',
    props: ['template', 'model', 'pageNumber', 'clickable'],
    data() {
      return {
        page: this.pageNumber
      }
    },
    methods: {
      update() {
        let container = window.document.createElement('div');
        container.innerHTML = this.template;

        if (this.clickable) {
          container.querySelectorAll('[data-field]:not([data-field=""])').forEach(x => x.style.cursor = 'pointer');
        }

        let self = this;
        let template = Handlebars.compile(container.innerHTML);
        let content = template(this.model);

        container.innerHTML = content;

        let pages = container.getElementsByClassName('page');

        if (self.page < 0) {
          self.page = 1;
          return;
        }

        if (self.page > pages.length) {
          self.page = pages.length;
          return;
        }

        Array.from(pages).forEach((x, i) => {
          if (self.page === 0 && pages.length === 1) {
            x.style.visibility = 'hidden';
          }
          else if (!x.classList.contains(`page-${self.page}`)) {
            x.remove();
          }
        });

        this.fillPage(this.$refs.bookletViewer, container.innerHTML);
      },

      fillPage(viewerElement, content) {
        let self = this;
        
        let window = viewerElement.contentWindow;
        let scrollTop = window.document.body.scrollTop;

        window.document.open();
        window.document.write(content);
        window.document.close();

        viewerElement.onload = () => {
          self.configViewer(viewerElement);
        };

        //bti - safari/ie initial onload to not execute
        var safari = navigator.userAgent.toLowerCase().indexOf('safari') > -1
        var ie = navigator.userAgent.toLowerCase().indexOf('msie') > -1 || navigator.userAgent.toLowerCase().indexOf('trident') > -1 

        if (safari || ie) self.configViewer(viewerElement);

        window.document.body.scrollTop = scrollTop;
      },
      
      configViewer(viewerElement) {
        let self = this;

        let viewerDocument = viewerElement.contentDocument || viewerElement.contentWindow.document;

        if (this.page === 0) {
          viewerDocument.body.style.backgroundColor = 'transparent';
        }

        let pages = viewerDocument.getElementsByClassName('page');

        if (pages.length > 0) {
          viewerElement.height = pages[0].offsetHeight;
          viewerElement.width = pages[0].offsetWidth;
          self.$emit('load');
        }

        if (this.clickable) {
          let fields = viewerDocument.querySelectorAll('[data-field]');
          fields.forEach((x, i) => {
            x.addEventListener('click', function (event) {
              self.$emit('click', x.attributes['data-field'].value, event);
            }, false);
          });
        }
      }
    },
    mounted() {
      this.update();
    },
    watch: {
      pageNumber() {
        this.page = this.pageNumber;
      },
      template() {
        this.update();
      },
      page() {
        this.update();
      },
      model: {
        handler() {
          this.update();
        },
        deep: true
      }
    }
  }
</script>