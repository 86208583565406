<template>
  <div v-if="showModal">
    <portal to="modals">
      <modal  :open="showModal" :modal-class="modalClass" @close="closeModal">
        <slot name="modal-content" slot="modal-content"></slot>
      </modal>
    </portal>
  </div>
</template>

<script>
  import Modal from './Modal'
  import {mapGetters} from 'vuex'

  export default {
    name: 'ShowModal',
    props: ['showModal', 'modalClass'],
    components: { Modal },
    data() {
      return {
      }
    },
    methods: {
      closeModal(result) {
        //this.showModal = false;
        this.$parent.showModal = false;
        document.body.style.removeProperty('overflow');
        //this.$emit('close', result);
      }
    }
  }
</script>

<style scoped>


</style>

