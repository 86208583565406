const retry = (delay, times, operation) => new Promise((resolve, reject) => {
  let counter = 0;

  let id = setInterval(() => {
    counter++;

    operation().then(x => {
      clearInterval(id);
      resolve(x);
    }).catch();

    if (counter === times) {
      clearInterval(id);
      reject(new Error(`Unable to complete operation after ${times} attempts.`));
    }
  }, delay);
});

export default retry