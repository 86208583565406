<template>
  <div>
    <div class="text-sm p-3 bg-grey-lightest border border-grey rounded h-full">
      <div class="flex flex-col content-between h-full">
        <div class="flex-1 mt-2">
          <div class="pb-1">Image Frame:</div>
          <select v-model="shape" @change="onShapeChange($event)" class="w-full" ref="selectShape">
            <option
              v-for="frame in frames"
              v-bind:key="frame.value"
              v-bind:value="frame.value"
              class="p-1"
            >
              {{ frame.text }}
            </option>
          </select>
        </div>
        <div class="flex-1">
          <div class="pb-1">Stroke:</div>

          <div class="flex">
            <div class="-mt-8 mx-4 w-4/5">
              <div>
                <VueSlideBar 
                  ref="sliderStroke"
                  v-model="strokeSlider.value"
                  :data="strokeSlider.data"
                  :range="strokeSlider.range"
                  :showTooltip="false"
                  :is-disabled="strokeEligible"
                  :processStyle="{ backgroundColor: 'green' }"
                  @callbackRange="onStrokeChange"
                >
                </VueSlideBar>
              </div>
            </div>
            <div class="w-1/5 text-center relative" @click="toggleSwatch">
              <bucket class="h-6"></bucket>
              <compact
                v-if="isSwatch"
                v-model="colors"
                @input="onColorChange"
                class="absolute z-50"
                style="top: 28px; left: -192px"
              ></compact>
            </div>
          </div>
        </div>

        <div class="flex-1">
          <div class="pb-1">Corner Radius:</div>
          <div class="-mt-8 mx-4">
            <div>
              <VueSlideBar
                ref="sliderRadius"
                v-model="radiusSlider.value"
                :data="radiusSlider.data"
                :range="radiusSlider.range"
                :showTooltip="false"
                :is-disabled="radiusEligible"
                :processStyle="{ backgroundColor: 'green' }"
                @callbackRange="onRadiusChange"
              >
              </VueSlideBar>
            </div>
          </div>
        </div>
        <div class="flex-1 pt-3">
          <div class="flex justify-between">
            <div class="flex-auto pb-1">Gradient Edges:</div>
            <div class="flex-1 text-right">
              <toggle-switch
                :isActive="gradient"
                @change="toggleGradient($event)"
              />
            </div>
           
          </div>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
import VueSlideBar from "vue-slide-bar";
import ToggleSwitch from "./ToggleSwitch.vue";
import Bucket from "./svg/Bucket.svg";
import { Compact } from "vue-color";

const option = {
  frames: [
    { text: "Retain Original Dimensions", value: "1" },
    { text: "Render Square frame", value: "2" },
    { text: "Render Circle frame", value: "3" },
    { text: "Render Hexagon frame", value: "4" },
  ],
  colors: {
    hex: "#000",
    rgba: { r: 0, g: 0, b: 0, a: 0 },
  },
  strokeSlider: {
    value: 0,
    data: [0, 2, 4, 6, 8, 10, 12],
    range: [
      { label: "0" },
      { label: "2", isHide: true },
      { label: "4" },
      { label: "6", isHide: true },
      { label: "8" },
      { label: "10", isHide: true },
      { label: "12" },
    ],
  },
  radiusSlider: {
    value: 0,
    data: [0, 10, 25, 35, 50, 75, 100],
    range: [
      { label: "0" },
      { label: "10", isHide: true },
      { label: "25" },
      { label: "35", isHide: true },
      { label: "50" },
      { label: "75", isHide: true },
      { label: "100" },
    ],
  },
};

export default {
  name: "CloudinaryOption",
  props: ['options', 'showModal'],
  components: { VueSlideBar, ToggleSwitch, Bucket, Compact },
  data() {
    return {
      shape: 1,
      isSwatch: false,
      frames: option.frames,
      strokeSlider: option.strokeSlider,
      colors: option.colors,
      radiusSlider: option.radiusSlider,
      gradient: false,
      strokeSize: 0,
      strokeColor: "#000",
      radiusSize: 0
    };
  },
  created() {
    if (this.options) {
        this.shape = this.synchShape()
        this.colors.hex = this.synchStrokeColor()
        this.gradient = this.synchGradientFade()
    }  
  },
  methods: {
    synchShape() {
        const val = this.options.includes(this.$config.cloudinary.original) ? 1 : 2
        return this.options.includes("r_max") ? 3 : this.options.includes("hextagon") ? 4 : val
    },
    synchStrokeSize() {
        if (!this.options.includes("bo_")) return 0
        
        var start = this.options.indexOf("bo_") + 3
        var end = this.options.indexOf("px")
        return this.options.substring(start, end)
    },
    synchStrokeColor() {
        if (!this.options.includes("rgb:")) return "#000"
        
        var start = this.options.indexOf("rgb:") + 4
        var end = this.options.indexOf(",", start)
        return "#" + this.options.substring(start, end)
    },
    synchRadiusSize() {
        if (!this.options.includes(",r_") || this.options.includes("r_max")) return 0
        
        var start = this.options.indexOf(",r_") + 3
        var end = this.options.indexOf(",", start)
        return this.options.substring(start, end)
    },
    synchGradientFade() {
        return this.options.includes("fade") ? true : false
    },


    onShapeChange(e) {
      this.shape = e.target.value
      if (this.shape == 3) this.radiusSlider.value = 0
      if (this.shape == 4) { 
          this.strokeSlider.value = 0 
          this.radiusSlider.value = 0 
      }
    },
    onStrokeChange(val) {
      this.rangeValue = val
      this.strokeSize = this.rangeValue.label
    },
    toggleSwatch() {
      return (this.isSwatch = !this.isSwatch)
    },
    onColorChange(val) {
      this.colors = val
      this.strokeColor = this.colors.hex
    },
    onRadiusChange(val) { 
      this.rangeValue = val
      this.radiusSize = this.rangeValue.label
    },
    toggleGradient(val) {
      this.gradient = val
      if (this.gradient) {
          this.strokeSlider.value = 0 
          this.radiusSlider.value = 0 
      }
    }
  },
  computed: {
    setShapeOption() {
      const original = this.$config.cloudinary.original + ","
      const thumb = this.$config.cloudinary.thumb + ","
      const hexagon = this.setShapeHexagon
      if (this.shape == 4) return thumb + hexagon
      return this.shape == 1 ? this.gradient ? "" : original : thumb
    },
    setShapeHexagon() {
      const self = this
      var hexagon = this.$config.cloudinary.hexagon + ","
      return self.gradient ? hexagon.replace("hexagon", "hexagon_fade") : hexagon
    },
    setStrokeSizeColor() {
      const self = this  
      const filter =
        "bo_" +
        self.strokeSize +
        "px_solid_rgb:" +
        self.setStrokeColor.slice(1) +
        ",";
      return self.strokeSize == 0 || self.strokeSize == null ? "" : filter;
    },
    setStrokeColor() {
        const self = this 
        return self.strokeColor == null ? "#000" : self.strokeColor
    },
    strokeEligible() {
        const self = this
        return self.gradient ? true : false
    },
    setRadiusSize() {
        const self = this
        return self.radiusSize == 0 || self.radiusSize == null ? self.shape == 3 ? "r_max,"  : ""  :  "r_" + self.radiusSize + ","
    },
    radiusEligible() {
        const self = this
        var disable = self.shape == 3 ? true : false
        return self.gradient ? true : disable
    },
    setGradientFade() {
        const self = this
        const original = "/e_gradient_fade:symmetric:10,x_0.09,y_0.09,"
        const square = "w_350/fl_cutter,l_filter:square_fade,w_350,"
        const round = "w_350/fl_cutter,l_filter:circle_fade,w_350,"
        var filter = original
        if (self.shape == 2) filter = square
        if (self.shape == 3) filter = round
        if (self.shape == 4) filter = ""
        return self.gradient ? filter : ""
    },
    finalOptions() {
      const self = this 
      return  (self.setShapeOption + self.setStrokeSizeColor + self.setRadiusSize + self.setGradientFade).slice(0, -1)
    }
  },
  watch: {
      finalOptions() {
        this.$emit('change', this.finalOptions)
      },
      showModal() {
        if (this.showModal && this.options) {
            this.strokeSlider.value = this.synchStrokeSize()
            this.$refs.sliderStroke.refresh()
            this.radiusSlider.value = this.synchRadiusSize()
            this.$refs.sliderRadius.refresh()   
        }
      }
  }
}
</script>
