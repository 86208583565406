<template>
  <div class="w-full bg-navy z-10">
    <div class="mx-auto max-w-3xl">
      <div v-if="!signedIn" class="hidden lg:block">
        <nav>
          <router-link to="/">
            <logo class="m-2 h-20" />
          </router-link>
          <router-link to="/how-it-works">How it works</router-link>
          <router-link to="/affiliate-providers">Funeral Professionals</router-link>
          <MegaMenu />
          <div class="buttons">
            <router-link to="/sign-in" class="outline hover:bg-melon mr-6">
              Sign In
              <Forward class="h-5" />
            </router-link>
            <router-link to="/register" class="outline selected mr-4">Register</router-link>
          </div>
        </nav>
      </div>
      <div v-if="signedIn" class="hidden lg:block">
        <nav>
          <router-link to="/">
            <logo class="m-2 h-20" />
          </router-link>
          <router-link to="/how-it-works">How it works</router-link>                                          
          <router-link to="/my-booklets">My Booklets</router-link>
          <MegaMenu />
          <UserMenu />
        </nav>
      </div>

      <div class="block lg:hidden">
        <nav>
          <router-link to="/" class=flex-auto>
            <logo class="m-2 h-16 md:h-20"/>
          </router-link> 
          <UserProfile v-if="signedIn" class="mr-6"></UserProfile>
          <Burger></Burger>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "../components/svg/Logo.svg";
import MegaMenu from "../components/MegaMenu";
import UserMenu from "../components/UserMenu";
import UserProfile from "../components/UserProfile";
import Burger from "../components/Burger";

import Forward from "../components/svg/icon/Forward.svg";

import { mapGetters } from "vuex";

export default {
  name: "Header",

  data: function() {
    return {
    };
  },

  computed: 
    mapGetters([
      "signedIn",
      "userDisplayName",
      "messages",
      "currentUser"
    ]),
  methods: {
    signOut() {
      this.$store.dispatch("signOut").then(response => {
        this.$router.push("/");
      });
    }
  },
  components: {
    Logo,
    UserProfile, 
    MegaMenu,
    UserMenu,
    Burger,
    Forward
  }
};
</script>

<style scoped>
nav {
  @apply relative;
  @apply flex;
  @apply items-center;
  @apply justify-between;
  min-height: 80px;
}

a {
  @apply text-base;
  @apply capitalize;
  @apply text-cream;
  letter-spacing: 3px;
}

a:hover {
  @apply text-white;
  transition: all 0.3s ease;
}

.buttons {
  @apply flex;
  @apply items-center;
  @apply justify-between;
}

.outline {
  @apply flex;
  @apply items-center;
  @apply text-base;
  @apply px-2;
  @apply py-1;
  @apply border;
  @apply border-cream;
  @apply rounded-sm;
}

.outline:hover {
  @apply text-white;
  @apply border-white;
}

.selected {
  @apply bg-melon;
  @apply text-white;
}

</style>
