<template>
  <div>
    <div class="mx-auto max-w-xl">
      <div class="md:px-8 text-left">

        <h1>Time Is Of The Essence</h1>
        <p>Christian Catholics Funerals normally occur within <b>3 to 4</b> days following a death; however, unforeseen circumstances can delay anywhere from two days to one week.  Hence, time is of the essence… There is a very short window to complete the elements associated with planning a meaningful funeral ceremony.</p>
        <p>Admittedly, these elements can be time consuming, demanding, and, burdensome.</p>
        <p>Why not eliminate one of the elements and channel your grief toward healing?</p>
        <p>Allow MY WAY BOOKLETS’ to assist with the creation of a personalized funeral mass booklet filled with meaningful scripture, photos, poems, and prayers.  A treasured keepsake uniquely designed with dual purpose:  1) to celebrate the life of your loved one; and, 2) to help your family and friends as they begin their healing journey.</p>
        
        <h1 class="mt-12">Create In 4 Easy Steps</h1>
        <ol class="m-0 text-xl">
          <li class="mb-2 pl-6">Select Booklet;</li>
          <li class="mb-2 pl-6">Create &amp; Approve Booklet;</li>
          <li class="mb-2 pl-6">Print; and</li>
          <li class="mb-2 pl-6">Deliver</li>
        </ol>
        
        <h3 class="mt-12 mb-6 underline">Select A Booklet</h3>
        <p>To begin, click '<router-link to="/funeral-booklets">Get Started</router-link>.' Review the booklet samples to choose your booklet preference. If you have difficulty deciding, simply click '<router-link to="/funeral-booklet-options">Can’t Decide? Compare for Yourself</router-link>.' The Decedent’s Profile page initially instructs you to select a booklet style; i.e., Traditional Funeral Mass, Cremation Mass, or Memorial Mass. After you complete the required fields, but before you click 'Continue' please note the Helpful Hints provided at the bottom of the page that lists the necessary items needed as you create your funeral mass booklet.</p>

        <h3 class="mt-12 mb-6 underline">Create A Booklet</h3>
        <p>The Creation Wizard is designed to patiently guide you through page by page with proper instruction as you proceed. As the required fields are completed, your booklet will display the sentiment of your love and allow you to visually capture the spirit of your loved one’s life.</p>
        <p>This Celebration of Life tribute, a treasured keepsake, dually designed to say “goodbye” to the physical presence of your beloved; and to be shared with family and friends as they begin their healing process is your final opportunity to solidify your relationship expressed through meaningful scripture readings, photos, poems, quotes and prayers.</p>

        <h3 class="mt-12 mb-6 underline">Approve</h3>
        <p>Now that you have finished creating, we request that you thoroughly review your booklet in its entirety. The Funeral Booklet Wizard will take you through page by page, we highly recommend that you take this opportunity to check for spelling accuracy, date accuracy, correct color theme, correct booklet style, add, change or eliminate photos, poems, prayers, quotes, etc.</p>
        <p>To make changes, simply click on the highlighted text or image to be edited, make your corrections, save, and, proceed to the end. Feel free to make as many changes as necessary.</p>
        <p>Upon completion of your final review and edits, the booklet is ready for APPROVAL. Once you click “APPROVE” your booklet, is eligible for conversion to a print-ready PDF file and your masterpiece is qualified to print.</p>

        <h3 class="mt-12 mb-6 underline">Print &amp; Deliver</h3>
        <p>Now that you have creatively designed, diligently reviewed, and voluntarily approved your mass booklet, why not utilize our in-house Print services to accentuate your masterpiece and take advantage of the print incentives?</p>
        <p>We confidently share our opinion that 'choosing a premium stock to compliment your lovingly designed booklet is equivalent to making a lasting first impression.' All in attendance will be captured with the spirit of your loved as the luxurious textures flatter its design, lend personality, and facilitate outstanding print quality.</p>      
        <p>ALL Print Orders receive 50% off the PDF retail price and FREE next day shipping and handling through a nationwide service. All print orders are immediately processed and you will receive a shipping confirmation email from UPS with a Tracking Number that guarantees next day delivery to the designated location by 10:30 a.m.</p>
      </div>
    </div>
  </div></template>

<script>
export default {
    
};
</script>

<style>
</style>