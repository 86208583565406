import * as api from '../../client/api'

const booklets = {
    state: {
        activeBooklet: null,
        bookletData: null
    },

    getters: {
        activeBooklet: state => {
            return state.activeBooklet && state.activeBooklet.id ?  state.activeBooklet : null
        },

        bookletData: state => state.bookletData
    },
    
    mutations: {
        setActiveBooklet(state, booklet) {
            state.activeBooklet = booklet;
        },

        STORE_BOOKLET_DATA(state, bookletData) {
            state.bookletData = bookletData;
        },

        DELETE_BOOKLET_DATA(state) {
            state.bookletData = null;
        }
    },
    
    actions: {
        storeBookletData({ commit, dispatch }, bookletData) {
            commit('STORE_BOOKLET_DATA', bookletData)
        },

        deleteBookletData({ commit, dispatch }) {
            commit('DELETE_BOOKLET_DATA')
        }
    }
}

export default booklets