import uuid from 'uuid'

const sessionIdCookieName = 'mwb-session';
const versionCookieName = 'mwb-version';
const accessTokenCookieName = 'mwb-token';

const initializeSessionStorage = function(store){
  // let sessionId = getCookie(sessionIdCookieName);
  // let accessToken = getCookieAccessToken();
  // let isNewSession = !sessionId && !accessToken;

  // if(isNewSession){
  //   store.commit('resetState');
  // }

  // if(!sessionId){
  //   sessionId = uuid();
  //   setCookie(sessionIdCookieName, sessionId);
  // }
}

const getCookieAccessToken = function () {
  return getCookie(accessTokenCookieName);
};

const setCookieAccessToken = function (token, persist) {
  let duration = persist === true ? 5.256e+6 : null;

  setCookie(accessTokenCookieName, token, duration);
};

const deleteCookieAccessToken = function () {
  deleteCookie(accessTokenCookieName);
};

const getCookieVersion = function () {
  return getCookie(versionCookieName);
};

const setCookieVersion = function (version, durationInMinutes) {
  setCookie(versionCookieName, version, durationInMinutes);
};

const getCookie = function (name) {
  let found = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
  return found ? found.pop() : '';
};

const setCookie = function (name, value, durationInMinutes = null) {
  let cookie = `${name}=${value}`;

  if (durationInMinutes) {
    let now = new Date();
    now.setTime(now.getTime() + (durationInMinutes * 60 * 1000));

    cookie += `; expires=${now.toUTCString()}`;
  }

  cookie += '; path=/';

  document.cookie = cookie;
};

const deleteCookie = function(name) {
  let expires = new Date('0001-01-01T00:00:00Z').toUTCString();

  document.cookie = `${name}=; expires=${expires}; path=/`;
};

export default {
  initializeSessionStorage,
  getCookieVersion,
  setCookieVersion,
  getCookieAccessToken,
  setCookieAccessToken,
  deleteCookieAccessToken
};