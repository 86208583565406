<template>
  <div>
    <label v-html="label"></label>
    <span>
      <show-modal-button modal-class="max-w-md" @close="closeModal"
                         :class="[hymnLoading ? 'disabled' : (hymn ? 'text-melon text-left' : 'btn text-center'), 'ml-2']"
      >
        <div slot="button-content" class="hymn-button align-middle truncate">
          {{ hymn ? hymnDisplay(hymn) : (hymnLoading ? (loaded ? value : 'Loading...') : `Select ${this.label}`) }}
        </div>
        <div slot="modal-content">
          <h1 v-html="label" class="font-normal text-xl text-left mb-2 sm:mt-0 mt-2"></h1>
          <div v-if="instruction" v-html="instruction" class="text-sm text-left px-2 mb-6"></div>
          <div class="pl-2 mb-4">
            <input v-model="userHymn" class="w-full" />
          </div>
          <div v-if="hymns" class="hymns-container scrollable-container overflow-y-auto p-2">
            <div v-for="hymn in hymns" v-bind:key="hymn.id" @click="selectHymn(hymn)" :class="['my-1', 'p-1', 'cursor-pointer', { selected: selectedHymn && selectedHymn.id === hymn.id }]">
              {{ hymn.title }}
            </div>
          </div>
          <div v-if="hymns && hymns.length === 0" class="p-2">
            {{ loaded? 'No hymns to select' : 'Loading...' }}
          </div>

          <div class="text-right mt-4">
            <span class="selected-hymn inline-block float-left p-2 truncate">Selected: {{ selectedHymn ? selectedHymn.title : (userHymn ? userHymn : 'none') }}</span>
            <button type="button" class="btn-light" data-modal-action="cancel">Cancel</button>
            <button type="button" class="btn ml-4" data-modal-action="ok">Save</button>
          </div>
        </div>
      </show-modal-button>
    </span>
  </div>
</template>

<script>
  import * as api from '../../client/api.js'
  import ShowModalButton from '../ShowModalButton'

  export default {
    name: 'HymnInput',
    props: ['label', 'name', 'value', 'section', 'instruction'],
    components: { ShowModalButton },
    data() {
      return {
        hymn: null,
        selectedHymn: null,
        hymns: [],
        userHymn: null,
        loaded: false
      }
    },
    computed: {
      hymnLoading() {
        return this.value && !this.hymn;
      },
      sectionHymns() {
        return this.$store.getters.hymns && this.$store.getters.hymns.filter(x => x.sectionId === this.section);
      }
    },
    created() {
      let self = this;
      self.getHymns(() => self.init());
    },
    watch: {
      userHymn() {
        if (this.userHymn) {
          this.selectedHymn = null;
          this.hymn = { title: this.userHymn }
        }
      }
    },
    methods: {
      init() {
        if (this.value) {
          let id = this.value.id || this.value.Id;
          let title = this.value.title || this.value.Title;

          if (!id && title) {
           this.userHymn = title;
          }
          else {
            this.userHymn = null;
            this.hymn = this.selectedHymn = this.hymns.find(x => x.id === this.value.id || x.id === this.value.Id);
          }
        }
      },
      selectHymn(hymn) {
        this.userHymn = null;
        this.selectedHymn = this.selectedHymn && this.selectedHymn.id === hymn.id ? null : hymn;
      },
      hymnDisplay(hymn) {
        return hymn.title || (hymn.Title || hymn.id);
      },
      getHymns(callback) {
        let self = this;
        self.hymns = this.sectionHymns;

        if (self.hymns != null) {
          self.loaded = true;

          if (callback) {
            callback();
          }
        }
        else {
          api.ReferencesGet().then(response => {
            if (response && response.data) {
              self.$store.dispatch('storeReferences', response.data).then(() => {
                self.hymns = this.sectionHymns;
                self.loaded = true;

                if (callback) {
                  callback();
                }
              });
            }
          }).catch(e => this.handleApiError(e));
        }
      },
      closeModal(result) {
        if (result === 'ok') {
          this.hymn = this.selectedHymn ? this.selectedHymn : (this.userHymn ? { title: this.userHymn } : null);
          this.$emit('input', this.hymn);
        }
        else if (result === 'cancel') {
          this.init();
        }
      }
    }
  }
</script>

<style scoped>
  .hymn-button {
    max-width: 17rem;
  }

  .disabled {
    color: #22292f;
    font-weight: 300;
    pointer-events: none;
  }

  .hymns-container {
    max-height: 50vh;
  }

  .selected {
    outline: 2px solid #de751f;
  }

  .selected-hymn {
    max-width: 23rem;
  }
</style>