<template>
  <div>
    <h1 class="text-xl md:text-2xl text-navy">{{ formData.header }}</h1>
    <div v-html="formData.desc" class="text-sm -mt-3 mb-3"></div>
     
    <div class="flex">
      <div class="m-auto md:ml-3">
        <button @click="prevImage"><arrow-left class="h-4 md:h-8"></arrow-left></button>
      </div>

      <!-- <div class="carousel-container">
        <div class="carousel-slider">
          <img  v-for="image in formData.images" :src="`${image.url}`" :key="image.id"/>
        </div>
      </div> -->

      <div v-if="templateName === 'FourPageFuneralBooklet'" class="carousel-container">
        <div class="carousel-slider">
          <img src="../assets/images/samples/fourpage/page1.jpg"/>
          <img src="../assets/images/samples/fourpage/page2-3.jpg"/>
          <img src="../assets/images/samples/fourpage/page4.jpg"/>
        </div>
      </div>

      <div v-if="templateName === 'EightPageFuneralBooklet'" class="carousel-container">
        <div class="carousel-slider">
          <img src="../assets/images/samples/eightpage/page1.jpg"/>
          <img src="../assets/images/samples/eightpage/page2-3.jpg"/>
          <img src="../assets/images/samples/eightpage/page4-5.jpg"/>
          <img src="../assets/images/samples/eightpage/page6-7.jpg"/>
          <img src="../assets/images/samples/eightpage/page8.jpg"/>
        </div>
      </div>  

      <div v-if="templateName === 'EightPageFullFuneralBooklet'" class="carousel-container">
        <div class="carousel-slider">
          <img src="../assets/images/samples/eightfullpage/page1.jpg"/>
          <img src="../assets/images/samples/eightfullpage/page2-3.jpg"/>
          <img src="../assets/images/samples/eightfullpage/page4-5.jpg"/>
          <img src="../assets/images/samples/eightfullpage/page6-7.jpg"/>
          <img src="../assets/images/samples/eightfullpage/page8.jpg"/>
        </div>
      </div>

      <div v-if="templateName === 'TwelvePageFuneralBooklet'" class="carousel-container">
        <div class="carousel-slider">
          <img src="../assets/images/samples/twelvepage/page1.jpg"/>
          <img src="../assets/images/samples/twelvepage/page2-3.jpg"/>
          <img src="../assets/images/samples/twelvepage/page4-5.jpg"/>
          <img src="../assets/images/samples/twelvepage/page6-7.jpg"/>
          <img src="../assets/images/samples/twelvepage/page8-9.jpg"/>
          <img src="../assets/images/samples/twelvepage/page10.jpg"/>
        </div>
      </div>  

      <div class="m-auto md:mr-3">
        <button @click="nextImage"><arrow-right class="h-4 md:h-8"></arrow-right></button>
      </div>
    </div>
    <div class="mt-8 pt-6 text-right border-t border-grey-light">
        <button type="button" class="btn" data-modal-action="cancel">Close</button>                
    </div>
  </div>
</template>

<script>
  import ArrowLeft from '../components/svg/ArrowLeft.svg'
  import ArrowRight from '../components/svg/ArrowRight.svg'

  const fourPage = {
      header: "Sample Booklet : 4 page - scripture titles",
      desc: "This booklet is designed with only the scripture titles listed. <i>The front cover is available for photos. The back cover is available for photos, poems, prayers or quotes. We recommend using a premium paper stock for a superior finish.</i>",
      images: [
          { "id": 0, "url": "@/assets/images/samples/fourpage/page1.jpg" },
          { "id": 1, "url": "@/assets/images/samples/fourpage/page2-3.jpg" },
          { "id": 2, "url": "@/assets/images/samples/fourpage/page4.jpg" }
      ]
  };

  const eightPage = {
      header: "Sample Booklet : 8 page - scripture titles",
      desc: "This booklet is one of our best sellers. <i>Listing scripture titles only which provides extra space for photos, poems, prayers, quotes and/or an expression of gratitude. It is saddle stitched (stapled through the fold line).</i>",
      images: [
          { "id": 0, "url": "@/assets/images/samples/eightpage/page1.jpg" },
          { "id": 1, "url": "@/assets/images/samples/eightpage/page2-3.jpg" },
          { "id": 2, "url": "@./assets/images/samples/eightpage/page4-5.jpg" },
          { "id": 3, "url": "@/assets/images/samples/eightpage/page6-7.jpg" },
          { "id": 4, "url": "@/assets/images/samples/eightpage/page8.jpg" }
      ]
  };
  
  const eightpagefull = {
      header: "Sample Booklet : 8 page - full scripture",
      desc: "This booklet is another one of our best sellers. <i>The full scripture readings are included with additional pages for photos, poems, prayers, quotes, and/or an expression of gratitude. It is saddle stitched (stapled through the fold line).</i>",
      images: [
          { "id": 0, "url": "@/assets/images/samples/eightfullpage/page1.jpg" },
          { "id": 1, "url": "@/assets/images/samples/eightfullpage/page2-3.jpg" },
          { "id": 2, "url": "@/assets/images/samples/eightfullpage/page4-5.jpg" },
          { "id": 3, "url": "@/assets/images/samples/eightfullpage/page6-7.jpg" },
          { "id": 4, "url": "@/assets/images/samples/eightfullpage/page8.jpg" }
      ]
  };

   const twelvepage = {
      header: "Sample Booklet : 12 page - full scripture",
      desc: "This booklet is an extended version of the 8 Page Full Scripture. <i>The full scripture readings are included with plenty of additional pages available for photos, poems, prayers, quotes, and/or an expression of gratitude. It is saddle stitched (stapled through the fold line).</i>",
      images: [
          { "id": 0, "url": "@/assets/images/samples/twelvepage/page1.jpg" },
          { "id": 1, "url": "@/assets/images/samples/twelvepage/page2-3.jpg" },
          { "id": 2, "url": "@/assets/images/samples/twelvepage/page4-5.jpg" },
          { "id": 3, "url": "@/assets/images/samples/twelvepage/page6-7.jpg" },
          { "id": 4, "url": "@/assets/images/samples/twelvepage/page8-9.jpg" },
          { "id": 5, "url": "@/assets/images/samples/twelvepage/page10.jpg" }
      ]
  };

  export default {
    name: 'SampleGenerator',
    props: [ 'templateName' ],
    components: { ArrowLeft, ArrowRight },
    data() {
        return {
          formData: {},
          counter: 0
        }
    },
    created() {
      if (this.templateName && this.templateName.toLowerCase().startsWith("four")) this.formData = fourPage;  
      if (this.templateName && this.templateName.toLowerCase().startsWith("eight")) this.formData = eightPage;      
      if (this.templateName && this.templateName.toLowerCase().startsWith("eightpagefull")) this.formData = eightpagefull;
      if (this.templateName && this.templateName.toLowerCase().startsWith("twelve")) this.formData = twelvepage; 
    },
    methods: {
      nextImage() {
        let self = this; 

        if (self.counter == this.carouselImages.length - 1) self.counter = -1;
        
        this.carouselSlide.style.transition = 'transform 0.4s ease-in-out';
        self.counter++;
        this.carouselSlide.style.transform = 'translateX(' + (-this.size * this.counter) + 'px)';
      },
      prevImage() {
        let self = this; 

        if (self.counter <= 0) self.counter = this.carouselImages.length;
        
        this.carouselSlide.style.transition = 'transform 0.4s ease-in-out';
        self.counter--;
        this.carouselSlide.style.transform = 'translateX(' + (-this.size * this.counter) + 'px)';
      }
    },
    computed: {
      carouselSlide() {
        return this.$el.querySelector('.carousel-slider');
      },
      carouselImages() {
        return this.$el.querySelectorAll('.carousel-slider img');
      },
      size() {
        return this.carouselImages[0].clientWidth;
      }  
    }

  }
</script>

<style scoped>
  button:focus { 
    outline: none; 
  }

  .carousel-container {
      @apply shadow-lg;
      @apply m-auto; 
      @apply overflow-hidden;
      width: 80%;
  }

  .carousel-slider {
      @apply flex;
      @apply w-full;
      @apply shadow-lg;
      height: 380px;
  }

  @media (min-width: 360px) {
    .carousel-slider {
      height: 140px;      
    }
  }

  @media (min-width: 768px) {
    .carousel-slider {
      height: 375px;      
    }
  }
 
</style>