const createDataModel = function(schema, formData) {
  let dataModel = {};

  for (let property in formData) {
    let value = formData[property];

    if (value !== null && typeof(value) === 'object') {
      let innerValue = {};

      for (let key in value) {
        let innerKey = key.charAt(0).toUpperCase() + key.slice(1);
        innerValue[innerKey] = value[key];
      }
      dataModel[property] = innerValue;
    }
    else {
      dataModel[property] = value;
    }
  }

  return dataModel;
}

const createTemplateModel = function (template) {
  return template.replace(/(\{\{)\s+/g, '$1').replace(/\s+(\}\})/g, '$1');
}

export {
  createDataModel,
  createTemplateModel
}