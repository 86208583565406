<template>
  <div>
    <label v-html="label"></label>
    <input v-if="!multi"
           type="text"
           :name="name"
           :value="value"
           @input="$emit('input',$event.target.value)"
           :placeholder="placeholder" />

    <show-modal-button v-if="multi" :class="[{ btn: !value }, 'align-middle', 'ml-2']" @close="closeModal">
      <div slot="button-content" :class="[value? 'text-melon content-link text-left' : '', 'truncate']">
        {{ value || 'Enter Text' }}
      </div>
      <div slot="modal-content">
        <h1 v-html="label" class="font-normal text-xl text-left mb-2 sm:mt-0 mt-2"></h1>
        <div v-if="instruction" v-html="instruction" class="text-sm text-left px-2 mb-6"></div>
        <div class="mb-6">
          <textarea rows="10" class="w-full font-thin" v-model="text"></textarea>
        </div>
        <div class="text-right">
          <button type="button" class="btn-light ml-3" data-modal-action="cancel">
            Cancel
          </button>
          <button type="button" class="btn ml-3" data-modal-action="ok">
            OK
          </button>
        </div>
      </div>
    </show-modal-button>
  </div>
</template>

<script>
  import ShowModalButton from '../ShowModalButton'

  export default {
    name: 'TextInput',
    props: ['multi', 'placeholder', 'label', 'name', 'value', 'instruction'],
    components: { ShowModalButton },
    data() {
      return {
        text: this.value
      }
    },
    watch: {
      value: function(val) {
        this.text = val;
      }
    },
    methods: {
      closeModal(result) {
        if (result === 'ok') {
          this.$emit('input', this.text);
        }
        else if (result === 'cancel') {
          this.text = this.$props.value;
        }
      }
    }
  }
</script>

<style scoped>
  .content-link {
    max-width: 17rem;
  }
</style>