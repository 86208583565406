<template>
  <div v-if="loaded">
    <h2 class="mb-6">Funeral Booklet Approval</h2>
    <div :class="['booklet-preview', { 'opacity-0': (!leftLoaded || !rightLoaded) }]" ref="bookletPreview" class="mr-4">
      <booklet-viewer
          ref="bookletViewerLeft"
          :clickable="true"
          @click="previewClicked"
          :pageNumber="leftPageNumber"
          :model="model"
          :template="template"
          @load="leftLoaded = true"
          class="border border-grey rounded inline-block">
      </booklet-viewer>
      <booklet-viewer
          ref="bookletViewerRight"
          :clickable="true"
          @click="previewClicked"
          :pageNumber="rightPageNumber"
          :model="model"
          :template="template"
          @load="rightLoaded = true"
          class="border border-grey rounded inline-block ml-1">
      </booklet-viewer>
      <div class="flex mt-4">
        <div class="flex-1"><button type="button" @click="previousPage" v-if="leftPageNumber" class="btn mr-1 text-sm md:text-md md:ml-20">Previous Page</button></div>
        <div class="flex-1"><button type="button" v-if="reviewed" @click="approve" class="btn text-sm md:text-md ">Approve</button></div>
        <div class="flex-1"><button type="button" @click="nextPage" v-if="rightPageNumber" class="btn ml-1 text-sm md:text-md md:mr-20">Next Page</button></div>
      </div>
    </div>
    <div class="error-summary my-4"></div>
  </div>
</template>

<script>
  import FormGenerator from '../components/FormGenerator'
  import BookletViewer from '../components/BookletViewer'
  import * as api from '../client/api.js'
  import * as bookletModels from '../tools/bookletModels'
  import { BookletStatus } from '../models/BookletStatus'

  export default {
    name: 'BookletApproval',
    components: { FormGenerator, BookletViewer },
    props: ['pages'],
    data() {
      return {
        id: null,
        formData: {},
        schema: null,
        template: null,
        loaded: false,
        leftPageNumber: this.pages ? this.pages[0] : 0,
        rightPageNumber: this.pages ? this.pages[1] : 1,
        bookletPagesCount: 0,
        bookletViewedPages: [],
        reviewed: false,
        leftLoaded: false,
        rightLoaded: false
      }
    },
    created() {
      let self = this;

      api.BookletsByIdGet({ id: self.$route.params.id })
        .then(response => {
          if (response && response.data) {
            let booklet = response.data;
            self.id = booklet.id;
            self.formData = JSON.parse(booklet.modelJson);
            self.template = bookletModels.createTemplateModel(booklet.template);
            self.schema = JSON.parse(booklet.schemaJson);
            self.reviewed = booklet.status >= BookletStatus.Reviewed;
            self.bookletPagesCount = self.getBookletPagesCount();
            self.updateBookletViewedPages();
            self.loaded = true;
          }
        }).catch(e => {
          self.processErrorMessages(e);
          self.handleApiError(e);
      });
    },
    computed: {
      model() {
        return bookletModels.createDataModel(this.schema, this.formData);
      }
    },
    methods: {
      previousPage() {
        this.leftPageNumber -= 2;
        this.rightPageNumber = this.rightPageNumber === 0 ? this.leftPageNumber + 1 : this.rightPageNumber -= 2;
        this.updateBookletViewedPages();
      },
      nextPage() {
        this.leftPageNumber += 2;
        this.rightPageNumber = this.rightPageNumber + 2 >= this.bookletPagesCount ? 0 : this.rightPageNumber += 2;
        this.updateBookletViewedPages();
      },
      getBookletPagesCount() {
        let container = window.document.createElement('div');
        container.innerHTML = this.template;
        let pages = container.getElementsByClassName('page');

        return pages.length;
      },
      previewClicked(field, event) {
        let wizardPage = this.schema.pages.find(x => x.fields.find(f => f.name === field));
        if (wizardPage) {
          this.$router.push({ name: 'booklet', params: { page: wizardPage.number, approvalPages: [this.leftPageNumber, this.rightPageNumber] }})
        }
      },
      updateBookletViewedPages() {
        if (!this.reviewed) {
          this.bookletViewedPages = this.bookletViewedPages.concat([this.leftPageNumber, this.rightPageNumber]).filter((element, index, array) => {
            return element !== 0 && array.indexOf(element) === index;
          });

          if (this.bookletViewedPages.length === this.bookletPagesCount) {
            this.reviewed = true;
            api.BookletsByIdStatusPut({id: this.id, args: {status: BookletStatus.Reviewed}});
          }
        }
      },
      approve() {
        let self = this;

        api.BookletsByIdStatusPut({id: self.id, args: {status: BookletStatus.Approved}}).then(response => {
          self.$router.push({ name: 'print-options', params: { id: self.id }});
        }).catch(e => {
          self.processErrorMessages(e);
          self.handleApiError(e);
        });
      }
    }
  }
</script>

<style>
  @media only screen
  and (min-device-width : 375px)
  and (max-device-width : 667px) {

    .booklet-preview {
      @apply block;
    }
  }
</style>
