<template>
  <button type="button" :disabled="processing" @click="started" ref="button">
    <slot v-if="!processing" name="content">Browse...</slot>
    <slot v-if="processing" name="processingContent">Uploading...</slot>
    <input type="file" class="hidden" @change="upload" ref="input" accept="image/*">
  </button>
</template>

<script>
  import axios from 'axios'
  import url from 'url'

  export default {
    name: 'FileUploadButton',
    props: ['url', 'fields', 'cloudinary'],
    data() {
      return {
        processing: false
      }
    },
    methods: {
      started() {
        this.$refs.input.click();
      },
      upload(event) {
        if (!event.target.files) {
          return;
        }

        let self = this;
        let file = event.target.files[0];
        let reader = new FileReader();

        reader.onload = e => {
          let arrayBuffer = e.target.result;

          let base64Bytes = btoa(
            new Uint8Array(arrayBuffer)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );

          let data = {
            bytes: base64Bytes,
            fullName: file.name,
            name: file.name.split('.').slice(0, -1).join('.'),
            extension: file.name.split('.').pop(),
            cloudinary: this.cloudinary
          };

          if (this.fields) {
            data = Object.assign(this.fields, data);
          }

          this.processing = true;

          let postUrl = url.resolve(this.$config.webApiRootUrl, this.url);

          axios.post(postUrl, data).then(x => {
            this.processing = false;
            self.$emit('completed', { id: x.data, name: data.name });
          }).catch(e => {
            this.processing = false;
            self.$emit('completed', 'An error occurred');
            self.handleApiError(e);
          });
        };

        reader.readAsArrayBuffer(file);
      }
    }
  }
</script>