<template>
  <div>
    <div
      id="UserProfile"
      @click.prevent="toggleSlider"
      v-on-clickaway="hideSlider"
      v-on:click.native="toggleSlider"
    >
      <button type="button" class="cursor-pointer outline-none" title="User Profile">
        <User class="h-7 mt-1 text-white"></User>
      </button>
    </div>
    <transition name="slide">
      <div v-if="isOpen" class="sidebar-panel">
        <div class="sidebar-menu pt-12" style="height: 40%">
          <div class="block">
            {{ userDisplayName }}
            <span
              class="italic lowercase font-thin pl-2"
            >{{ currentUser.emailAddress }}</span>
          </div>
          <router-link to="/profile">My Account</router-link>
          <router-link to="/my-booklets">My Booklets</router-link>
          <div class="border-t border-cream w-1/2">&nbsp;</div>
          <router-link @click.native="signOut" to="/" class="-mt-6">Sign Out</router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import User from "../components/svg/icon/User.svg";

import { mapGetters } from "vuex";
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "UserProfile",
  mixins: [clickaway],

  data: function() {
    return {
      isOpen: false
    };
  },
  computed: mapGetters([
    "signedIn",
    "userDisplayName",
    "messages",
    "currentUser"
  ]),
  methods: {
    toggleSlider() {
      this.isOpen = !this.isOpen;
    },
    hideSlider() {
      this.isOpen = false;
    },
    signOut() {
      this.$store.dispatch("signOut").then(response => {
        this.$router.push("/");
      });
    }
  },
  components: { User }
};
</script>

<style scoped>
@import "../assets/css/SliderMenu.css";
</style>