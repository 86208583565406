import '@/assets/css/tailwind.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import configService from './services/ConfigService'
import storageService from './services/StorageService'
import ValidationService from './services/ValidationService'
import * as api from './client/api.js'
import axios from 'axios'
import LoggingService from './services/LoggingService'
import store from './store/'
import './filters/BookletDate'
import VueAnalytics from 'vue-analytics'
import VueGtag from "vue-gtag";
import TrustpilotPlugin from "vue-trustpilot";
import VueSlideBar from 'vue-slide-bar';


configService.getConfig(config => {
  Vue.config.productionTip = false;

  api.setDomain(config.webApiRootUrl);
  store.dispatch('authInit');
  
  storageService.initializeSessionStorage(store);

  if (config.logglyToken) {
    Vue.prototype.$logger = new LoggingService(config.logglyToken, config.application, config.environmentName, config.version);
  }

  Vue.mixin({
    methods: {
      processErrorMessages(error) {
        ValidationService.processErrorMessages(this, error);
      },
      handleApiError(error) {
        if (error.statusCode !== 400 && error.statusCode !== 401) {
          throw error;
        }
      }
    },
    beforeRouteEnter (to, from, next) {
      next(x => {
        if (x.$root.$logger) {
          x.$root.$logger.logRoute(to);
        }
      })
    }
  });
  
  axios.defaults.headers.common['MWB-WebsiteVersion'] = config.version;

  storageService.setCookieVersion(config.version, config.versionCookieDurationInMinutes);

  if (config.googleAnalyticsTrackingId) {
    Vue.use(VueAnalytics, {
      id: config.googleAnalyticsTrackingId,
      router
    })
  }

  if (config.googleAdsConversionId) {
    Vue.use(VueGtag, {
      config: { 
        id: config.googleAdsConversionId,
        params: {
          send_page_view: false
        } 
      }
    });    
  }

  const starterWidget = {
    templateId: config.trustPilot.templateId,
    businessunitId: config.trustPilot.businessUnitId,
    reviewUrl: config.trustPilot.reviewUrl,
    locale: config.trustPilot.locale
  };

  const options = {
    widgets: {
      starter: starterWidget
    }
  };

  Vue.use(TrustpilotPlugin, options);
  
  new Vue({
    router,
    config,
    store,
    render: h => h(App)
  }).$mount('#app');

  Vue.component('vue-slide-bar', VueSlideBar);

});