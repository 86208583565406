export default class ApiError extends Error {
  constructor(error) {
    let message = 'An error occurred';
    let status;
    let statusCode;
    let requestId;
    let errors;
    let messages = {};

    if (error && error.response)
    {
      if (error.response.data && error.response.data.message) {
        message = error.response.data.message;
        messages[''] = message;
      }
      else if (error.response.statusText) {
        message = error.response.statusText;
      }

      status = error.response.statusText;
      statusCode = error.response.status;

      if (error.response.data) {
        requestId = error.response.data.requestId;
        errors = error.response.data.errors;

        let commonMessages = [];

        errors.forEach(x => {
          if (x.property) {
            messages[x.property] = x.message;
          }
          else {
            commonMessages.push(`'${x.message}'`);
          }
        });

        if (commonMessages.length > 0) {
          messages[''] = `Errors occured: ${commonMessages.join(', ')}`;
        }
        else if (errors.length > 0) {
          messages[''] = '';
        }
      }
    }

    super(message);

    this.name = this.constructor.name;
    this.message = message;
    this.status = status;
    this.statusCode = statusCode;
    this.requestId = requestId;
    this.errors = errors;
    this.messages = messages;
  }
}