import ApiError from '../../models/ApiError'
import Router from '../../router'
import StorageService from '../../services/StorageService'
import axios from 'axios'
import * as api from '../../client/api'

const auth = {
    state: {
        currentUser: null
    },

    getters: {
        currentUser(state) {
            return state.currentUser
        },

        userDisplayName(state) {
            return !!state.currentUser ? 
                ((state.currentUser.firstName || '') + ' ' + (state.currentUser.lastName || '')).trim() || state.currentUser.emailAddress :
                ''
        },

        signedIn(state) {
            return !!state.currentUser
        }
    },
    
    mutations: {
        SET_CURRENT_USER (state, user) {
            state.currentUser = user
        },

        REMOVE_CURRENT_USER (state) {
            StorageService.deleteCookieAccessToken()
            state.currentUser = null
        }
    },
    
    actions: {
        authInit({ commit, dispatch }) {
            axios.interceptors.request.use(function(config) {
              const token = StorageService.getCookieAccessToken();

              if (token != null) {
                config.headers['MWB-Token'] = token;
              }

              return config;
            }, null);
            
            axios.interceptors.response.use(null, function(error) {
                let apiError = new ApiError(error);
        
                if (window.location.pathname !== '/sign-in' &&
                    window.location.pathname !== '/checkout' &&
                    apiError.statusCode === 401) {
                    dispatch('signOut');

                    let redirectRoute = { name: 'sign-in', params: { redirectUrl: window.location.pathname + window.location.search }};
                    
                    if (redirectRoute) {
                        Router.push(redirectRoute);
                    }
            
                    return Promise.resolve();
                }
        
                return Promise.reject(apiError);
            });

            const token = StorageService.getCookieAccessToken();

            if (token) {
                dispatch('validate')
            } else {
                commit('REMOVE_CURRENT_USER')
            }

            commit('DELETE_ALL_MESSAGES')
        },

        register({ commit, dispatch, getters }, { emailAddress, password, firstName, lastName } = {}) {
            return new Promise((resolve, reject) => {
                api.UsersPost({ args: { emailAddress: emailAddress, password: password, firstName: firstName, lastName : lastName }})
                    .then(userResponse => {
                        if(userResponse.data){
                            dispatch('signIn', { emailAddress, password })
                            .then(response => {resolve(response)})
                        }
                        else{
                            resolve(null)
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        signIn({ commit, dispatch, getters }, { emailAddress, password, persist = false } = {}) {
            return new Promise((resolve, reject) => {
                api.SessionsPost({ args: { emailAddress: emailAddress, password: password, persist: persist }})
                    .then(sessionResponse => {
                console.log(sessionResponse)
                        const token = sessionResponse.data

                        StorageService.setCookieAccessToken(token, persist)
                        
                        api.UsersMeGet()
                            .then(userResponse => {
                                const user = userResponse.data

                                commit('SET_CURRENT_USER', user)

                                resolve(user)
                            })
                    })
                    .catch(error => {
                        if (error.statusCode === 401) {
                            commit('REMOVE_CURRENT_USER')
                        }

                        reject(error)
                    })
            })
        },
        
        signOut({ commit }) {
            commit('setActiveBooklet', null)
            commit('REMOVE_CURRENT_USER')
        },

        validate({ commit, state }) {
            api.UsersMeGet()
                .then(userResponse => {
                    const user = userResponse.data
                    commit('SET_CURRENT_USER', user)
                })
                .catch(error => {
                    if (error.statusCode === 401) {
                        commit('REMOVE_CURRENT_USER')
                    }
                })
        }
    }
}

export default auth