<template>
  <div id="app" class="flex items-stretch flex-col min-h-full">
    <portal-target name="modals" multiple></portal-target>
    <app-header class="fixed w-full" />
    <main class="w-full flex-grow ">
      <router-view class="p-8 pt-24 md:pt-32 pb-12 mx-auto max-w-3xl text-center" />
    </main>
    <app-footer class="bg-blue border-t border-white flex-shrink" />
  </div>
</template>

<script>
  import Header from './views/Header'
  import Footer from './views/Footer'
  import Vue from 'vue'
  import PortalVue from 'portal-vue'

  Vue.use(PortalVue);

  export default {
    name: 'app',
    data () {
      return {
      }
    },
    components: {
      'app-header': Header,
      'app-footer': Footer
    }
  }
</script>