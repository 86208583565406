<template>
  <footer>
    <div class="mx-auto max-w-3xl">
      <div class="pb-8 text-center text-xs text-gray">
        <Social-Media class="pt-8 pb-2"></Social-Media>
        <div></div>&copy; My Way Booklets. All Rights Reserved. 
        <br class="sm:hidden" />
          <router-link class="text-grey-dark" to="/terms-of-service">Legal</router-link> 
        | <router-link class="text-grey-dark" to="/privacy-policy">Privacy</router-link>
        | v{{ this.$config.version }}
      </div>
    </div>
  </footer>
</template>

<script>
  import SocialMedia from '../components/SocialMedia'

  export default {
    name: 'Footer',
    components: { SocialMedia }
  }
</script>
