<template>
  <div>
    <h1>Print Options</h1>
    <div class="text-md md:text-lg mx-auto max-w-xl text-left">
      <p class="mb-8">
        NOW that you have creatively designed, diligently reviewed and voluntarily APPROVED your mass booklet, 
        why not print with MY WAY BOOKLETS' to accentuate your masterpiece? Our premium papers provide luxurious 
        textures which compliment designs, lend personality and facilitate outstanding print performance. 
      </p>
      <p>
        Believe it or not, the paper you choose is equivalent to making a first impression, although subconscious, 
        it’s the very first thing anyone notices about your booklet! Before a word is read or a page is turned, 
        everyone will acknowledge the texture and quality of the print and paper of your booklet.
      </p>
      <p>
        <i>Print discount incentives;</i> All print orders receive 50% off the PDF retail price plus for a limited time, 
        FREE shipping and handling. All Orders will be processed immediately, you will receive a shipping confirmation 
        from UPS with a Tracking Number and your Order will be delivered the next day by 10:30 a.m. to the designated location.
      </p>

      <PriceModel :printOptions="printOptions" :shippingAddress="shippingAddress" class="sm:mx-12"></PriceModel>     

      <hr class="invisible md:visible" >  
      <h3 class="mt-16 cursor-default">Premium Paper Offered By MY WAY BOOKLETS' : </h3>
      <PaperDefinition class="pt-8"></PaperDefinition>

    </div>
  </div>
</template>

<script>
  import PriceModel from "../components/PriceModel"
  import PaperDefinition from '../components/PaperDefinition'

  export default {
    name: "PrintOptions",
    props: ['printOptions', 'shippingAddress'],
  
    components: { PriceModel, PaperDefinition  },
  }
</script>

<style scoped>
  hr {
    @apply mt-10;
    @apply mb-8;
    @apply max-w-2xl;
    @apply border-0;
    @apply h-px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));  
  }
</style>