/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form
    }, config)
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    
 ==========================================================*/
/**
 * 
 * request: BookletsByIdPdfGet
 * url: BookletsByIdPdfGetURL
 * method: BookletsByIdPdfGet_TYPE
 * raw_url: BookletsByIdPdfGet_RAW_URL
 * @param id - 
 */
export const BookletsByIdPdfGet = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/booklets/{id}/pdf'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const BookletsByIdPdfGet_RAW_URL = function() {
  return '/booklets/{id}/pdf'
}
export const BookletsByIdPdfGet_TYPE = function() {
  return 'get'
}
export const BookletsByIdPdfGetURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/booklets/{id}/pdf'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: BookletsGet
 * url: BookletsGetURL
 * method: BookletsGet_TYPE
 * raw_url: BookletsGet_RAW_URL
 */
export const BookletsGet = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/booklets'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const BookletsGet_RAW_URL = function() {
  return '/booklets'
}
export const BookletsGet_TYPE = function() {
  return 'get'
}
export const BookletsGetURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/booklets'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: BookletsPut
 * url: BookletsPutURL
 * method: BookletsPut_TYPE
 * raw_url: BookletsPut_RAW_URL
 * @param args - 
 */
export const BookletsPut = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/booklets'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['args'] !== undefined) {
    body = parameters['args']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const BookletsPut_RAW_URL = function() {
  return '/booklets'
}
export const BookletsPut_TYPE = function() {
  return 'put'
}
export const BookletsPutURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/booklets'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: BookletsPost
 * url: BookletsPostURL
 * method: BookletsPost_TYPE
 * raw_url: BookletsPost_RAW_URL
 * @param args - 
 */
export const BookletsPost = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/booklets'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['args'] !== undefined) {
    body = parameters['args']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const BookletsPost_RAW_URL = function() {
  return '/booklets'
}
export const BookletsPost_TYPE = function() {
  return 'post'
}
export const BookletsPostURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/booklets'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: BookletsByIdGet
 * url: BookletsByIdGetURL
 * method: BookletsByIdGet_TYPE
 * raw_url: BookletsByIdGet_RAW_URL
 * @param id - 
 */
export const BookletsByIdGet = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/booklets/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const BookletsByIdGet_RAW_URL = function() {
  return '/booklets/{id}'
}
export const BookletsByIdGet_TYPE = function() {
  return 'get'
}
export const BookletsByIdGetURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/booklets/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: BookletsByIdClonePost
 * url: BookletsByIdClonePostURL
 * method: BookletsByIdClonePost_TYPE
 * raw_url: BookletsByIdClonePost_RAW_URL
 * @param id - 
 */
export const BookletsByIdClonePost = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/booklets/{id}/clone'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const BookletsByIdClonePost_RAW_URL = function() {
  return '/booklets/{id}/clone'
}
export const BookletsByIdClonePost_TYPE = function() {
  return 'post'
}
export const BookletsByIdClonePostURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/booklets/{id}/clone'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: BookletsByIdStatusPut
 * url: BookletsByIdStatusPutURL
 * method: BookletsByIdStatusPut_TYPE
 * raw_url: BookletsByIdStatusPut_RAW_URL
 * @param id - 
 * @param args - 
 */
export const BookletsByIdStatusPut = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/booklets/{id}/status'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['args'] !== undefined) {
    body = parameters['args']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const BookletsByIdStatusPut_RAW_URL = function() {
  return '/booklets/{id}/status'
}
export const BookletsByIdStatusPut_TYPE = function() {
  return 'put'
}
export const BookletsByIdStatusPutURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/booklets/{id}/status'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: ContactPost
 * url: ContactPostURL
 * method: ContactPost_TYPE
 * raw_url: ContactPost_RAW_URL
 * @param args - 
 */
export const ContactPost = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/contact'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['args'] !== undefined) {
    body = parameters['args']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const ContactPost_RAW_URL = function() {
  return '/contact'
}
export const ContactPost_TYPE = function() {
  return 'post'
}
export const ContactPostURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/contact'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: Image_categoriesGet
 * url: Image_categoriesGetURL
 * method: Image_categoriesGet_TYPE
 * raw_url: Image_categoriesGet_RAW_URL
 */
export const Image_categoriesGet = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/image-categories'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const Image_categoriesGet_RAW_URL = function() {
  return '/image-categories'
}
export const Image_categoriesGet_TYPE = function() {
  return 'get'
}
export const Image_categoriesGetURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/image-categories'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: Image_categoriesPost
 * url: Image_categoriesPostURL
 * method: Image_categoriesPost_TYPE
 * raw_url: Image_categoriesPost_RAW_URL
 * @param args - 
 */
export const Image_categoriesPost = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/image-categories'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['args'] !== undefined) {
    body = parameters['args']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const Image_categoriesPost_RAW_URL = function() {
  return '/image-categories'
}
export const Image_categoriesPost_TYPE = function() {
  return 'post'
}
export const Image_categoriesPostURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/image-categories'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: ImagesGet
 * url: ImagesGetURL
 * method: ImagesGet_TYPE
 * raw_url: ImagesGet_RAW_URL
 * @param categoryIds - 
 */
export const ImagesGet = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/images'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['categoryIds'] !== undefined) {
    queryParameters['CategoryIds'] = parameters['categoryIds']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const ImagesGet_RAW_URL = function() {
  return '/images'
}
export const ImagesGet_TYPE = function() {
  return 'get'
}
export const ImagesGetURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/images'
  if (parameters['categoryIds'] !== undefined) {
    queryParameters['CategoryIds'] = parameters['categoryIds']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: ImagesPost
 * url: ImagesPostURL
 * method: ImagesPost_TYPE
 * raw_url: ImagesPost_RAW_URL
 * @param args - 
 */
export const ImagesPost = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/images'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['args'] !== undefined) {
    body = parameters['args']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  console.log(domain + path)
  return request('post', domain + path, body, queryParameters, form, config)
}
export const ImagesPost_RAW_URL = function() {
  return '/images'
}
export const ImagesPost_TYPE = function() {
  return 'post'
}
export const ImagesPostURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/images'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: OrdersGet
 * url: OrdersGetURL
 * method: OrdersGet_TYPE
 * raw_url: OrdersGet_RAW_URL
 */
export const OrdersGet = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/orders'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const OrdersGet_RAW_URL = function() {
  return '/orders'
}
export const OrdersGet_TYPE = function() {
  return 'get'
}
export const OrdersGetURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/orders'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: OrdersPost
 * url: OrdersPostURL
 * method: OrdersPost_TYPE
 * raw_url: OrdersPost_RAW_URL
 * @param args - 
 */
export const OrdersPost = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/orders'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['args'] !== undefined) {
    body = parameters['args']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const OrdersPost_RAW_URL = function() {
  return '/orders'
}
export const OrdersPost_TYPE = function() {
  return 'post'
}
export const OrdersPostURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/orders'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: PricingGet
 * url: PricingGetURL
 * method: PricingGet_TYPE
 * raw_url: PricingGet_RAW_URL
 * @param bookletTypeName - 
 * @param coverPaperId - 
 * @param useCoverPaperForInterior - 
 * @param quantity - 
 */
export const PricingGet = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/pricing'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['bookletTypeName'] !== undefined) {
    queryParameters['BookletTypeName'] = parameters['bookletTypeName']
  }
  if (parameters['coverPaperId'] !== undefined) {
    queryParameters['CoverPaperId'] = parameters['coverPaperId']
  }
  if (parameters['useCoverPaperForInterior'] !== undefined) {
    queryParameters['UseCoverPaperForInterior'] = parameters['useCoverPaperForInterior']
  }
  if (parameters['quantity'] !== undefined) {
    queryParameters['Quantity'] = parameters['quantity']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const PricingGet_RAW_URL = function() {
  return '/pricing'
}
export const PricingGet_TYPE = function() {
  return 'get'
}
export const PricingGetURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/pricing'
  if (parameters['bookletTypeName'] !== undefined) {
    queryParameters['BookletTypeName'] = parameters['bookletTypeName']
  }
  if (parameters['coverPaperId'] !== undefined) {
    queryParameters['CoverPaperId'] = parameters['coverPaperId']
  }
  if (parameters['useCoverPaperForInterior'] !== undefined) {
    queryParameters['UseCoverPaperForInterior'] = parameters['useCoverPaperForInterior']
  }
  if (parameters['quantity'] !== undefined) {
    queryParameters['Quantity'] = parameters['quantity']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: ReferencesGet
 * url: ReferencesGetURL
 * method: ReferencesGet_TYPE
 * raw_url: ReferencesGet_RAW_URL
 */
export const ReferencesGet = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/references'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const ReferencesGet_RAW_URL = function() {
  return '/references'
}
export const ReferencesGet_TYPE = function() {
  return 'get'
}
export const ReferencesGetURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/references'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: SessionsPost
 * url: SessionsPostURL
 * method: SessionsPost_TYPE
 * raw_url: SessionsPost_RAW_URL
 * @param args - 
 */
export const SessionsPost = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/Sessions'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['args'] !== undefined) {
    body = parameters['args']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const SessionsPost_RAW_URL = function() {
  return '/Sessions'
}
export const SessionsPost_TYPE = function() {
  return 'post'
}
export const SessionsPostURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/Sessions'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: ThemesGet
 * url: ThemesGetURL
 * method: ThemesGet_TYPE
 * raw_url: ThemesGet_RAW_URL
 */
export const ThemesGet = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/themes'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const ThemesGet_RAW_URL = function() {
  return '/themes'
}
export const ThemesGet_TYPE = function() {
  return 'get'
}
export const ThemesGetURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/themes'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: ThemesByIdGet
 * url: ThemesByIdGetURL
 * method: ThemesByIdGet_TYPE
 * raw_url: ThemesByIdGet_RAW_URL
 * @param id - 
 */
export const ThemesByIdGet = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/themes/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const ThemesByIdGet_RAW_URL = function() {
  return '/themes/{id}'
}
export const ThemesByIdGet_TYPE = function() {
  return 'get'
}
export const ThemesByIdGetURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/themes/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: UsersMeGet
 * url: UsersMeGetURL
 * method: UsersMeGet_TYPE
 * raw_url: UsersMeGet_RAW_URL
 */
export const UsersMeGet = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/Users/Me'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const UsersMeGet_RAW_URL = function() {
  return '/Users/Me'
}
export const UsersMeGet_TYPE = function() {
  return 'get'
}
export const UsersMeGetURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/Users/Me'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: UsersPut
 * url: UsersPutURL
 * method: UsersPut_TYPE
 * raw_url: UsersPut_RAW_URL
 * @param args - 
 */
export const UsersPut = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/Users'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['args'] !== undefined) {
    body = parameters['args']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const UsersPut_RAW_URL = function() {
  return '/Users'
}
export const UsersPut_TYPE = function() {
  return 'put'
}
export const UsersPutURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/Users'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: UsersPost
 * url: UsersPostURL
 * method: UsersPost_TYPE
 * raw_url: UsersPost_RAW_URL
 * @param args - 
 */
export const UsersPost = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/Users'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['args'] !== undefined) {
    body = parameters['args']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const UsersPost_RAW_URL = function() {
  return '/Users'
}
export const UsersPost_TYPE = function() {
  return 'post'
}
export const UsersPostURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/Users'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: UsersReset_password_requestByIdGet
 * url: UsersReset_password_requestByIdGetURL
 * method: UsersReset_password_requestByIdGet_TYPE
 * raw_url: UsersReset_password_requestByIdGet_RAW_URL
 * @param id - 
 */
export const UsersReset_password_requestByIdGet = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/Users/reset-password-request/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const UsersReset_password_requestByIdGet_RAW_URL = function() {
  return '/Users/reset-password-request/{id}'
}
export const UsersReset_password_requestByIdGet_TYPE = function() {
  return 'get'
}
export const UsersReset_password_requestByIdGetURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/Users/reset-password-request/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: UsersReset_password_requestPost
 * url: UsersReset_password_requestPostURL
 * method: UsersReset_password_requestPost_TYPE
 * raw_url: UsersReset_password_requestPost_RAW_URL
 * @param args - 
 */
export const UsersReset_password_requestPost = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/Users/reset-password-request'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['args'] !== undefined) {
    body = parameters['args']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const UsersReset_password_requestPost_RAW_URL = function() {
  return '/Users/reset-password-request'
}
export const UsersReset_password_requestPost_TYPE = function() {
  return 'post'
}
export const UsersReset_password_requestPostURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/Users/reset-password-request'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: UsersReset_passwordPost
 * url: UsersReset_passwordPostURL
 * method: UsersReset_passwordPost_TYPE
 * raw_url: UsersReset_passwordPost_RAW_URL
 * @param args - 
 */
export const UsersReset_passwordPost = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/Users/reset-password'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['args'] !== undefined) {
    body = parameters['args']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const UsersReset_passwordPost_RAW_URL = function() {
  return '/Users/reset-password'
}
export const UsersReset_passwordPost_TYPE = function() {
  return 'post'
}
export const UsersReset_passwordPostURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/Users/reset-password'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: AffiliatesPost
 * url: AffiliatesPostURL
 * method: AffiliatesPost_TYPE
 * raw_url: AffiliatesPost_RAW_URL
 * @param args - 
 */
export const AffiliatesPost = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/affiliates'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['args'] !== undefined) {
    body = parameters['args']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const AffiliatesPost_RAW_URL = function() {
  return '/affiliates'
}
export const AffiliatesPost_TYPE = function() {
  return 'post'
}
export const AffiliatesPostURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/affiliates'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 
 * request: AffiliatesByIdentifierGet
 * url: AffiliatesByIdIdentifierGetURL
 * method: AffiliatesByIdentifierGet_TYPE
 * raw_url: AffiliatesByIdentifierGet_RAW_URL
 * @param identifier - 
 */
export const AffiliatesByIdentifierGet = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/affiliates/{identifier}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{identifier}', `${parameters['identifier']}`)
  if (parameters['identifier'] === undefined) {
    return Promise.reject(new Error('Missing required parameter: identifier'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const AffiliatesByIdentifierGet_RAW_URL = function() {
  return '/affiliates/{identifier}'
}
export const AffiliatesByIdentifierGet_TYPE = function() {
  return 'get'
}
export const AffiliatesByIdentifierGetURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/affiliates/{identifier}'
  path = path.replace('{identifier}', `${parameters['identifier']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}