<template>
  <div>
    <h1>Create an Account</h1>

    <div class="mb-4">Already have an account? <router-link to="/sign-in">Sign In</router-link></div>

    <div class="text-left max-w-sm mx-auto">
      <div class="py-2">
        <div class="inline-block w-32">Email Address:</div>
        <div class="inline-block w-full md:w-80"><input class="w-full" name="EmailAddress" type="email" v-model="emailAddress" /></div>
      </div>
      <div class="py-2">
        <div class="inline-block w-32">Password:</div>
        <div class="inline-block w-full md:w-80"><input class="w-full" name="Password" type="password" v-model="password" /></div>
      </div>
      <div class="py-2">
        <div class="inline-block w-32">First Name:</div>
        <div class="inline-block w-full md:w-80"><input class="w-full" name="firstName" v-model="firstName" /></div>
      </div>
      <div class="py-2 mb-8">
        <div class="inline-block w-32">Last Name:</div>
        <div class="inline-block w-full md:w-80"><input class="w-full" name="LastName" v-model="lastName" /></div>
      </div>
    </div>

    <button class="btn" @click="register">Register</button>
    <p class="error-summary"></p>
  </div>
</template>

<script>
  export default {
    name: 'Register',
    props: [ 'emailAddress' ],
    
    data: function () {
      return {
        password: null,
        firstName: null,
        lastName: null,
      }
    },
    methods: {
      register() {
        const self = this;
        
        self.$store
          .dispatch('register', self)
          .then(response => {
              self.$store.dispatch('addMessage', { text: 'Account has been created!' });

              let redirectUrl =  self.$route.params.redirectUrl || '/';

              self.$router.push(redirectUrl);
            }).catch(e => {
              self.processErrorMessages(e)
              self.handleApiError(e)
            });
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name === 'sign-in' && this.$route.params.redirectUrl) {
        to.params.redirectUrl = this.$route.params.redirectUrl;
      }

      next();
    }
  }
</script>
