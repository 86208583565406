import Vue from 'vue'
import moment from 'moment'

Vue.filter('bookletDate', function(value) {
  if (value) {
    let momentDate = moment(value);

    if (momentDate.isValid()) {
      return momentDate.format('MMMM DD, YYYY');
    }
  }
});