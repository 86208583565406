<template>
  <div>
    <label v-html="label"></label>
    <input type="number"
           :name="name"
           :value="value"
           :min="minValue"
           @input="$emit('input', $event.target.value)"
           :placeholder="placeholder" />

    <show-modal-button class="ml-2" @close="closeModal">
      <div slot="button-content">
        Set Age
      </div>
      <div slot="modal-content">
        <h1 v-html="label" class="font-normal text-xl text-left mb-2 sm:mt-0 mt-2"></h1>
        <div v-if="instruction" v-html="instruction" class="text-sm text-left px-2 mb-6"></div>
        <div class="mb-6">
          <label>Age: <input class="w-full my-2" v-model="age" /></label>
        </div>
        <div class="text-right">
          <button type="button" class="btn-light ml-3" data-modal-action="cancel">
            Cancel
          </button>
          <button type="button" class="btn ml-3" data-modal-action="ok">
            OK
          </button>
        </div>
      </div>
    </show-modal-button>
  </div>
</template>

<script>
  import ShowModalButton from '../ShowModalButton'

  export default {
    name: 'NumberInput',
    components: { ShowModalButton },
    props: ['placeholder', 'label', 'name', 'value', 'minValue', 'instruction'],
    data() {
      return {
        age: null
      }
    },
    watch: {
      value: function(val) {
        this.age = val;
      }
    },
    methods: {
      closeModal(result) {
        if (result === 'ok') {
          this.$emit('input', this.age);
        }
        else if (result === 'cancel') {
          this.age = this.$props.value;
        }
      }
    }
  }
</script>