<template>
  <button @click="showModal=true">
    <slot name="button-content">Show Modal</slot>
    <portal to="modals">
      <modal :key="this.$parent.name" :open="showModal" :modal-class="modalClass" @close="closeModal">
        <slot name="modal-content" slot="modal-content"></slot>
      </modal>
    </portal>
  </button>
</template>

<script>
  import Modal from './Modal'

  export default {
    name: 'ShowModalButton',
    components: { Modal },
    props: ['modalClass'],
    data() {
      return {
        showModal: false
      }
    },
    methods: {
      closeModal(result) {
        this.showModal = false;
        this.$emit('close', result);
      },
    },
    watch: {
      showModal() {
        this.$emit('showModal', this.showModal);    
      }
    }
  }
</script>