<template>
  <div>
   <h1>Decedent's Profile</h1>
    <div class="max-w-xl text-left mx-auto">
      <p>
        Creating a loving funeral captures the spirit of the person whose life you wish to celebrate and honor. It’s a final opportunity to express the relationship you shared, remember their life’s path and acknowledge their unique personality.
      </p>
      <p>
        Saying “goodbye” to their physical presence is truly an act of love for all parties involved. Your custom designed booklet solidifies your shared relationship through selected scripture readings, photos, poems and prayers. Sharing your creation with family and friends demonstrates your love, respect and honor of your loved one.
      </p>   
      <BeginHere class="h-20 md:pl-8 lg:pl-32"></BeginHere>
    </div>

    <div class="md:-mt-6">
      <div class="mb-4" v-if="!signedIn">
        <router-link @click.native="storeBooklet" :to="{ name: 'register', params: { redirectUrl: '/funeral-booklets/create' }}">Create an Account</router-link> or <router-link @click.native="storeBooklet" :to="{ name: 'sign-in', params: { redirectUrl: '/funeral-booklets/create' }}">Sign In</router-link>
        to begin a booklet creation.
      </div>

      <div class="mt-8 mb-12 flex justify-center">
        <div class="max-w-lg pb-10 text-left border-b border-grey-light">
          <div><input type="radio" id="traditional" name="selection" value="traditional" @change="onChange($event)" checked=checked><label for="traditional"><span class="pl-4"><b>Traditional</b> – <span class="text-sm">Includes 'Pallbearers' Title and Space for Names of Pallbearers</span></span></label></div>
          <div><input type="radio" id="cremation" name="selection" value="cremation" @change="onChange($event)"><label for="cremation"><span class="pl-4"><b>Cremation</b> - <span class="text-sm">Omits 'Pallbearers' Title and Space for Names of Pallbearers</span></span></label></div>
          <div><input type="radio" id="memorial" name="selection" value="memorial" @change="onChange($event)"><label for="memorial"><span class="pl-4"><b>Memorial Mass</b> - <span class="text-sm">Omits 'Pallbearers' Title and Space for Names of Pallbearers</span></span></label></div>
        </div>
      </div>

      <div class="p-2">
        <div class="inline-block w-64 text-left">Decedent's Name:</div>
        <div class="inline-block w-64 text-left"><input class="w-full" name="DeceasedName" v-model="booklet.deceasedName" /></div>
      </div>

    </div>

    <div class="p-2">
      <div class="inline-block w-64 text-left">Decedent's Date of Birth:</div>
      <div class="inline-block w-64 text-left"><input class="w-full" name="DeceasedDateOfBirth"  type="date" v-model="booklet.deceasedDateOfBirth" /></div>
    </div>
    <div class="p-2">
      <div class="inline-block w-64 text-left">Decedent's Date of Death:</div>
      <div class="inline-block w-64 text-left"><input class="w-full" name="DeceasedDateOfDeath" type="date" v-model="booklet.deceasedDateOfDeath" /></div>
    </div>

    <div class="mt-12">
      <button @click="submit" class="btn ml-4">Continue</button>
    </div>
    <div class="max-w-lg text-left mx-auto">
      <hr>
      <Prerequisite :templateName="templateName"></Prerequisite>
    </div>
    <p class="error-summary my-4"></p>
  </div>
</template>

<script>
  import * as api from '../client/api.js'
  import { mapGetters } from 'vuex'
  import Prerequisite from '../components/Prerequisite'
  import BeginHere from '../components/svg/BeginHere.svg'

  export default {
    name: 'FuneralBooklet',
    props: [ 'templateName' ],
    data() {
      return {
        loaded: false,
        booklet: {
          bookletTypeName: this.templateName,
          deceasedName: null,
          deceasedDateOfBirth: null,
          deceasedDateOfDeath: null,
          cremation: false,
          cloudinaryURL: null,
          cloudinaryPath: null
        }
      }
    },
    computed: mapGetters(['signedIn']),
    components: { Prerequisite, BeginHere },
    methods: {
      submit() {
        let self = this;

        self.booklet.cloudinaryURL = this.$config.cloudinary.url
        self.booklet.cloudinaryPath = this.$config.cloudinary.path
        
        api.BookletsPost({ args: this.booklet }).then(response => {
          self.$store.dispatch('deleteBookletData');
          this.$router.push({ name: 'booklet', params: { id: response.data }})
        }).catch(e => {
          this.processErrorMessages(e);
          this.handleApiError(e);
        })
      },
      onChange(event) {
        let self = this;
        self.booklet.cremation = (event.target.value == 'traditional') ? false : true;
      }
    },
    created() {

      if(!this.templateName){
        this.$router.push("/funeral-booklets")
      }

      this.loaded = true
    }
  }
</script>


<style scoped>
hr {
  @apply mt-24;
  @apply mb-20;
  @apply max-w-2xl;
  @apply border-0;
  @apply h-px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));  
}
</style>
