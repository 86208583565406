<template>
  <div>
    <h1>Reset Password</h1>
    
    <form v-if="!requestSent" method="post" v-on:submit.prevent="submit">
      <div class="text-left max-w-sm mx-auto">
        <div class="mb-8 py-2 px-8">
          <div class="inline-block w-32">Email Address:</div>
          <div class="inline-block w-full md:w-80"><input class="w-full" name="EmailAddress" type="email" v-model="emailAddress" /></div>
        </div>
      </div>
      <button class="btn">Submit</button>
    </form>

    <p v-if="requestSent">
      An email containing instructions to reset your password has been sent.
    </p>
    
    <p class="error-summary"></p>
  </div>
</template>

<script>
  import * as api from '../client/api.js'

  export default {
    name: 'ResetPasswordRequest',
    data() {
      return {
        emailAddress: null,
        requestSent: false
      }
    },
    methods: {
      submit() {
        const self = this

        api.UsersReset_password_requestPost({ args: { emailAddress: self.emailAddress }}).then(x => {
          self.requestSent = true
        }).catch(e => {
          self.processErrorMessages(e)
          self.handleApiError(e)
        })
      }
    }
  }
</script>
