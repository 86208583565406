<template>
  <div v-if="request || requestExpired">
    <h1>Reset Password</h1>
    
    <form v-if="!requestExpired" method="post" v-on:submit.prevent="submit">
      <div class="text-left max-w-sm mx-auto">
        <div class="py-2">
          <div class="inline-block w-full md:w-80">New Password:</div>
          <div class="inline-block w-full md:w-80"><input class="w-full" name="Password" type="password" v-model="password" /></div>
        </div>
      </div>
      <div class="mt-8">
        <button class="btn">Submit</button>
      </div>
    </form>
    <div v-if="requestExpired">
      Your password reset request has expired. Please <router-link to="/reset-password">click here</router-link> to submit a new request.
    </div>
    
    <p class="error-summary"></p>
  </div>
</template>

<script>
  import * as api from '../client/api.js'
  import ApiError from '../models/ApiError'

  export default {
    name: 'ResetPassword',
    data() {
      return {
        request: null,
        requestExpired: false,
        password: null
      }
    },
    created() {
      let self = this;

      api.UsersReset_password_requestByIdGet({ id: self.$route.params.id }).then(response => {
        if (response && response.data) {
          self.request = response.data;
        }
      }).catch(e => {
        if (e.statusCode === 404) {
          self.requestExpired = true;
        }
        else {
          self.handleApiError(e);
        }
      });
    },
    methods: {
      submit() {
        const self = this;

        api.UsersReset_passwordPost({ args: { ResetPasswordRequestId: this.request.id, password: this.password } }).then(x => {
          self.$store.dispatch('addMessage', { text: 'Your password was reset successfully.' });
          self.$router.push({ name: 'sign-in'});
        }).catch(e => {
          self.processErrorMessages(e);
          self.handleApiError(e);
        })
      }
    }
  }
</script>
