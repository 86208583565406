<template>
  <div>
    <h1>Thank you for registering!</h1>
    <div class="text-md md:text-lg mx-auto max-w-lg text-left">
      <p>
        Welcome to <i>My Way Booklets'</i>Affiliate Program.  We are honored to professionally maintain your 
        business reputation and to assist your families with a matchless alternative to create a loving keepsake 
        reflective of their loved one's life. An Executive Assistant will contact you shortly to confirm your 
        registration and answer any additional questions.
      </p>
      <div class="mx-auto max-w-sm text-center">
        <div class="mx-auto text-left">
          <div class="pt-6">
            <div class="inline-block md:w-48 text-grey-darker">Identifier # :</div>
            <div class="inline-block font-medium">{{ data.identifier }}</div>
          </div>
          <div class="pt-6 font-medium">Contact Summary :</div>
          <div class="pt-2">
            <div class="inline-block md:w-48 text-grey-darker">{{ typeName }} :</div>
            <div class="inline-block font-medium whitespace-no-wrap">{{ data.name }}</div>
          </div>
          <div>
            <div class="inline-block md:w-48 text-grey-darker">Contact Name :</div>
            <div class="inline-block whitespace-no-wrap">{{ contact.firstName }} {{ contact.lastName }}</div>
          </div>
          <div class="pt-2">
            <div class="inline-block md:w-48 text-grey-darker">Address :</div>
            <div class="inline-block">{{ location.address1 }}</div>
          </div>
          <div v-if="location.address2">
            <div class="inline-block md:w-48 text-grey-darker">&nbsp;</div>
            <div class="inline-block">{{ location.address2 }}</div>
          </div>
          <div>
            <div class="inline-block md:w-48 text-grey-darker">&nbsp;</div>
            <div class="inline-block">{{ location.city }}, {{ location.state }} {{ location.zipCode }}</div>
          </div>
          <div class="pt-2">
            <div class="inline-block md:w-48 text-grey-darker">Email Address :</div>
            <div class="inline-block">{{ contact.emailAddress }}</div>
          </div>
          <div v-if="contact.telephone">
            <div class="inline-block md:w-48 text-grey-darker">Telephone :</div>
            <div class="inline-block">{{ contact.telephone }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AffiliateConfirmation",
  props: ["data"],
  data() {
    return {};
  },
  computed: {
    typeName: function() {
      return this.data.typeid == 1 ? "Funeral Home" : "Church";
    },
    contact: function() {
      return this.data.contact;
    },
    location: function() {
      return this.data.location;
    }
  }
};
</script>
