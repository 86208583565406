<template>
    <div>
        <Accordion title="Cover Stock v. Text Stock:" @expand="expandAll" class="text-navy hover:text-melon">
            <p>
            <span class="italic underline">Cover stock</span> is a thicker paper, equal to a thin cardboard. It is <b><i>often used as covers</i></b> for booklets, brochures, postcards, and bookmarks, basically anything that needs a stiff, heavier paper. 
            </p>
            <p>
            <span class="italic underline">Text stock</span> is the paper you're accustomed to using in desktop printers; it's thinner, looser and more flexible.  It is <b><i>commonly used as the interior paper</i></b> of a booklet or book.
            </p> 
        </Accordion>
        <Accordion title="Premium Paper Stocks:" @expand="expandAll" class="text-navy hover:text-melon">
            <p>
                Premium papers offer nicer textures, better coatings, higher brightness and greater attention to detail. These attributes compliment design, facilitating outstanding performance to a printed piece.
            </p>

            <p><span class="italic underline">80# Gloss Cover **</span><br />
            Looking for a clean look and superior feel of a gloss finish? This stock has a brilliant, alluring gloss finish for stunning graphic reproduction. It's designed for image quality and print performance for use with today's high-resolution color copiers. The ink stands up - so your images stand out!  Warmly suggested choice as (Cover & Text) of an 8 or a 12 Page Booklet or combine 80# Gloss Cover with 80# Gloss Text of an 8 or a 12 Page booklet. 4 Page booklets printed on Cover Stock only. 
            </p>
            <p><span class="italic underline">80# Gloss Text *</span><br />
            A lightweight paper stock with a glossy sheen on both sides. Gives paper a polished sheen, produces vibrant, crisp images and ink print. Highly beneficial to use as interior booklet pages for the 8 or 12 Page booklet.
            </p>
            <p><span class="italic underline">100# Gloss Cover **</span><br />
            This premium cover stock is thicker than the 80# gloss cover with a glossy sheen on both sides. This heavier stock cover makes colors more vibrant and produces a more durable cover. An excellent stock choice as (Cover & Text) of an 8 or a 12 Page Booklet or combine 100# Gloss Cover with 100# Gloss Text of an 8 or a 12 Page booklet.  4 Page booklets printed on Cover Stock only. 
            </p>
            <p><span class="italic underline">100 # Gloss Text *</span><br />
            This paper stock is 25% thicker and heavier than the 80# text gloss. It has a substantial sensation in the hands with a gloss sheen finish on both sides to make colors more vibrant. Regularly suggested use as interior booklet pages and is an ideal choice for interior pages of the 8 or 12 Page booklets.
            </p>

            <p><span class="italic underline">80# Linen Cover **</span><br />
            This classic Linen cover stock has a very delicate, understated linen embossed finish that whispers taste, elegance and refinement. It almost feels like a delicate woven cloth as you let your fingertips drift across it. But, don’t let that fool you, it also performs like a workhorse - with a hard, super printable surface that's compatible with laser and ink jet printers. 80# Linen Cover is an excellent stock for the 8 or 12 Page booklet (Cover & Text) or combine 80# Linen Cover with 80# Linen Text for the 8 or 12 Page booklet. 
            4 Page booklets printed on Cover Stock only. 
            </p>
            <p><span class="italic underline">80# Linen Text *</span><br />
            This textured paper stock lends the look of woven linen with a classic elegant finish. It's subtle, sensuous, sensible, and, perfect for full-color applications.  It's truly a paper for our time. Highly recommended and available for use as interior booklet pages for the 8 or 12 Page booklets.  
            </p>
            <p><span class="italic underline">100# Linen Cover **</span><br /> 
            The classic linen texture and premium thickness of this stock communicates strength, boldness, and durability. Its rich elegant appearance adds depth and substance. If you want the ultimate impression of your booklet, select 100# Linen Cover for the 8 or 12 Page booklet (Cover & Text) or combine 100# Linen Cover with 100# Linen Text for the 8 or 12 Page booklets. This premium texture adds an elegant finish that makes a bold impact in the hand and on the eye of the beholder. Nothing gives a more luxurious and sophisticated sensation than this paper and finish. 4 Page booklets printed on Cover Stock only. 
            </p>
            <p><span class="italic underline">100# Linen Text *</span><br />
            This woven linen textured stock is 25% thicker and heavier than the 80# linen text. Its slightly raised texture lends a smooth feel with a classic elegant finish perfect for full-color applications. Truly a paper for our time. Highly recommended for use as interior booklet pages for the 8 or 12 Page booklets.
            </p>

            <p><span class="italic underline">80# Matte Cover **</span><br /> 
            This premium coated paper is coated on both sides, it’s smooth to the touch and provides perfect digital printing. It looks good, feels good, and prints great. 80# Matte Cover stock is ideal for use as (Cover & Text) of an 8 or a 12 Page Booklet or combine 80# Matte Cover with 80# Matte Text for the 8 or a 12 Page booklets.   4 Page booklets printed on Cover Stock only. 
            </p>
            <p><span class="italic underline">80# Matte Text *</span><br />
            This smooth silky paper offers a softer glare-free sheen plus it provides the highest quality and most versatile everyday digital printing paper. It provides an appealing, dense, tactile sensation to the hand and superior readability. This elegant workhorse coated paper performs well and is highly beneficial for use as interior booklet pages for the 8 or 12 Page booklets.
            </p>
            <p><span class="italic underline">100# Matte Cover **</span><br />
            This sturdy premium stock lends a classical look and feel. It is coated on both sides, moderately smooth, substantial to the touch, creates a presence in the hand, and most important, it prints great. 100# Matte Cover is frequently recommended and requested for use as (Cover & Text) of an 8 or a 12 Page Booklet or combine 100# Matte Cover with 100# Matte Text of the 8 or 12 Page booklets.  
            4 Page booklets printed on Cover Stock only. 
            </p>
            <p><span class="italic underline">100# Matte Text *</span><br />
            This silky paper stock is 25% thicker and heavier than the 80# text matte. It offers a non-glossy, opaque base for detailed, crisp printing. And provides a substantially appealing tactile sensation to the hand with superior readability. Available and highly recommended for use as interior pages of the 8 or 12 Page booklet
            </p>

            <p>
            * Available as Interior Pages Only<br />
            ** Available as Cover &amp; Interior pages for 8 Page &amp; 12 Page booklets<br />
            4 Page booklets printed on Cover Stock only
            </p>
        </Accordion>
        <Accordion title="Color Selection:" @expand="expandAll" class="text-navy hover:text-melon">
            <p>
            <b>WHITE</b><br />
            <b>NATURAL</b>
            </p>
        </Accordion>      
    </div>    
</template>

<script>
  import Accordion from '../components/Accordion'

  export default {
    name: 'PaperDefinition',
    data() {
        return {
        "expanded": false
        }
    },
    components: {
      Accordion
    },
    methods: {
        expandAll() {
        self.$emit("closeAll");
        }
    }
  }
</script>
