import Vue from 'vue';
import axios from 'axios';

const getConfig = function (callback) {
  axios.get('/config.json').then(response => {
    let config = response.data;

    Vue.$config = config;

    Vue.mixin({
      beforeCreate() {
        const options = this.$options;
        if (options.config)
          this.$config = options.config;
        else if (options.parent && options.parent.$config)
          this.$config = options.parent.$config;
      }
    });

    callback(config);
  }).catch(e => {
    return null;
  });
};

export default {
  getConfig
};