import Vuex from 'vuex'
import Vue from 'vue'
import auth from './modules/auth'
import booklets from './modules/booklets'
import shared from './modules/shared'

Vue.use(Vuex);

let store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    auth,
    booklets,
    shared
  }
});

export default store;