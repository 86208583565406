<template>
  <div>
    <select v-model="path" @change="changePath" class="md:hidden w-full block mb-6">
      <option value="/help">Help</option>
      <option value="/about/mission">About: Mission</option>
      <option value="/about/what-makes-us-different">About: What Makes Us Different</option>
      <option value="/about/dedication">About: Dedication</option>
      <option value="/contact-us">Contact Us</option>
      <option value="/fallen-heros/children">Fallen Heros: Children</option>
      <option value="/fallen-heros/veterans">Fallen Heros: Veterans</option>
      <!-- <option value="/faq">FAQ</option> -->
      <option value="/our-guarantee">Our Guarantee</option>
      <option value="/pre-planning">Pre-Planning: Why Pre-Plan</option>
      <option value="/pre-planning/booklets">Pre-Planning: Booklets</option>
      <option value="/pre-planning/save-and-retrieve-your-booklet">Pre-Planning: Save and Retrieve Your Booklet</option>
      <option value="/privacy-policy">Privacy Policy</option>
      <option value="/terms-of-service">Terms of Service</option>
    </select>
    
    <div class="hidden md:block w-48 pr-4 text-left text-base help-menu border-r border-grey-light">
      <router-link event="" @click.native.prevent="toggleGroup('about')" :class="{'router-link-active':aboutActive}" class="p-2 text-grey-darker block" to="/about">About</router-link>
      <div class="hidden">
        <router-link class="pl-4 p-2 font-light text-grey-darker block" to="/about/dedication">Dedication</router-link>
        <router-link class="pl-4 p-2 font-light text-grey-darker block" to="/about/mission">Mission</router-link>
        <router-link class="pl-4 p-2 font-light text-grey-darker block" to="/about/what-makes-us-different">What Makes Us Different</router-link>
      </div>
      
      <router-link class="p-2 text-grey-darker block" to="/contact-us">Contact Us</router-link>

      <router-link event="" @click.native.prevent="toggleGroup('fallenHeros')" :class="{'router-link-active':fallenHerosActive}" class="group p-2 text-grey-darker block" to="/fallen-heros">Fallen Heros</router-link>
      <div class="hidden">
        <router-link class="pl-4 p-2 font-light text-grey-darker block" to="/fallen-heros/children">Children</router-link>
        <router-link class="pl-4 p-2 font-light text-grey-darker block" to="/fallen-heros/veterans">Veterans</router-link>
      </div>

      <!-- <router-link class="p-2 text-grey-darker block" to="/faq">FAQ</router-link> -->
      <router-link class="p-2 text-grey-darker block" to="/our-guarantee">Our Guarantee</router-link>
      
      <router-link event="" @click.native.prevent="toggleGroup('prePlanning')" :class="{'router-link-active':prePlanningActive}" class="p-2 text-grey-darker block" to="/pre-planning">Pre-Planning</router-link>
      <div class="hidden">
        <router-link class="pl-4 p-2 font-light text-grey-darker block" to="/pre-planning/booklets">Booklets</router-link>
        <router-link class="pl-4 p-2 font-light text-grey-darker block" to="/pre-planning/save-and-retrieve-your-booklet">Save and Retrieve Your Booklet</router-link>
        <router-link class="pl-4 p-2 font-light text-grey-darker block" to="/pre-planning">Why Pre-Plan</router-link>
      </div>

      <router-link class="p-2 text-grey-darker block" to="/privacy-policy">Privacy Policy</router-link>
      
      <!-- <router-link event="" @click.native.prevent="toggleGroup('resources')" :class="{'router-link-active':resourcesActive}" class="p-2 text-grey-darker block" to="/resources">Resources</router-link>
      <div class="hidden"> -->
        <router-link class="p-2 text-grey-darker block" to="/resources/order-of-christian-funerals">Order of Christian Funerals</router-link>
        <router-link class="p-2 text-grey-darker block" to="/resources/purpose-of-a-funeral">Purpose of a Funeral</router-link>
      <!-- </div> -->

      <router-link class="p-2 text-grey-darker block" to="/terms-of-service">Terms of Service</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: "HelpMenu",
    data() {
      const path = window.location.pathname.toLowerCase()

      return {
        path: path,
        aboutActive: path.startsWith('/about'),
        resourcesActive: path.startsWith('/resources'),
        prePlanningActive: path.startsWith('/pre-planning'),
        fallenHerosActive: path.startsWith('/fallen-heros')
      }
    },
    methods: {
      changePath(){
        this.$router.push(this.path);
      },
      toggleGroup(group){
        if(group === 'about') {
          this.aboutActive = !this.aboutActive
          this.resourcesActive = false
          this.prePlanningActive = false
          this.fallenHerosActive = false
        } else if(group === 'resources') {
          this.resourcesActive = !this.resourcesActive
          this.prePlanningActive = false
          this.fallenHerosActive = false
          this.aboutActive = false
        } else if(group === 'prePlanning') {
          this.prePlanningActive = !this.prePlanningActive
          this.resourcesActive = false
          this.fallenHerosActive = false
          this.aboutActive = false
        } else if (group === 'fallenHeros'){
          this.fallenHerosActive = !this.fallenHerosActive
          this.resourcesActive = false
          this.prePlanningActive = false
          this.aboutActive = false
        }
      }
    }
  }
</script>

<style scoped>
  .router-link-active + div {
    display: block;
  }
  a {
    @apply roman;
  }
</style>
