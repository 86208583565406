<template>
  <div>
    <div class="mx-auto max-w-xl">
      <div class="md:px-8 text-left">
        <h1>What Makes Us Different</h1>
        <p>
          MY WAY BOOKLETS' primary purpose is to simplify one of the tasks of planning a loving funeral while capturing the spirit of the person whose life you wish to memorialize, honor, and celebrate.
        </p>
        <p>
          Our alternative user-friendly interface walks you through the booklet options, page by page, assisting with a uniquely designed booklet in accordance with the Order of Christian Funerals, offering the complete selection of scripture readings for the First Reading, Second Reading, Gospel, Responsorial Psalms and Hymns recommended Outside the Easter Season and during Easter Time.
        </p>
        <p>
          Design your booklet today, remember and share the memories of yesterday with your family, relatives and friends, create a meaningful keepsake in honor of your loved one’s life that will capture the hearts and minds of all in attendance with a priceless booklet that will be Treasuered Forever!
        </p>
        <p>
          Inclusive additional features: the ability to upload personal photographs, poems, prayers or quotes from your own library or from in-house resource library; a guaranteed print-ready PDF; high standard in-house printing* (includes the selection of superior paper and color); first-class packaged booklet orders; and, next day shipping to your designated location.
        </p>
        <p class="italic">
          *In-house print incentives include FREE shipping &amp; handling.
        </p>
        <p>
          Creating a timeless keepsake is a GIFT… a Gift to be shared with your family and friends… a Gift of memories… a Gift of healing… It’s truly a priceless Gift of peace-of-mind.
        </p>
        <p>
          We warmly invite you to demonstrate a true act of love as you physically say goodbye and spiritually lock them in your heart forever.  
        </P>

        <h1 class="text-left">Our Mission</h1>
        <p>
          MY WAY BOOKLETS' mission enables you to create a distinct “one of a kind” mass booklet that guarantees an excellent first impression to share with your family and friends. Our online prototype options are designed with ease of use, complemented with high standard print quality and overnight shipping.
        </p>
        <p>
          Your Catholic Funeral mass booklet bequests your loved one’s desires in accordance with the Order of Christian Funerals offering a complete selection of scripture readings for the First Reading, the Second Reading, the Gospel, Responsorial Psalms and recommended Hymns. 
        </p>
        <p>
          Whether your intention is to plan a traditional funeral or follow the current funeral trend of a “Celebration of Life,” your wishes will be granted and our mission will be accomplished as your booklet is lovingly treasured by all in attendance honoring and memorializing a lasting impression of a life lived.
        </p>
        <p>
          <b><i>We warmly</i></b> welcome you to begin your journey, genuinely express your sentiments, and illuminate the personality of your loved one. 
        </p>

        <h1>Dedication</h1>
        <div class="lg:flex content-center">
          <div class="lg:flex-1">
            <p>
              MY WAY BOOKLETS' is dedicated to a man who truly celebrated life in every sense.  I am proud to say that this gentleman was my Dad, William P. Plumley, Jr., a/k/a Billy Matthews. He was an entertainer in the Pennsylvania and South Jersey areas for over thirty years and a Deputy Jury Commissioner in the Philadelphia Court of Common Pleas.
            </p>
            <p>
              The only thing that surpassed his love for life and his family was his love for the Lord. From learning the Mass in Latin as an altar boy, to watching his father sing in Church, to praying the Joyful, Sorrowful, and Glorious Mysteries of the Rosary until he lost consciousness, his faith never wavered.
            </p>
            <p>
              To me, he was a Dad unlike any other; loving, kind, funny, compassionate, always willing to help others and, if he could not help them, he would find someone who could complete the task.
            </p>
            <p>
              I thank God every day that he was the man who created my existence and the one who was beside me when I drew my first breath. I feel privileged that God chose me to be the one who was with him when he drew his last breath while on earth. I look forward to smiling, laughing and, most of all, walking with him again when the day comes that our Lord calls me home to my eternal life.  <i>Thank you, Dad, for engraving a memory of everlasting love in my heart.</i>
            </p>        
          </div>
          <div class="lg:flex-none md:mx-12 image-daddy">
          </div>
        </div>

      
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>

<style scoped>
  .image-daddy {
      background-image: url('../assets/images/Daddy.jpg');
      width: 295px;
      height: 500px;
      box-shadow: 0 0 0px ppx white inset;
}
</style>