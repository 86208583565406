import moment from 'moment'

const getEasterReading = function(massDate, config) {
  let easterStartDate = moment(config.easterStartDate);
  let easterEndDate = moment(config.easterEndDate);

  if (easterStartDate.isValid() && easterEndDate.isValid()) {
    let massDateMoment = moment(massDate);

    if (massDateMoment.isValid() && massDateMoment.isSameOrAfter(easterStartDate) && massDateMoment.isSameOrBefore(easterEndDate)) {
      return 'NTE';
    } else {
      return 'OT';
    }
  }
}

export {
  getEasterReading
}
