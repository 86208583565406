<template>
  <div>
    <div class="mx-auto max-w-xl">
      <div class="md:px-8 text-left">
        <h1>Contact Us</h1>
        <p>MY WAY BOOKLETS' empathizes with you at this emotionally difficult time. We acknowledge the time-consuming, multiple tasks of planning a funeral. Allow us to simplify one of your tasks by assisting you in designing a funeral Mass booklet honoring and capturing the spirit of your loved one; a uniquely created keepsake to be shared with your family and friends. Please contact us.</p>
        <div v-if="!sent">
          <div v-if="!signedIn" class="p-2">
            <div class="inline-block w-32 text-left">Name:</div>
            <div class="inline-block w-full md:w-96 text-left">
              <input class="w-full" name="Name" v-model="name" />
            </div>
          </div>
          <div v-if="!signedIn" class="p-2">
            <div class="inline-block w-32 text-left">Email Address:</div>
            <div class="inline-block w-full md:w-96 text-left">
              <input class="w-full" name="EmailAddress" type="email" v-model="emailAddress" />
            </div>
          </div>
          <div class="p-2">
            <div class="inline-block w-32 text-left">Subject:</div>
            <div class="inline-block w-full md:w-96 text-left">
              <input class="w-full" name="Subject" v-model="subject" />
            </div>
          </div>
          <div class="p-2 mb-8">
            <div class="inline-block w-32 md:align-top md:pt-2 text-left">Message:</div>
            <div class="inline-block w-full md:w-2/3 text-left">
              <textarea class="w-full h-32" name="Message" v-model="message"></textarea>
            </div>
          </div>
          <button class="btn" @click="send">Send</button>
          <p class="error-summary"></p>
        </div>
        <div v-if="sent">Thank you for contacting us. We'll get back to you shortly.</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../client/api.js";
import { mapGetters } from "vuex";

export default {
  name: "ContactUs",
  computed: mapGetters(["signedIn", "currentUser"]),
  data() {
    return {
      name: null,
      emailAddress: null,
      subject: null,
      message: null,
      sent: false
    };
  },
  methods: {
    send() {
      const self = this;

      let data = {
        name: self.signedIn
          ? `${self.currentUser.firstName} ${self.currentUser.lastName} (${self.currentUser.id})`
          : self.name,
        emailAddress: self.signedIn
          ? self.currentUser.emailAddress
          : self.emailAddress,
        subject: self.subject,
        message: self.message
      };

      api
        .ContactPost({ args: data })
        .then(x => {
          self.sent = true;
        })
        .catch(e => {
          self.processErrorMessages(e);
          self.handleApiError(e);
        });
    }
  }
};
</script>
