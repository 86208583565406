import Vue from 'vue'
import uuid from 'uuid'

const shared = {
    state: {
        messages: [],
        references: {}
    },

    getters: {
        messages: state => {
            let result = state.messages.map(function(message){
                return message.text;
            }).join(' ');
    
          return result;
        },

        hymns: state => {
            if (state.references && state.references.hymns) {
                return state.references.hymns.slice().sort((x, y) => (x.title > y.title) ? 1 : ((y.title > x.title) ? -1 : 0))
            }
            else {
                return null;
            }
        },

        scriptures: state => {
            if (state.references && state.references.scriptures) {
                let scriptures = state.references.scriptures.slice();

                scriptures.sort((x, y) => {
                    let xDisplay = `${x.book}:${x.lines}`;
                    let yDisplay = `${y.book}:${y.lines}`;

                    return (xDisplay > yDisplay) ? 1 : ((yDisplay > xDisplay) ? -1 : 0)
                });

                return scriptures;
            }
            else {
                return null;
            }
        }
    },
    
    mutations: {
        ADD_MESSAGE(state, message) {
            state.messages.push(message)
        },

        DELETE_MESSAGE(state, id) {
            state.messages = state.messages
                .filter(function(message) {
                    return message.id !== id
                })
        },

        DELETE_ALL_MESSAGES(state) {
            state.messages = []
        },

        STORE_REFERENCES(state, references) {
            state.references = references;
        }
    },
    
    actions: {
        addMessage({ commit, dispatch }, message){
            const duration = Vue.$config.notificationMessageDurationInSeconds
            const id =  uuid()
            const msg = {
                id: id,
                text: message.text,
                type: 'NOTIFICATION'
            }

            console.log(message)

            commit('ADD_MESSAGE', msg)

            if (duration) {
                setTimeout(() => commit('DELETE_MESSAGE', id), duration * 1000)
            }
        },

        storeReferences({ commit, dispatch }, references) {
            commit('STORE_REFERENCES', references)
        }
    }
}

export default shared