<template>
  <div>
    <label v-html="label"></label>
    <span>
      <show-modal-button modal-class="max-w-md" @close="closeModal"
                         :class="[scriptureLoading ? 'disabled' : (scripture ? 'text-melon text-left' : 'btn text-center'), 'ml-2']"
      >
        <div slot="button-content" class="scripture-button align-middle truncate">
          {{ scripture ? scriptureDisplay(scripture) : (scriptureLoading ? (loaded ? value : 'Loading...') : `Select ${this.label}`) }}
        </div>
        <div slot="modal-content">
          <h1 v-html="label" class="font-normal text-xl text-left mb-2 sm:mt-0 mt-2"></h1>
          <div v-if="instruction" v-html="instruction" class="text-sm text-left px-2 mb-4 sm:mb-6"></div>
          
          <div class="flex flex-col pl-2 mb-4">
            <label v-html="`Preferred ${this.label}`" class="text-sm mb-1"></label>
            <div>
              <input v-model="userBook" class="text-sm w-1/3" /><span class="px-3 font-medium">:</span><input v-model="userLines" class="text-sm w-1/3" />
            </div>

            <label v-if="title && userBook" class="text-sm my-1">Title</label>
            <div v-if="title && userBook" >
              <input v-model="userTitle" class="text-sm sm:text-md w-full" />
            </div> 
            
            <label v-if="override && userBook" class="text-sm my-1">Scripture</label>
            <div v-if="override && userBook">
              <textarea v-model="userOverride" class="text-sm whitespace-pre break-words text-sm leading-normal w-full h-32 sm:h-48" />
            </div> 
            
            <label v-if="response && userBook" class="text-sm my-1">Response</label>
            <div v-if="response && userBook" >
              <input v-model="userResponse" class="text-sm sm:text-md w-full" />
            </div>           
          </div>

          <div v-if="scriptures" class="scriptures-container scrollable-container overflow-y-auto p-2"  :class="{ 'collapse' : isCollapse }">
            <div v-for="scripture in scriptures" v-bind:key="scripture.id" @click="selectScripture(scripture)" :class="['my-1', 'p-1', 'cursor-pointer', { selected: selectedScripture && selectedScripture.id === scripture.id }]">
              <div>{{ scriptureDisplay(scripture) }}</div>
              <div v-if="scripture.id === (selectedScripture && selectedScripture.id)" class="whitespace-pre break-words text-sm leading-normal mt-2 ml-4 ">{{ scripture.body }}</div>
            </div>
          </div>
          <div v-if="scriptures && scriptures.length === 0" class="p-2">
            {{ loaded? 'No Scriptures to select' : 'Loading...' }}
          </div>

          <div class="sm:flex text-right my-3 sm:my-6">
            <div class="selected-scripture p-2 truncate text-left">Selected: {{ selectedScripture ? scriptureDisplay(selectedScripture) : scriptureNewDisplay() }}</div>
            <div class="flex-auto">
              <button type="button" class="btn-light" data-modal-action="cancel">Cancel</button>
              <button type="button" class="btn ml-4" data-modal-action="ok">Save</button>              
            </div>

          </div>
        </div>
      </show-modal-button>
    </span>
  </div>
</template>

<script>
  import * as api from '../../client/api.js'
  import ShowModalButton from '../ShowModalButton'
  import * as easterTools from '../../tools/easterTools'

  export default {
    name: 'ScriptureInput',
    props: ['label', 'name', 'value', 'reading', 'instruction', 'response', 'override', 'title'],
    components: { ShowModalButton },
    data() {
      return {
        scripture: null,
        selectedScripture: null,
        scriptures: [],
        userBook: null,
        userLines: null,
        userResponse: null,
        userTitle: null,
        userOverride: null,
        isCollapse: false,
        loaded: false
      }
    },
    computed: {
      scriptureLoading() {
        return this.value && !this.scripture;
      },
      readingScriptures() {
        let readingId = this.reading;

        if (readingId === 'EASTER') {
          readingId = easterTools.getEasterReading(this.$parent.formData['MassDate'], this.$config);
        }

        return this.$store.getters.scriptures && this.$store.getters.scriptures.filter(x => x.readingId === readingId);
      },
    },
    created() {
      let self = this;
      self.getScriptures(() => self.init());
    },
    watch: {
      userBook() {
        if (this.userBook) {
          this.selectedScripture = null;
          this.scripture = { book: this.userBook, lines: this.userLines == null ? '' : this.userLines }
        } else {
          this.userLines = null
          this.userResponse = null
          this.userOverride = null
          this.userTitle = null 
        }
        this.isCollapse = this.override
      },
      userLines() {
        if (this.userLines) {
          this.selectedScripture = null;
          this.scripture = { book: this.userBook, lines: this.userLines }
        }
      },
      userResponse() {
        if (this.userResponse) {
          this.selectedScripture = null;
          this.scripture = { book: this.userBook, lines: this.userLines, caption: this.userResponse }
        }
      },
      userOverride() {
        if (this.userOverride) {
          this.selectedScripture = null;
          this.scripture = { book: this.userBook, lines: this.userLines, caption: this.userResponse, body: this.userOverride }
        }
      },
      userTitle() {
        if (this.userTitle) {
          this.selectedScripture = null;
          this.scripture = { book: this.userBook, lines: this.userLines, caption: this.userResponse, body: this.userOverride, title: this.userTitle }
        }
      }
    },
    methods: {
      init() {
        this.userBook = null
        this.userLines = null
        this.userResponse = null
        this.userOverride = null
        this.userTitle = null
        this.isCollapse = false;       
        
        if (this.value) {
          let id = this.value.id || this.value.Id;
          let book = this.value.book || this.value.Book;
          let lines = this.value.lines || this.value.Lines;
          let caption = this.value.caption || this.value.Caption;
          let body = this.value.body || this.value.Body;
          let title = this.value.title || this.value.Title;

          if (!id && book) {
           this.userBook = book
           this.userLines = lines
           this.userResponse = caption
           this.userOverride = body
           this.userTitle = title
           this.isCollapse = this.userBook.length !== 0 && this.overrider ? true : false; 
          }
          else {
            this.scripture = this.selectedScripture = this.scriptures.find(x => x.id === this.value.id || x.id === this.value.Id);
          }
        }
      },
      selectScripture(scripture) {
        this.userBook = null;
        this.userLines = null;
        this.userResponse = null
        this.userOverride = null
        this.userTitle = null
        this.selectedScripture = this.selectedScripture && this.selectedScripture.id === scripture.id ? null : scripture;
      },
      scriptureDisplay(scripture) {
        return scripture ? `${scripture.book}:${scripture.lines}` : 'none';
      },      
      scriptureNewDisplay() {     
        return this.userBook ? this.userBook + ' : ' + (this.userLines == null ? '' : this.userLines) : 'none'
      },

      getScriptures(callback) {
        let self = this;

        self.scriptures = this.readingScriptures;

        if (self.scriptures != null) {
          self.loaded = true;

          if (callback) {
            callback();
          }
        }
        else {
          api.ReferencesGet().then(response => {
            if (response && response.data) {
              self.$store.dispatch('storeReferences', response.data).then(() => {
                self.scriptures = this.readingScriptures;
                self.loaded = true;

                if (callback) {
                  callback();
                }
              });
            }
          }).catch(e => this.handleApiError(e));
        }
      },
      closeModal(result) {
        if (result === 'ok') {
          this.scripture = this.selectedScripture ? this.selectedScripture : (this.userBook && this.userLines ? { book: this.userBook, lines: this.userLines, caption: this.userResponse, body: this.userOverride, title: this.userTitle } : null);
          this.$emit('input', this.scripture);
        }
        else if (result === 'cancel') {
          this.init();
        }
      }
    }
  }
</script>

<style scoped>
  .scripture-button {
    max-width: 17rem;
  }

  .disabled {
    color: #22292f;
    font-weight: 300;
    pointer-events: none;
  }

  .scriptures-container {
    max-height: 50vh;
  }

  @media (max-height: 896px) {
    .scriptures-container {
      max-height: 45vh;
    }
  } 

  @media (max-height: 768px) {
    .scriptures-container {
      max-height: 33vh;
    } 
  }


  .selected {
    outline: 2px solid #de751f;
  }

  .selected-scripture {
    max-width: 23rem;
  }

  .collapse {
    max-height: 14vh;
  }
 
</style>
