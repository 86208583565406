<template>
  <div class="flex flex-col md:flex-row">
    <help-menu></help-menu>
    <div class="flex-1 md:px-8 text-left">
      <h1>Our Guarantee</h1>
      <p>
        MY WAY BOOKLETS' primary goal is to satisfy our customer and deliver a high standard of quality. We guarantee that you will love our product or we will re-run and replace your order or refund your money.
      </p>
      <p>
        If, for any reason, you are not satisfied with your order, kindly notify us and explain your dissatisfaction. If we are unable to achieve a mutually agreeable outcome by making requested revisions to the order, or we are unable to make the requested revisions, we will refund your money.  Most importantly, we want your MY WAY BOOKLETS' to honor and reflect the memory of the person for whom it has been designed and created
      </p>
      <p>
        We would like you to feel completely comfortable doing business with us. We sincerely empathize with you and understand this difficult time in your life. Please <router-link to="/contact-us">contact us</router-link> with any questions, concerns or additions you may have, and someone will be available to answer your questions.
      </p>
      <p>
        If you find a defect in your order that is the responsibility of MyWayBooklets.com, please contact us within thirty (30) days of receipt of the delivered order and provide a written description, together with a photo of the discrepancy. Upon receipt, your written concern will be reviewed to determine our course of action. We will either re-run the order at no additional cost, including expedited shipping, or refund a partial or full refund of the original cost of the order. In some instances, we may request that you return a portion of the defective order at our expense in order to determine the best possible outcome. 
      </p>
      <p>
        Please note that if your job was printed as ordered, but you are not fully satisfied, you may return your order for a full refund. However, in this case, since we fulfilled our obligation, we request the return shipping costs be at your expense. MY WAY BOOKLETS' is not responsible for delays or defects that are outside of our control. Additionally, we are not responsible, under any circumstances, for refunding shipping charges when you have chosen to use your own shipping method or account instead of the MyWayBooklets.com shipping method.
      </p>
    </div>
  </div>
</template>

<script>
  import HelpMenu from "./HelpMenu"

  export default {
    name: "OurGuarantee",
    components: { HelpMenu }
  }
</script>
