<template>
  <div>
    <div class="mx-auto max-w-xl">
      <div class="md:px-8 text-left">
        <h1>Prepare For The Future</h1>
        <p>
          Since you have taken the time to pre-plan your funeral, why not take advantage of MY WAY BOOKLETS' technology to prepare your Mass booklet in advance? MY WAY BOOKLETS' templates are designed for ease of use and retrieval. We will assist you, page by page, with the design and creation of your funeral Mass booklet in accordance with the Order of Christian Funerals. We offer a complete selection of scripture readings for the First Reading, the Second Reading, the Gospel Reading, the Responsorial Psalms and the Hymns recommended for use when planning a Catholic Funeral Mass.
        </p>
        <p>
          Additionally, you have the <i>ability to upload personal photographs, poems, prayers or quotes with images from your own library or from our resource image library selection</i>.  These special features add caring elements to your booklet so that all in attendance will leave with a unique keepsake of your memory to be treasured forever.
        </p>    
        <p>
          These special features add caring elements to your booklet, so that all in attendance leave with a unique keepsake of your memory to be treasured forever.
        </p>
        <p>
          Whether you are pre-planning your own funeral Mass booklet or assisting a loved one with their Mass booklet, you can create Save and Retrieve Your Booklet. Ready, <router-link to="/funeral-booklets">let’s begin.</router-link>
        </p>

        <h1>Why Pre-Plan</h1>
        <p>
          Pre-Planning your own funeral Mass undoubtedly is an emotionally challenging endeavor; however, making decisions in advance affords you the opportunity to share your wishes with your spouse, family or friends. Your consideration alleviates the anxiety of planning an appropriate Mass in your absence.
        </p>
        <p>
          Planning ahead allows you to comfortably consider your choices rather than having others make decisions on your behalf. Most importantly, it provides reassurance to your loved ones that your final requests were complied with, giving them time to support one another during this emotionally challenging time.
        </p> 
        <p>
          Ready, <router-link to="/funeral-booklets">let's begin.</router-link>
        </p>   

        <h1>Save And Retrieve Your Booklet</h1>
        <p>
          Whether you are pre-planning your own funeral Mass booklet or assisting a loved one, MY WAY BOOKLETS' templates are designed for simplistic use and easy retrieval.
        </p>
        <div class="mb-3">Follow these instructions:</div>
        <ol>
          <li>Create your profile;</li>
          <li>Select a designated representative with an email address;</li>
          <li>Design your booklet;</li>
          <li>Review and approve your created booklet;</li>
          <li>Pay for the service;</li>
          <li>Save your profile and booklet;</li>
          <li>An email will be sent to you and your designated representative with proper instruction for future retrieval.</li>
        </ol>
        <p class="mt-6">
          We are ready when you are... <router-link to="/funeral-booklets">Let's begin.</router-link>
        </p>             
      </div>
    </div>
  </div></template>

<script>
export default {};
</script>

<style>
</style>