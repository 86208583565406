<template>
    <div class="mx-auto text-left">
        <div class="card" :class="{ 'not-expanded': !this.expanded }">

            <header class="card-header" @click="toggleCardState">
                <h3 class="card-header-title">
                    {{title}}
                </h3>
                <a class="card-header-icon">
                    <span class="icon">
                    <i class="fa fa-angle-up"></i>
                    </span>
                </a>
            </header>
            <div class="card-content text-navy hover:text-navy">
                <div class="content">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

  export default {
    name: 'Accordion',
    props: ["title", "expandAll"],
    data() {
        return {
        "expanded": false
        }
    },
    methods: {
        toggleCardState() {
            this.expanded = !this.expanded;
        },
        closeAll() {
            this.expanded = false;
        }
    },
    created() {
        this.$on("closeAll", this.closeAll)
    },
    mounted() {
        this.$on("toggleCardState", this.expanded = !this.expanded) 
    }
  }
</script>

<style scope>
.card {
	 @apply mb-10;
	 @apply w-full;
}
.card .card-header {
    @apply pb-1;
    @apply mb-3;
    @apply border-b;
    @apply border-grey-light;
    @apply italic;
    @apply cursor-pointer;
}
.card .card-header .icon {
	 transform: rotate(180deg);
	 transition: transform 150ms ease-out;
}
.card .card-content {
	 transition: all 150ms ease;
}
.card.not-expanded .card-header .icon {
	 transform: rotate(0deg);
}
.card.not-expanded .card-content {
	 transform: scaleY(0);
	 transform-origin: top;
	 opacity: 0;
	 height: 0;
	 padding: 0;
}
</style>